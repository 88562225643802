import IKpi from "../models/IKpi";
import { fetchSecureData } from "../utils";

export const getKpis = async (accessToken: string) => {
  const data = await fetchSecureData("GET", `/api/Kpi/GetKpis`, accessToken);
  const kpis = ((await data.json()) as any).value as IKpi[];
  if (kpis !== undefined) {
    return kpis;
  } else {
    return [] as IKpi[];
  }
};

export default getKpis;