import * as React from "react";
import { Route, Switch } from "react-router";
import { routes, secureAdminRoutes, secureRoutes } from "../../routes/RouteList";
import ErrorPage from "../pages/ErrorPage";
import SecureRoute from "../templates/SecureRoute";
import SecureAdminRoute from "./SecureAdminRoute";

const PageBodyRoutes = () => {
  return (
    <Switch>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.main}
        />
      ))}
      {secureRoutes.map(route => (
        <SecureRoute
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.main}
        />
      ))}
      {secureAdminRoutes.map(route => (
        <SecureAdminRoute
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.main}
        />
      ))}
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default PageBodyRoutes;
