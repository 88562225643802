export const SET_ACTIVE_KPI_FY_ID = "SET_ACTIVE_KPI_FY_ID";
export const CLEAR_ACTIVE_KPI_FY_ID = "CLEAR_ACTIVE_KPI_FY_ID";

export const setActiveKpiFinancialYearId = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_KPI_FY_ID
  };
};

export const clearActiveKpiFinancialYearId = () => {
  return {
    type: CLEAR_ACTIVE_KPI_FY_ID
  };
};
