import IPbiDashboard from "../models/IPbiDashboard";
import { fetchSecureData } from "../utils";

export const saveDashboard = async (
  accessToken: string,
  name: string,
  displayName: string,
  groupId: string,
  reportId: string,
  dashboardViewerVersion:number,
  isEnabled: boolean
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/PbiDashboard/Add?name=${name}&displayName=${displayName}&groupId=${groupId}&reportId=${reportId}&dashboardViewerVersion=${dashboardViewerVersion}&isEnabled=${isEnabled}`,
      accessToken
    );
    const dashboard = ((await data.json()) as any).value as IPbiDashboard;
    return dashboard;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/PbiDashboard/Add?name=${name}&displayName=${displayName}&groupId=${groupId}&reportId=${reportId}&dashboardViewerVersion=${dashboardViewerVersion}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as IPbiDashboard;
};

export default saveDashboard;
