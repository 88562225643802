import { AnyAction } from "redux";
import {
  ADD_ACTIVE_ROLE_KPI_GROUPING_PREDICATE,
  CLEAR_ACTIVE_ROLE_KPI_GROUPING_PREDICATES,
  LOAD_ACTIVE_ROLE_KPI_GROUPING_PREDICATES,
  REMOVE_ACTIVE_ROLE_KPI_GROUPING_PREDICATE
} from "../actions/activeRoleKpiGroupingPredicates";
import IPredicate from "../models/IPredicate";

export const activeRoleKpiGroupingPredicates = (
  state = [] as IPredicate[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_ACTIVE_ROLE_KPI_GROUPING_PREDICATE:
      const newState: IPredicate[] = state.slice();
      newState.push(action.predicate as IPredicate);
      return newState;
    case REMOVE_ACTIVE_ROLE_KPI_GROUPING_PREDICATE:
      return Object.assign(
        [],
        state.filter(
          (predicate: IPredicate) => predicate.id !== (action.predicate as IPredicate).id
        )
      ) as IPredicate[];
    case LOAD_ACTIVE_ROLE_KPI_GROUPING_PREDICATES:
      return action.predicates as IPredicate[];
    case CLEAR_ACTIVE_ROLE_KPI_GROUPING_PREDICATES:
      return [] as IPredicate[];
    default:
      return state;
  }
};

export default activeRoleKpiGroupingPredicates;
