import * as React from "react";
import Manage from "../../assets/icons/manageWhite.svg";
import Icon from "./Icon";

const AdminIcon = () => {
  return (
    <React.Fragment>
      <Icon icon={Manage} text="Site Administration" id="adminManage" />
    </React.Fragment>
  );
};

export default AdminIcon;
