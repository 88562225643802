import * as Redux from "redux";
import getKpis from "../api/getKpis";
import { removeKpi as deleteKpi } from "../api/removeKpi";
import saveKpi from "../api/saveKpi";
import saveUpdatedKpi from "../api/saveUpdatedKpi";
import IKpi from "../models/IKpi";
import { setKpisLoaded } from "./loadingKpis";
export const ADD_KPI = "ADD_KPI";
export const REMOVE_KPI = "REMOVE_KPI";
export const UPDATE_KPI = "UPDATE_KPI";
export const LOAD_KPIS = "LOAD_KPIS";

export const addKpi = (kpi: IKpi) => {
  return {
    kpi,
    type: ADD_KPI
  };
};

export const removeKpi = (id: number) => {
  return {
    id,
    type: REMOVE_KPI
  };
};

export const updateKpi = (kpi: IKpi) => {
  return {
    kpi,
    type: UPDATE_KPI
  };
};

export const loadKpis = (kpis: IKpi[]) => {
  return {
    kpis,
    type: LOAD_KPIS
  };
};

export const handleSaveKpi = (
  accessToken: string,
  displayName: string,
  name: string,
  isEnabled: boolean
) => {
  return async (dispatch: Redux.Dispatch) => {
    const kpi = await saveKpi(accessToken, displayName, name, isEnabled);
    if (kpi !== null && kpi !== undefined) {
      dispatch(addKpi(kpi));
    }
  };
};

export const handleUpdateKpi = (accessToken: string, kpi: IKpi) => {
  return async (dispatch: Redux.Dispatch) => {
    const k = await saveUpdatedKpi(accessToken, kpi);
    if (k !== null && k !== undefined) {
      dispatch(updateKpi(k));
    }
  };
};

export const handleDeleteKpi = (accessToken: string, id: number) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deleteKpi(accessToken, id);
    if (result === 200) {
      dispatch(removeKpi(id));
    }
  };
};

export const handleLoadKpis = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const kpis = await getKpis(accessToken);
    if (kpis !== undefined) {
      if (kpis.length > 0) {
        kpis.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
        dispatch(loadKpis(kpis));
      }
      dispatch(setKpisLoaded(true));
    }
  };
};
