import * as Redux from "redux";
import IPbiPaginatedReport from "../models/IPbiPaginatedReport";

export const SET_ACTIVE_PAGINATED_REPORT_ID = "SET_ACTIVE_PAGINATED_REPORT_ID";
export const CLEAR_ACTIVE_PAGINATED_REPORT_ID = "CLEAR_ACTIVE_PAGINATED_REPORT_ID";

export const setActivePaginatedReport = (id: number) => {
    return {
        id,
        type: SET_ACTIVE_PAGINATED_REPORT_ID
    };
};
export const clearActivePaginatedReport = () => {
    return {
        type: CLEAR_ACTIVE_PAGINATED_REPORT_ID
    };
};

export const handleLoadActivePaginatedReportData = (
    paginatedReportId: number,
    userPaginatedReports: IPbiPaginatedReport[]
) => {
    return async (dispatch: Redux.Dispatch) => {
        const paginatedReport = await userPaginatedReports.find(
            rep => rep.id === paginatedReportId
        );
        if (paginatedReport === undefined) {
            // tslint:disable-next-line:no-console
            console.log(
                "This paginatedReport not found, or user does not have access to this paginatedReport"
            );
        } else {
            dispatch(setActivePaginatedReport(paginatedReport.id));
        }
    };
};
