import IGroup from "../models/IGroup";
import { fetchSecureData } from "../utils";

export const saveUpdatedGroup = async (
  accessToken: string,
  group: IGroup
) => {
  const payload = {
    id: group.id,
    name: group.name,
    // tslint:disable-next-line:object-literal-sort-keys
    isEnabled: group.isEnabled
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/Group/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as IGroup;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/Group/Edit`, err);
  }
  return {} as IGroup;
};

export default saveUpdatedGroup;
