import { AnyAction } from "redux";
import {
  ADD_ROLE,
  LOAD_ROLES,
  REMOVE_ROLE,
  UPDATE_ROLE
} from "../actions/roles";
import IRole from "../models/IRole";

export const roles = (state = [] as IRole[], action: AnyAction) => {
  switch (action.type) {
    case ADD_ROLE:
      const newState: IRole[] = state.slice();
      newState.push(action.role as IRole);
      return newState;
    case REMOVE_ROLE:
      return Object.assign(
        [],
        state.filter((role: IRole) => role.id !== (action.id as number))
      ) as IRole[];
    case UPDATE_ROLE:
      return Object.assign(
        [],
        state.map((role: IRole) => {
          if (role.id === (action.role as IRole).id) {
            return action.role as IRole;
          }
          return role;
        })
      ) as IRole[];
    case LOAD_ROLES:
      return action.roles as IRole[];
    default:
      return state;
  }
};

export default roles;
