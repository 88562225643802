import { AnyAction } from "redux";
import { SET_DATA_LOADED } from "../actions/loading";

export const loading = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_DATA_LOADED:
      return action.dataLoaded;
    default:
      return state;
  }
};

export default loading;
