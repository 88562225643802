import * as React from "react";
import { Link } from "react-router-dom";
import { NavbarBrand as BootstrapNavbarBrand } from "reactstrap";

export interface INavbarBrandProps {
  to?: string;
  id?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const NavbarBrand: React.SFC<INavbarBrandProps> = (
  props: INavbarBrandProps
) => {
  const { to, id, style, children } = props;
  return (
    <BootstrapNavbarBrand tag={Link} to={to} id={id} style={style}>
      {children}
    </BootstrapNavbarBrand>
  );
};

export default NavbarBrand;
