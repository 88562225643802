import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import {
  clearActiveKpiFinancialYearId,
  setActiveKpiFinancialYearId
} from "../../actions/activeKpiFinancialYear";
import { handleLoadFinancialYears } from "../../actions/financialYears";
import IFinancialYear from "../../models/IFinancialYear";
import { IStore } from "../../models/IStore";
import DataLoading from "../organisms/DataLoading";

export interface IKpiFinancialYearSelectorProps {
  accessToken: string;
  fys: IFinancialYear[];
  fysLoaded: boolean;
  roleId: number;
  predicateId: number;
  clientId: number;
  onHandleLoadFinancialYears: (accessToken: string) => void;
  onHandleSetActiveFinancialYearId: (selectedFinancialYearId: number) => void;
  onHandleClearActiveFinancialYearId: () => void;
}

export interface IKpiFinancialYearSelectorState {
  selectedFinancialYearId: number;
}

class KpiFinancialYearSelector extends React.Component<
  IKpiFinancialYearSelectorProps,
  IKpiFinancialYearSelectorState
> {
  public state = {
    selectedFinancialYearId: 0
  };
  public constructor(props: IKpiFinancialYearSelectorProps) {
    super(props);
  }
  public selectedFinancialYearChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onHandleSetActiveFinancialYearId } = this.props;
    e.preventDefault();
    const selectedFinancialYearId =
      e.target.value === undefined ? 0 : Number(e.target.value as string);
    onHandleSetActiveFinancialYearId(selectedFinancialYearId);
    this.setState(() => ({ selectedFinancialYearId }));
  };
  public componentDidMount() {
    const { fysLoaded, onHandleLoadFinancialYears, accessToken } = this.props;
    if (!fysLoaded) {
      onHandleLoadFinancialYears(accessToken);
    }
  }

  public componentDidUpdate(prevProps: IKpiFinancialYearSelectorProps) {
    const {
      accessToken,
      fysLoaded,
      roleId,
      predicateId,
      clientId,
      onHandleClearActiveFinancialYearId,
      onHandleLoadFinancialYears
    } = this.props;
    if (!fysLoaded) {
      onHandleLoadFinancialYears(accessToken);
    }
    if (
      prevProps.roleId !== roleId ||
      prevProps.predicateId !== predicateId ||
      prevProps.clientId !== clientId
    ) {
      onHandleClearActiveFinancialYearId();
      this.setState({ selectedFinancialYearId: 0 });
    }
  }

  public componentWillUnmount() {
    const { onHandleClearActiveFinancialYearId } = this.props;
    onHandleClearActiveFinancialYearId();
  }
  public render() {
    const { fys, fysLoaded } = this.props;
    const { selectedFinancialYearId } = this.state;
    return (
      <FormGroup>
        {!fysLoaded && <DataLoading dataTitle="FinancialYears" />}
        {fysLoaded && (
          <React.Fragment>
            <Label for="financialYear">Financial Year</Label>
            <Input
              id="financialYear"
              type="select"
              placeholder="Financial Year"
              value={selectedFinancialYearId}
              onChange={this.selectedFinancialYearChange}
            >
              <option value="0" disabled={true}>
                Select Financial Year
              </option>
              {fys.map(financialYear => (
                <option key={financialYear.id} value={financialYear.id}>
                  {financialYear.displayName}
                </option>
              ))}
            </Input>
          </React.Fragment>
        )}
      </FormGroup>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    fys: state.financialYears,
    fysLoaded: state.loadingFinancialYears,
    roleId: state.activeRoleId,
    // tslint:disable-next-line:object-literal-sort-keys
    predicateId: state.activeKpiPredicateId,
    clientId: state.activeKpiClientId
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadFinancialYears: (accessToken: string) => {
    dispatch(handleLoadFinancialYears(accessToken));
  },
  onHandleSetActiveFinancialYearId: (selectedFinancialYearId: number) => {
    dispatch(setActiveKpiFinancialYearId(selectedFinancialYearId));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleClearActiveFinancialYearId: () => {
    dispatch(clearActiveKpiFinancialYearId());
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KpiFinancialYearSelector);
