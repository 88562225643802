import * as Redux from "redux";
import getRoleClients from "../api/getRoleClients";
import IClient from "../models/IClient";
import { setRoleClientsLoaded } from "./loadingRoleClients";

export const ADD_ACTIVE_ROLE_CLIENT = "ADD_ACTIVE_ROLE_CLIENT";
export const REMOVE_ACTIVE_ROLE_CLIENT = "REMOVE_ACTIVE_ROLE_CLIENT";
export const LOAD_ACTIVE_ROLE_CLIENTS = "LOAD_ACTIVE_ROLE_CLIENTS";
export const CLEAR_ACTIVE_ROLE_CLIENTS = "CLEAR_ACTIVE_ROLE_CLIENTS";

export const addActiveRoleClient = (client: IClient) => {
  return {
    client,
    // tslint:disable-next-line:object-literal-sort-keys
    type: ADD_ACTIVE_ROLE_CLIENT
  };
};

export const removeActiveRoleClient = (client: IClient) => {
  return {
    client,
    type: REMOVE_ACTIVE_ROLE_CLIENT
  };
};

const loadActiveRoleClients = (clients: IClient[]) => {
  return {
    clients,
    // tslint:disable-next-line:object-literal-sort-keys
    type: LOAD_ACTIVE_ROLE_CLIENTS
  };
};

const clearActiveRoleClients = () => {
  return {
    type: CLEAR_ACTIVE_ROLE_CLIENTS
  };
};

export const handleLoadActiveRoleClients = (
  accessToken: string,
  roleId: number,
  predicateId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const clients = await getRoleClients(accessToken, roleId, predicateId);
    if (clients !== undefined) {
      if (clients.length > 0) {
        clients.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
        dispatch(loadActiveRoleClients(clients));
      }
      dispatch(setRoleClientsLoaded(true));
    }
  };
};

export const handleClearActiveRoleClients = () => {
  return async (dispatch: Redux.Dispatch) => {
    dispatch(clearActiveRoleClients());
    dispatch(setRoleClientsLoaded(false));
  };
};
