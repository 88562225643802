import * as Redux from "redux";
import getUserPaginatedReports from "../api/getUserPaginatedReports";
import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { setActivePaginatedReport } from "./activePaginatedReport";
import { setUserPaginatedReportsLoaded } from "./loadingUserPaginatedReports";

export const ADD_USER_PAGINATED_REPORT = "ADD_USER_PAGINATED_REPORT";
export const REMOVE_USER_PAGINATED_REPORT = "REMOVE_USER_PAGINATED_REPORT";
export const LOAD_USER_PAGINATED_REPORTS = "LOAD_USER_PAGINATED_REPORTS";

export const addUserPaginatedReport = (userPaginatedReport: IPbiPaginatedReport) => {
    return {
        userPaginatedReport,
        // tslint:disable-next-line:object-literal-sort-keys
        type: ADD_USER_PAGINATED_REPORT
    };
};

export const removeUserPaginatedReport = (id: number) => {
    return {
        id,
        type: REMOVE_USER_PAGINATED_REPORT
    };
};

export const loadUserPaginatedReports = (userPaginatedReports: IPbiPaginatedReport[]) => {
    return {
        userPaginatedReports,
        // tslint:disable-next-line:object-literal-sort-keys
        type: LOAD_USER_PAGINATED_REPORTS
    };
};

export const handleLoadUserPaginatedReports = (
    accessToken: string,
    userId: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        const paginatedReports = await getUserPaginatedReports(accessToken, userId);
        if (paginatedReports !== undefined) {
            if (paginatedReports.length > 0) {
                paginatedReports.sort((a, b) =>
                    a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
                );
                dispatch(loadUserPaginatedReports(paginatedReports));
                dispatch(setActivePaginatedReport(paginatedReports[0].id));
            }
            dispatch(setUserPaginatedReportsLoaded(true));
        }
    };
};
