import { fetchSecureData } from "../utils";

export const removePredicate = async (accessToken: string, id: number) => {
  try {
    const data = await fetchSecureData(
      "DELETE",
      `/api/Predicate/Remove?id=${id}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/Predicate/Remove?id=${id}`, err);
  }
  return 404;
};

export default removePredicate;
