import { AnyAction } from "redux";
import {
  ADD_USER_DASHBOARD,
  LOAD_USER_DASHBOARDS,
  REMOVE_USER_DASHBOARD
} from "../actions/userDashboards";
import IPbiDashboard from "../models/IPbiDashboard";

export const userDashboards = (
  state = [] as IPbiDashboard[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_USER_DASHBOARD:
      const newState: IPbiDashboard[] = state.slice();
      newState.push(action.userDashboard as IPbiDashboard);
      return newState;
    case REMOVE_USER_DASHBOARD:
      return Object.assign(
        {},
        state.filter((r: IPbiDashboard) => r.id !== (action.id as number))
      );
    case LOAD_USER_DASHBOARDS:
      return action.userDashboards as IPbiDashboard[];
    default:
      return state;
  }
};

export default userDashboards;
