import { AnyAction } from "redux";
import { SET_REPORTS_LOADED } from "../actions/loadingReports";

export const loadingReports = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_REPORTS_LOADED:
      return action.reportsLoaded;
    default:
      return state;
  }
};

export default loadingReports;
