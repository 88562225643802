import * as React from "react";
import { connect } from "react-redux";
import { Button, Table } from "reactstrap";
import {
  handleClearActiveKpiEntries,
  handleLoadActiveKpiEntries,
  handleSaveKpiEntry,
  handleUpdateKpiEntry
} from "../../actions/activeKpiEntries";
import { handleLoadFyStartMonths } from "../../actions/fyStartMonths";
import IFinancialYear from "../../models/IFinancialYear";
import IFYStartMonth from "../../models/IFYStartMonth";
import IKpiEntry from "../../models/IKpiEntry";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";
import { validateNumber } from "../../utils";
import DataLoading from "../organisms/DataLoading";
import AddTextField from "./AddTextField";

export interface IKpiEntriesEditTableProps {
  accessToken: string;
  callback: () => void;
  role: IRole;
  financialYear: IFinancialYear;
  predicateId: number;
  clientId: number;
  kpiId: number;
  kpiEntries: IKpiEntry[];
  kpiEntriesLoaded: boolean;
  months: IFYStartMonth[];
  monthsLoaded: boolean;
  onHandleLoadMonths: (accessToken: string) => void;
  onHandleLoadKpiEntries: (
    accessToken: string,
    predicateId: number,
    clientId: number,
    fyStartMonthId: number,
    endYear: number,
    kpiId: number
  ) => void;
  onHandleClearLoadedKpiEntries: () => void;
  onHandleSaveKpiEntry: (accessToken: string, kpiEntry: IKpiEntry) => void;
  onHandleUpdateKpiEntry: (
    accessToken: string,
    kpiEntry: IKpiEntry,
    updatedKpiEntry: IKpiEntry
  ) => void;
}

export interface IKpiEntriesEditTableState {
  editedKpiEntries: IKpiEntry[];
}

class KpiEntriesEditTable extends React.Component<
  IKpiEntriesEditTableProps,
  IKpiEntriesEditTableState
> {
  public state = {
    editedKpiEntries: [] as IKpiEntry[]
  };
  constructor(props: IKpiEntriesEditTableProps) {
    super(props);
    this.createTable = this.createTable.bind(this);
    this.onKpiEntryValueChange = this.onKpiEntryValueChange.bind(this);
    this.onKpiEntryThresholdChange = this.onKpiEntryThresholdChange.bind(this);
    this.copyToAllEditedKpiEntries = this.copyToAllEditedKpiEntries.bind(this);
  }
  public copyToAllEditedKpiEntries = (measuredValue: number) => {
    const { predicateId, clientId, kpiId, role, financialYear } = this.props;
    const { fyStartMonthId } = role;
    const { editedKpiEntries } = this.state;
    if (fyStartMonthId === 1) {
      // this.createTable(1, 13, financialYear.endYear, true);
      for (let i = fyStartMonthId; i < 13; i++) {
        const kpiEntryIndex = editedKpiEntries.findIndex(
          kpie => kpie.monthId === i && kpie.year === financialYear.endYear
        );
        const kpiEntry =
          kpiEntryIndex === -1
            ? ({
                predicateId,
                clientId,
                kpiId,
                year: financialYear.endYear,
                monthId: i,
                measuredValue,
                thresholdValue: 0,
                isEnabled: true
              } as IKpiEntry)
            : editedKpiEntries[kpiEntryIndex];
        this.onKpiEntryValueChange(kpiEntryIndex, kpiEntry, measuredValue);
      }
    } else {
      // {this.createTable(startMonthId, 13, financialYear.endYear - 1, true)}
      for (let i = fyStartMonthId; i < 13; i++) {
        const kpiEntryIndex = editedKpiEntries.findIndex(
          kpie => kpie.monthId === i && kpie.year === financialYear.endYear - 1
        );
        const kpiEntry =
          kpiEntryIndex === -1
            ? ({
                predicateId,
                clientId,
                kpiId,
                year: financialYear.endYear - 1,
                monthId: i,
                measuredValue,
                thresholdValue: 0,
                isEnabled: true
              } as IKpiEntry)
            : editedKpiEntries[kpiEntryIndex];
        this.onKpiEntryValueChange(kpiEntryIndex, kpiEntry, measuredValue);
      }
      // {this.createTable(1, startMonthId, financialYear.endYear)}
      for (let i = 1; i < fyStartMonthId; i++) {
        const kpiEntryIndex = editedKpiEntries.findIndex(
          kpie => kpie.monthId === i && kpie.year === financialYear.endYear
        );
        const kpiEntry =
          kpiEntryIndex === -1
            ? ({
                predicateId,
                clientId,
                kpiId,
                year: financialYear.endYear,
                monthId: i,
                measuredValue,
                thresholdValue: 0,
                isEnabled: true
              } as IKpiEntry)
            : editedKpiEntries[kpiEntryIndex];
        this.onKpiEntryValueChange(kpiEntryIndex, kpiEntry, measuredValue);
      }
    }
    this.props.callback();
  };
  public onKpiEntryValueChange = (
    index: number,
    kpiEntry: IKpiEntry,
    updatedValue: number
  ) => {
    const {
      accessToken,
      onHandleSaveKpiEntry,
      onHandleUpdateKpiEntry
    } = this.props;
    const { editedKpiEntries } = this.state;
    const newKpiEntry = {
      predicateId: kpiEntry.predicateId,
      // tslint:disable-next-line:object-literal-sort-keys
      clientId: kpiEntry.clientId,
      year: kpiEntry.year,
      kpiId: kpiEntry.kpiId,
      monthId: kpiEntry.monthId,
      measuredValue: updatedValue,
      thresholdValue: kpiEntry.thresholdValue
    } as IKpiEntry;
    if (index === -1) {
      // new entry to add at end of array
      onHandleSaveKpiEntry(accessToken, newKpiEntry);
      editedKpiEntries.push(newKpiEntry);
    } else {
      // existing entry to update
      onHandleUpdateKpiEntry(accessToken, kpiEntry, newKpiEntry);
      editedKpiEntries.splice(index, 1, newKpiEntry);
    }
    this.setState(() => ({ editedKpiEntries }));
  };
  public onKpiEntryThresholdChange = (
    index: number,
    kpiEntry: IKpiEntry,
    updatedThreshold: number
  ) => {
    const {
      accessToken,
      onHandleSaveKpiEntry,
      onHandleUpdateKpiEntry
    } = this.props;
    const { editedKpiEntries } = this.state;
    const newKpiEntry = {
      predicateId: kpiEntry.predicateId,
      // tslint:disable-next-line:object-literal-sort-keys
      clientId: kpiEntry.clientId,
      year: kpiEntry.year,
      kpiId: kpiEntry.kpiId,
      monthId: kpiEntry.monthId,
      measuredValue: kpiEntry.measuredValue,
      thresholdValue: updatedThreshold
    } as IKpiEntry;
    if (index === -1) {
      // new entry to add at end of array
      onHandleSaveKpiEntry(accessToken, newKpiEntry);
      editedKpiEntries.push(newKpiEntry);
    } else {
      // existing entry to update
      onHandleUpdateKpiEntry(accessToken, kpiEntry, newKpiEntry);
      editedKpiEntries.splice(index, 1, newKpiEntry);
    }
    this.setState(() => ({ editedKpiEntries }));
  };
  public createTable = (
    start: number,
    end: number,
    year: number,
    addAutofillButton: boolean = false
  ) => {
    const { months, predicateId, clientId, kpiId } = this.props;
    const { editedKpiEntries } = this.state;
    const table = [];
    for (let i = start; i < end; i++) {
      const kpiEntryIndex = editedKpiEntries.findIndex(
        kpie => kpie.monthId === i && kpie.year === year
      );
      const kpiEntry =
        kpiEntryIndex === -1
          ? ({
              predicateId,
              // tslint:disable-next-line:object-literal-sort-keys
              clientId,
              kpiId,
              year,
              monthId: i,
              measuredValue: 0,
              thresholdValue: 0,
              isEnabled: true
            } as IKpiEntry)
          : editedKpiEntries[kpiEntryIndex];
      table.push(
        <tr key={i}>
          <td>
            {(months.find(month => month.id === i) as IFYStartMonth).name}{" "}
            {year}
          </td>
          <td>
            <AddTextField
              noDuplicates={false}
              isValid={validateNumber}
              title=""
              value={kpiEntry.measuredValue.toString()}
              existingValues={[] as string[]}
              // tslint:disable-next-line:jsx-no-lambda
              callback={async (val: string) => {
                this.onKpiEntryValueChange(
                  kpiEntryIndex,
                  kpiEntry,
                  Number(val)
                );
              }}
            />
          </td>
          {/* Disable threshold input from UI */}
          {/* <td>
            <AddTextField
              noDuplicates={false}
              isValid={validateNumber}
              title=""
              value={kpiEntry.thresholdValue.toString()}
              existingValues={[] as string[]}
              // tslint:disable-next-line:jsx-no-lambda
              callback={async (threshold: string) => {
                this.onKpiEntryThresholdChange(
                  kpiEntryIndex,
                  kpiEntry,
                  Number(threshold)
                );
              }}
            />
          </td> */}
          <td>
            {" "}
            {i === start && addAutofillButton && (
              <Button
                onClick={() =>
                  this.copyToAllEditedKpiEntries(kpiEntry.measuredValue)
                }
              >
                Copy Value To All Months
              </Button>
            )}
          </td>
        </tr>
      );
    }
    return table;
  };

  public componentDidUpdate(prevProps: IKpiEntriesEditTableProps) {
    const {
      accessToken,
      role,
      predicateId,
      clientId,
      financialYear,
      kpiId,
      kpiEntries,
      kpiEntriesLoaded,
      onHandleClearLoadedKpiEntries,
      onHandleLoadKpiEntries
    } = this.props;
    if (
      prevProps.role.id !== role.id ||
      prevProps.predicateId !== predicateId ||
      prevProps.clientId !== clientId ||
      prevProps.financialYear.id !== financialYear.id ||
      prevProps.kpiId !== kpiId
    ) {
      onHandleClearLoadedKpiEntries();
    }
    if (!kpiEntriesLoaded) {
      onHandleLoadKpiEntries(
        accessToken,
        predicateId,
        clientId,
        role.fyStartMonthId,
        financialYear.endYear,
        kpiId
      );
    }
    if (prevProps.kpiEntries !== kpiEntries) {
      if (kpiEntriesLoaded) {
        this.setState(() => ({ editedKpiEntries: kpiEntries }));
      }
    }
  }
  public componentDidMount() {
    const {
      accessToken,
      monthsLoaded,
      onHandleLoadMonths,
      kpiEntries,
      kpiEntriesLoaded,
      onHandleLoadKpiEntries,
      role,
      predicateId,
      clientId,
      financialYear,
      kpiId
    } = this.props;
    if (!monthsLoaded) {
      onHandleLoadMonths(accessToken);
    }
    if (!kpiEntriesLoaded) {
      onHandleLoadKpiEntries(
        accessToken,
        predicateId,
        clientId,
        role.fyStartMonthId,
        financialYear.endYear,
        kpiId
      );
    }
    if (kpiEntriesLoaded) {
      this.setState(() => ({ editedKpiEntries: kpiEntries }));
    }
  }
  public componentWillUnmount() {
    const { onHandleClearLoadedKpiEntries } = this.props;
    onHandleClearLoadedKpiEntries();
  }
  public render() {
    const { role, financialYear, monthsLoaded, kpiEntriesLoaded } = this.props;
    const startMonthId = role.fyStartMonthId;
    return (
      <React.Fragment>
        {!monthsLoaded && <DataLoading dataTitle="Months" />}
        {!kpiEntriesLoaded && <DataLoading dataTitle="KPI Entries" />}
        {monthsLoaded && kpiEntriesLoaded && (
          <React.Fragment>
            <Table striped={true} className="table-sm">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Target Value</th>
                  {/* <th>Threshold Value</th> */}
                </tr>
              </thead>
              <tbody>
                {startMonthId === 1 &&
                  this.createTable(1, 13, financialYear.endYear, true)}
                {startMonthId !== 1 && (
                  <React.Fragment>
                    {this.createTable(
                      startMonthId,
                      13,
                      financialYear.endYear - 1,
                      true
                    )}
                    {this.createTable(1, startMonthId, financialYear.endYear)}
                  </React.Fragment>
                )}
              </tbody>
            </Table>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    role: state.userRoles.find(role => role.id === state.activeRoleId) as IRole,
    // tslint:disable-next-line:object-literal-sort-keys
    financialYear: state.financialYears.find(
      fy => fy.id === state.activeKpiFinancialYearId
    ) as IFinancialYear,
    months: state.fyStartMonths,
    kpiEntries: state.activeKpiEntries,
    kpiEntriesLoaded: state.loadingKpiEntries,
    monthsLoaded: state.loadingFyStartMonths,
    predicateId: state.activeKpiPredicateId,
    clientId: state.activeKpiClientId,
    kpiId: state.activeKpiId
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadMonths: (accessToken: string) => {
    dispatch(handleLoadFyStartMonths(accessToken));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleLoadKpiEntries: (
    accessToken: string,
    predicateId: number,
    clientId: number,
    fyStartMonthId: number,
    endYear: number,
    kpiId: number
  ) => {
    dispatch(
      handleLoadActiveKpiEntries(
        accessToken,
        predicateId,
        clientId,
        fyStartMonthId,
        endYear,
        kpiId
      )
    );
  },
  onHandleClearLoadedKpiEntries: () => {
    dispatch(handleClearActiveKpiEntries());
  },
  onHandleSaveKpiEntry: (accessToken: string, kpiEntry: IKpiEntry) => {
    dispatch(handleSaveKpiEntry(accessToken, kpiEntry));
  },
  onHandleUpdateKpiEntry: (
    accessToken: string,
    kpiEntry: IKpiEntry,
    updatedKpiEntry: IKpiEntry
  ) => {
    dispatch(handleUpdateKpiEntry(accessToken, kpiEntry, updatedKpiEntry));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KpiEntriesEditTable);
