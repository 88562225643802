import IClient from "../models/IClient";
import { fetchSecureData } from "../utils";

export const getRoleClients = async (
  accessToken: string,
  roleId: number,
  predicateId: number
) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/Client/GetClientsForRoleAndPredicate?roleId=${roleId}&predicateId=${predicateId}`,
      accessToken
    );
    const roleDashboards = ((await data.json()) as any).value as IClient[];
    if (roleDashboards !== undefined) {
      return roleDashboards;
    } else {
      return [] as IClient[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/Client/GetClientsForRoleAndPredicate?roleId=${roleId}&predicateId=${predicateId}`,
      err
    );
  }
  return [] as IClient[];
};

export default getRoleClients;
