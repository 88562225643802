import * as React from "react";

export interface IImageProps {
  src: string;
  className?: string;
  alt: string;
  style?: React.CSSProperties;
}
const Image: React.SFC<IImageProps> = (props: IImageProps) => {
  const { alt, className, src, style } = props;
  return (
    <React.Fragment>
      <img src={src} className={className} alt={alt} style={style} />
    </React.Fragment>
  );
};

export default Image;
