import { ITokenData } from "../models/ITokenData";
import { fetchSecureData } from "../utils";

export const getAccessToken = async (accessToken: string) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/PowerBiService/GetAccessToken`,
      accessToken
    );
    const result = await data.json();
    return result as ITokenData;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log("Fetch error for /api/PowerBiService/GetAccessToken", err);
  }
  return {} as ITokenData;
};

export default getAccessToken;
