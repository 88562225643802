import { ITokenData } from "../models/ITokenData";
import { fetchSecureData } from "../utils";

export const refreshAccessToken = async (
  accessToken: string,
  refreshToken: string
) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/PowerBiService/RefreshAccessToken?refreshToken=${refreshToken}`,
      accessToken
    );
    const result = await data.json();
    return result as ITokenData;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/PowerBiService/RefreshAccessToken?refreshToken=${refreshToken}`,
      err
    );
  }
  return {} as ITokenData;
};

export default refreshAccessToken;
