import IClient from "../models/IClient";
import { fetchSecureData } from "../utils";

const getClient = async (accessToken: string, id: number) => {
  const data = await fetchSecureData(
    "GET",
    `/api/Client/GetClientById?id=${id}`,
    accessToken
  );
  const client = ((await data.json()) as any).value as IClient;
  if (client !== undefined) {
    return client;
  } else {
    return {} as IClient;
  }
};

export default getClient;
