import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Table
} from "reactstrap";
import { handleSaveUserProfile } from "../../../actions/profiles";
import paths, { settings } from "../../../constants";
import { IStore } from "../../../models/IStore";
import { validateEmail } from "../../../utils";

export interface IAddUserPageState {
  email: string;
  isEnabled: boolean;
  emailAlertVisible: boolean;
}

export interface IAddUserPageProps extends RouteComponentProps {
  accessToken: string;
  onHandleSaveUser: (
    accessToken: string,
    email: string,
    isEnabled: boolean
  ) => void;
}

export class AddUserPage extends React.Component<
  IAddUserPageProps,
  IAddUserPageState
> {
  public state = {
    email: "",
    isEnabled: true,
    // tslint:disable-next-line:object-literal-sort-keys
    emailAlertVisible: false
  };

  constructor(props: IAddUserPageProps) {
    super(props);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
    this.onDismissEmailAlert = this.onDismissEmailAlert.bind(this);
    this.emailFocus = this.emailFocus.bind(this);
    this.emailIsValid = this.emailIsValid.bind(this);
    this.checkAllInputValid = this.checkAllInputValid.bind(this);
  }

  public emailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const email =
      e.target.value === undefined ? "" : (e.target.value as string);
    this.setState(() => ({ email }));
  };
  public emailFocus = () => {
    this.setState({ emailAlertVisible: false });
  };
  public emailIsValid = () => {
    const { email } = this.state;
    if (email === "" || email === undefined || !validateEmail(email)) {
      this.setState(() => ({ emailAlertVisible: true }));
      return false;
    }
    return true;
  };
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public save = (addMore: boolean = false) => {
    const { accessToken, onHandleSaveUser, history } = this.props;
    const { email, isEnabled } = this.state;
    if (this.checkAllInputValid()) {
      onHandleSaveUser(accessToken, email, isEnabled);
      if (!addMore) {
        history.push(paths.home);
      } else {
        this.setState(() => ({
          email: "",
          isEnabled: true,
          // tslint:disable-next-line:object-literal-sort-keys
          emailAlertVisible: false
        }));
      }
    }
  };
  public cancel(e: React.MouseEvent) {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  }
  public checkAllInputValid() {
    let isValid = true;
    if (!this.emailIsValid()) {
      isValid = false;
    }
    return isValid;
  }
  public onDismissEmailAlert() {
    this.setState(() => ({ emailAlertVisible: false }));
  }
  public render() {
    const { email, isEnabled } = this.state;
    const { allowDisableUsers } = settings;
    return (
      <Form>
        <Alert
          color="danger"
          isOpen={this.state.emailAlertVisible}
          toggle={this.onDismissEmailAlert}
        >
          Email not valid
        </Alert>
        <Card>
          <CardTitle>Add New User Details</CardTitle>
          <CardBody>
            <Table striped={true}>
              {/* <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr>
              </thead> */}
              <tbody>
                <tr>
                  <th scope="row">Email Address</th>
                  <td>
                    {" "}
                    <Input
                      type="text"
                      placeholder="Email Address"
                      value={email}
                      onChange={this.emailChange}
                      onFocus={this.emailFocus}
                      onBlur={
                        // tslint:disable-next-line:jsx-no-lambda
                        async (e: React.ChangeEvent<HTMLElement>) => {
                          await this.setState(() => ({ email: email.trim() }));
                          this.emailIsValid();
                        }
                      }
                    />
                  </td>
                </tr>
                {allowDisableUsers && (
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <Input
                        type="checkbox"
                        checked={isEnabled}
                        onChange={this.isEnabledChange}
                      />
                      {isEnabled}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <React.Fragment>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save()}
                style={{ margin: 4, padding: 4 }}
              >
                Save Changes
              </Button>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save(true)}
                style={{ margin: 4, padding: 4 }}
              >
                Save And Add Another
              </Button>
              <Button onClick={this.cancel} style={{ margin: 4, padding: 4 }}>
                Cancel
              </Button>
            </React.Fragment>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleSaveUser: (
    accessToken: string,
    emailAddress: string,
    isEnabled: boolean
  ) => {
    dispatch(handleSaveUserProfile(accessToken, emailAddress, isEnabled));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserPage);
