import IPbiDashboard from "../models/IPbiDashboard";
import { fetchSecureData } from "../utils";

export const getDashboards = async (accessToken: string) => {
  const data = await fetchSecureData(
    "GET",
    `/api/PbiDashboard/GetPbiDashboards`,
    accessToken
  );
  const dashboards = ((await data.json()) as any).value as IPbiDashboard[];
  if (dashboards !== undefined) {
    return dashboards;
  } else {
    return [] as IPbiDashboard[];
  }
};

export default getDashboards;
