import IKpi from "../models/IKpi";
import { fetchSecureData } from "../utils";

export const saveKpi = async (
  accessToken: string,
  displayName: string,
  name: string,
  isEnabled: boolean
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/Kpi/Add?displayName=${displayName}&name=${name}&isEnabled=${isEnabled}`,
      accessToken
    );
    const kpi = ((await data.json()) as any).value as IKpi;
    return kpi;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/Kpi/Add?displayName=${displayName}&name=${name}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as IKpi;
};

export default saveKpi;
