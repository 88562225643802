import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import ICurrencyCode from "../../models/ICurrencyCode";
import { IStore } from "../../models/IStore";

export interface IAddCurrencyCodeProps {
  currencyCodes: ICurrencyCode[];
  currencyCodeId: number;
  callback: (currencyCodeId: number) => void;
}

export interface IAddCurrencyCodeState {
  currencyCodeId: number;
}

class AddCurrencyCode extends React.Component<IAddCurrencyCodeProps> {
  public state = {
    currencyCodeId: 1
  };
  constructor(props: IAddCurrencyCodeProps) {
    super(props);
    this.currencyCodeIdChange = this.currencyCodeIdChange.bind(this);
  }
  public currencyCodeIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { callback } = this.props;
    e.preventDefault();
    const currencyCodeId =
      e.target.value === undefined ? 1 : Number(e.target.value as string);
    this.setState(() => ({ currencyCodeId }));
    callback(currencyCodeId);
  };
  public async componentDidUpdate(prevProps: IAddCurrencyCodeProps) {
    const { currencyCodeId } = this.props;
    if (
      currencyCodeId !== prevProps.currencyCodeId &&
      currencyCodeId !== undefined &&
      currencyCodeId !== 0
    ) {
      await this.setState(() => ({ currencyCodeId }));
    }
  }
  public async componentDidMount() {
    const { currencyCodeId } = this.props;
    if (currencyCodeId !== undefined && currencyCodeId !== 0) {
      await this.setState(() => ({ currencyCodeId }));
    }
  }
  public render() {
    const { currencyCodes } = this.props;
    const { currencyCodeId } = this.state;
    return (
      <FormGroup>
        <Label for="currencyCodeId">Currency Code:</Label>
        <Input
          id="currencyCodeId"
          type="select"
          placeholder="Currency"
          value={currencyCodeId.toString()}
          onChange={this.currencyCodeIdChange}
        >
          {currencyCodes.map(cc => (
            <option key={cc.id} value={cc.id}>
              {cc.code}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  return {
    callback: ownProps.callback,
    currencyCodeId: ownProps.currencyCodeId,
    currencyCodes: state.currencyCodes
  };
};

export default connect(mapStateToProps)(AddCurrencyCode);
