import { AnyAction } from "redux";
import {
  CLEAR_EDIT_USER_DETAILS,
  SET_EDIT_USER_DETAILS
} from "../actions/editUserDetails";
import IUserProfile from "../models/IUserProfile";

export const editUserDetails = (
  state = {} as IUserProfile,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_EDIT_USER_DETAILS:
      return action.user as IUserProfile;
    case CLEAR_EDIT_USER_DETAILS:
      return {} as IUserProfile;
    default:
      return state;
  }
};

export default editUserDetails;
