import * as React from "react";
import Col from "../atoms/Col";
import Row from "../atoms/Row";

export interface IPageBodyProps {
  children: React.ReactNode;
  rowStyle?: React.CSSProperties;
  colStyle?: React.CSSProperties;
  rowId?: string;
  colId?: string;
}

const PageBody: React.SFC<IPageBodyProps> = (props: IPageBodyProps) => {
  const { children, rowStyle, colStyle, rowId, colId } = props;
  return (
    <Row id={rowId} style={rowStyle}>
      <Col id={colId} style={colStyle}>
        {children}
      </Col>
    </Row>
  );
};

export default PageBody;
