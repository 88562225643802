import * as React from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import {
  handleClearActiveKpiEntries,
  handleLoadActiveKpiEntries
} from "../../actions/activeKpiEntries";
import { handleLoadFyStartMonths } from "../../actions/fyStartMonths";
import IFinancialYear from "../../models/IFinancialYear";
import IFYStartMonth from "../../models/IFYStartMonth";
import IKpiEntry from "../../models/IKpiEntry";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";
import DataLoading from "../organisms/DataLoading";

export interface IKpiEntriesTableProps {
  accessToken: string;
  role: IRole;
  financialYear: IFinancialYear;
  predicateId: number;
  clientId: number;
  kpiId: number;
  kpiEntries: IKpiEntry[];
  kpiEntriesLoaded: boolean;
  months: IFYStartMonth[];
  monthsLoaded: boolean;
  onHandleLoadMonths: (accessToken: string) => void;
  onHandleLoadKpiEntries: (
    accessToken: string,
    predicateId: number,
    clientId: number,
    fyStartMonthId: number,
    endYear: number,
    kpiId: number
  ) => void;
  onHandleClearLoadedKpiEntries: () => void;
}

class KpiEntriesTable extends React.Component<IKpiEntriesTableProps> {
  constructor(props: IKpiEntriesTableProps) {
    super(props);
    this.createTable = this.createTable.bind(this);
  }
  public createTable = (start: number, end: number, year: number) => {
    const { months, kpiEntries } = this.props;
    const table = [];
    for (let i = start; i < end; i++) {
      const kpiEntry = kpiEntries.find(kpie => kpie.monthId === i) as IKpiEntry;
      table.push(
        <tr key={i}>
          <td>
            {(months.find(month => month.id === i) as IFYStartMonth).name}{" "}
            {year}
          </td>
          <td>{kpiEntry === undefined ? 0 : kpiEntry.measuredValue}</td>
          {/* Disable threshold value from UI */}
          {/* <td>{kpiEntry === undefined ? 0 : kpiEntry.thresholdValue}</td> */}
        </tr>
      );
    }
    return table;
  };

  public componentDidUpdate(prevProps: IKpiEntriesTableProps) {
    const {
      accessToken,
      role,
      predicateId,
      clientId,
      financialYear,
      kpiId,
      kpiEntriesLoaded,
      onHandleClearLoadedKpiEntries,
      onHandleLoadKpiEntries
    } = this.props;
    if (
      prevProps.role.id !== role.id ||
      prevProps.predicateId !== predicateId ||
      prevProps.clientId !== clientId ||
      prevProps.financialYear.id !== financialYear.id ||
      prevProps.kpiId !== kpiId
    ) {
      onHandleClearLoadedKpiEntries();
    }
    if (!kpiEntriesLoaded) {
      onHandleLoadKpiEntries(
        accessToken,
        predicateId,
        clientId,
        role.fyStartMonthId,
        financialYear.endYear,
        kpiId
      );
    }
  }
  public componentDidMount() {
    const {
      accessToken,
      role,
      monthsLoaded,
      onHandleLoadMonths,
      kpiEntriesLoaded,
      onHandleLoadKpiEntries,
      predicateId,
      clientId,
      financialYear,
      kpiId
    } = this.props;
    if (!monthsLoaded) {
      onHandleLoadMonths(accessToken);
    }
    if (!kpiEntriesLoaded) {
      onHandleLoadKpiEntries(
        accessToken,
        predicateId,
        clientId,
        role.fyStartMonthId,
        financialYear.endYear,
        kpiId
      );
    }
  }
  public componentWillUnmount() {
    const { onHandleClearLoadedKpiEntries } = this.props;
    onHandleClearLoadedKpiEntries();
  }
  public render() {
    const { role, financialYear, monthsLoaded, kpiEntriesLoaded } = this.props;
    const startMonthId = role.fyStartMonthId;
    return (
      <React.Fragment>
        {!monthsLoaded && <DataLoading dataTitle="Months" />}
        {!kpiEntriesLoaded && <DataLoading dataTitle="KPI Entries" />}
        {monthsLoaded && kpiEntriesLoaded && (
          <Table striped={true}>
            <thead>
              <tr>
                <th>Month</th>
                <th>Target Value</th>
                {/* <th>Threshold Value</th> */}
              </tr>
            </thead>
            <tbody>
              {this.createTable(
                startMonthId,
                13,
                startMonthId === 1
                  ? financialYear.endYear
                  : financialYear.endYear - 1
              )}
              {this.createTable(1, startMonthId, financialYear.endYear)}
            </tbody>
          </Table>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    role: state.userRoles.find(role => role.id === state.activeRoleId) as IRole,
    // tslint:disable-next-line:object-literal-sort-keys
    financialYear: state.financialYears.find(
      fy => fy.id === state.activeKpiFinancialYearId
    ) as IFinancialYear,
    months: state.fyStartMonths,
    kpiEntries: state.activeKpiEntries,
    kpiEntriesLoaded: state.loadingKpiEntries,
    monthsLoaded: state.loadingFyStartMonths,
    predicateId: state.activeKpiPredicateId,
    clientId: state.activeKpiClientId,
    kpiId: state.activeKpiId
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadMonths: (accessToken: string) => {
    dispatch(handleLoadFyStartMonths(accessToken));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleLoadKpiEntries: (
    accessToken: string,
    predicateId: number,
    clientId: number,
    fyStartMonthId: number,
    endYear: number,
    kpiId: number
  ) => {
    dispatch(
      handleLoadActiveKpiEntries(
        accessToken,
        predicateId,
        clientId,
        fyStartMonthId,
        endYear,
        kpiId
      )
    );
  },
  onHandleClearLoadedKpiEntries: () => {
    dispatch(handleClearActiveKpiEntries());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KpiEntriesTable);
