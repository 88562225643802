import IPredicate from "../models/IPredicate";
import { fetchSecureData } from "../utils";

export const getRoleKpiGroupingPredicates = async (
  accessToken: string,
  roleId: number
) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/Predicate/GetKpiGroupingsForRole?roleId=${roleId}`,
      accessToken
    );
    // tslint:disable-next-line:no-console
    const roleKpiGroupingPredicates = ((await data.json()) as any)
      .value as IPredicate[];
    if (roleKpiGroupingPredicates !== undefined) {
      return roleKpiGroupingPredicates;
    } else {
      return [] as IPredicate[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/Predicate/GetKpiGroupingsForRole?roleId=${roleId}`,
      err
    );
  }
  return [] as IPredicate[];
};

export default getRoleKpiGroupingPredicates;
