import { AnyAction } from "redux";
import {
  ADD_USER_GROUP,
  LOAD_USER_GROUPS,
  REMOVE_USER_GROUP
} from "../actions/userGroups";
import IUserGroup from "../models/IGroup";

export const userGroups = (
  state = [] as IUserGroup[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_USER_GROUP:
      const newState: IUserGroup[] = state.slice();
      newState.push(action.userGroup as IUserGroup);
      return newState;
    case REMOVE_USER_GROUP:
      return Object.assign(
        {},
        state.filter((r: IUserGroup) => r.id !== (action.id as number))
      );
    case LOAD_USER_GROUPS:
      return action.userGroups as IUserGroup[];
    default:
      return state;
  }
};

export default userGroups;
