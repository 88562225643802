import { AnyAction } from "redux";
import { SET_USER_DASHBOARDS_LOADED } from "../actions/loadingUserDashboards";

export const loadingUserDashboards = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_USER_DASHBOARDS_LOADED:
      return action.userDashboardsLoaded;
    default:
      return state;
  }
};

export default loadingUserDashboards;
