import * as Redux from "redux";
import getRoleDashboards from "../api/getRoleDashboards";
import removeRoleDashboard from "../api/removeRoleDashboard";
import saveRoleDashboard from "../api/saveRoleDashboard";
import { setEditRoleDashboardsLoaded } from "./loadingEditRoleDashboards";
export const ADD_EDIT_ROLE_DASHBOARD_ID = "ADD_EDIT_ROLE_DASHBOARD_ID";
export const REMOVE_EDIT_ROLE_DASHBOARD_ID = "REMOVE_EDIT_ROLE_DASHBOARD_ID";
export const LOAD_EDIT_ROLE_DASHBOARD_IDS = "LOAD_EDIT_ROLE_DASHBOARD_IDS";
export const CLEAR_EDIT_ROLE_DASHBOARD_IDS = "CLEAR_EDIT_ROLE_DASHBOARD_IDS";

export const addEditRoleDashboardId = (id: number) => {
  return {
    id,
    type: ADD_EDIT_ROLE_DASHBOARD_ID
  };
};

export const removeEditRoleDashboardId = (id: number) => {
  return {
    id,
    type: REMOVE_EDIT_ROLE_DASHBOARD_ID
  };
};

export const loadEditRoleDashboardIds = (ids: number[]) => {
  return {
    ids,
    type: LOAD_EDIT_ROLE_DASHBOARD_IDS
  };
};

export const clearEditRoleDashboardIds = () => {
  return {
    type: CLEAR_EDIT_ROLE_DASHBOARD_IDS
  };
};

export const handleLoadEditRoleDashboardIds = (
  accessToken: string,
  roleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const roleDashboards = await getRoleDashboards(accessToken, roleId);
    if (roleDashboards === undefined) {
      dispatch(clearEditRoleDashboardIds());
      dispatch(setEditRoleDashboardsLoaded(false));
    } else {
      if (roleDashboards.length > 0) {
        dispatch(
          loadEditRoleDashboardIds(
            roleDashboards.map(dashboard => dashboard.id)
          )
        );
      }
      dispatch(setEditRoleDashboardsLoaded(true));
    }
  };
};

export const handleSaveEditRoleDashboardId = (
  accessToken: string,
  roleId: number,
  dashboardId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await saveRoleDashboard(accessToken, roleId, dashboardId);
    dispatch(addEditRoleDashboardId(dashboardId));
  };
};

export const handleDeleteEditRoleDashboardId = (
  accessToken: string,
  roleId: number,
  dashboardId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await removeRoleDashboard(accessToken, roleId, dashboardId);
    dispatch(removeEditRoleDashboardId(dashboardId));
  };
};
