import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, CardFooter, CardTitle } from "reactstrap";
import UserDetailsTable from "../../../components/organisms/UserDetailsTable";
import paths from "../../../constants";
import { IStore } from "../../../models/IStore";
import IUserProfile from "../../../models/IUserProfile";

export interface IUserSummaryState {
  activeEditRoleUsers: IUserProfile[];
}

export interface IUserSummaryProps extends RouteComponentProps {
  user: IUserProfile;
}

class UserSummary extends React.Component<IUserSummaryProps> {
  public render() {
    const { history, user /*, roles, roleIds, groups, groupIds */ } = this.props;
    return (
      <Card>
        <CardTitle tag="h3">Review and Complete</CardTitle>
        <CardBody>
          <h4>User Details:</h4>
          <UserDetailsTable
            user={user}
          />
        </CardBody>
        <CardFooter>
          <Button
            // tslint:disable-next-line:jsx-no-lambda
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              history.push(paths.home)
            }
          >
            Complete
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  return {
    user: state.editUserDetails,
  };
};

export default connect(mapStateToProps)(UserSummary);
