import ISqlReport from "../models/ISqlReport";
import { fetchSecureData } from "../utils";

export const getReports = async (accessToken: string) => {
  const data = await fetchSecureData(
    "GET",
    `/api/SqlReport/GetSqlReports`,
    accessToken
  );
  const reports = ((await data.json()) as any).value as ISqlReport[];
  if (reports !== undefined) {
    return reports;
  } else {
    return [] as ISqlReport[];
  }
};

export default getReports;
