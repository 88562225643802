import IUserProfile from "../models/IUserProfile";
import { fetchSecureData } from "../utils";

export const saveUserProfile = async (
  accessToken: string,
  emailAddress: string,
  isEnabled: boolean
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/UserProfile/Add?emailAddress=${emailAddress}&isEnabled=${isEnabled}`,
      accessToken
    );
    const userProfile = ((await data.json()) as any).value as IUserProfile;
    return userProfile;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/UserProfile/Add?emailAddress=${emailAddress}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as IUserProfile;
};

export default saveUserProfile;
