import * as React from 'react';

export interface INavListRightProps{
  children: React.ReactNode;
}

const NavListRight:React.SFC<INavListRightProps> = (props:INavListRightProps) => {
  const {children} = props;
  return (
    <ul className="navbar-nav flex-row ml-md-auto d-none d-md-flex">
      {children}
    </ul>
  );
};

export default NavListRight;