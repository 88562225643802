import { AnyAction } from "redux";
import { SET_EDIT_ROLE_DASHBOARDS_LOADED } from "../actions/loadingEditRoleDashboards";

export const loadingEditRoleDashboards = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_EDIT_ROLE_DASHBOARDS_LOADED:
      return action.editRoleDashboardsLoaded;
    default:
      return state;
  }
};

export default loadingEditRoleDashboards;
