import { fetchSecureData } from "../utils";

export const removeRoleReport = async (accessToken: string, roleId: number, reportId: number) => {
  try {
    const data = await fetchSecureData(
      "DELETE",
      `/api/RoleReport/Remove?roleId=${roleId}&reportId=${reportId}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/RoleReport/Remove?roleId=${roleId}&reportId=${reportId}`, err);
  }
  return 404;
};

export default removeRoleReport;
