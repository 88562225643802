import { AnyAction } from "redux";
import { SET_EDIT_ROLE_PREDICATES_LOADED } from "../actions/loadingEditRolePredicates";

export const loadingEditRolePredicates = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_EDIT_ROLE_PREDICATES_LOADED:
      return action.editRolePredicatesLoaded;
    default:
      return state;
  }
};

export default loadingEditRolePredicates;
