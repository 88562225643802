import { AnyAction } from "redux";
import {
  CLEAR_ACTIVE_KPI_FY_ID,
  SET_ACTIVE_KPI_FY_ID
} from "../actions/activeKpiFinancialYear";

export const activeKpiFinancialYear = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case SET_ACTIVE_KPI_FY_ID:
      return action.id as number;
    case CLEAR_ACTIVE_KPI_FY_ID:
      return 0;
    default:
      return state;
  }
};

export default activeKpiFinancialYear;
