import { AnyAction } from "redux";
import {
  ADD_USER_REPORT,
  LOAD_USER_REPORTS,
  REMOVE_USER_REPORT
} from "../actions/userReports";
import ISqlReport from "../models/ISqlReport";

export const userReports = (state = [] as ISqlReport[], action: AnyAction) => {
  switch (action.type) {
    case ADD_USER_REPORT:
      const newState: ISqlReport[] = state.slice();
      newState.push(action.userReport as ISqlReport);
      return newState;
    case REMOVE_USER_REPORT:
      return Object.assign(
        {},
        state.filter((r: ISqlReport) => r.id !== (action.id as number))
      );
    case LOAD_USER_REPORTS:
      return action.userReports as ISqlReport[];
    default:
      return state;
  }
};

export default userReports;
