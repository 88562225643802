import IPredicate from "../models/IPredicate";
import { fetchSecureData } from "../utils";

export const getPredicates = async (accessToken: string) => {
  const data = await fetchSecureData(
    "GET",
    `/api/Predicate/GetPredicates`,
    accessToken
  );
  const predicates = ((await data.json()) as any).value as IPredicate[];
  if (predicates !== undefined) {
    return predicates;
  } else {
    return [] as IPredicate[];
  }
};

export default getPredicates;
