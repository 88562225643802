import * as React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardFooter, CardTitle } from "reactstrap";
import { IStore } from "../../models/IStore";

export interface IProfileCardProps {
  emailAddress: string;
  name: string;
  photo: string;
}

const ProfileCard: React.SFC<IProfileCardProps> = (
  props: IProfileCardProps
) => {
  const { emailAddress, name, photo } = props;
  return (
    <Card style={{ textAlign: "center" }}>
      <CardTitle>
        <strong>{emailAddress}</strong>
      </CardTitle>
      <CardBody>
        <img alt={photo} src={photo} style={{ height: "80px", borderRadius: 50 }} />
      </CardBody>
      <CardFooter> Full Name: {name}</CardFooter>
    </Card>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    emailAddress: state.auth0.getProfile().email as string,
    name: state.auth0.getProfile().name as string,
    photo: state.auth0.getProfile().picture as string
  };
};

export default connect(mapStateToProps)(ProfileCard);
