import { AnyAction } from "redux";
import { SET_ACTIVE_USER_PROFILE } from "../actions/activeUserProfile";
import IUserProfile from "../models/IUserProfile";

export const userProfile = (
  state = {} as IUserProfile,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_ACTIVE_USER_PROFILE:
      return Object.assign({}, state, action.user as IUserProfile);
    default:
      return state;
  }
};

export default userProfile;
