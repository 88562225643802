import * as React from "react";
import { Route, Switch } from "react-router";
import SecureAdminRoute from "../../components/templates/SecureAdminRoute";
import SecureRoute from "../../components/templates/SecureRoute";
import {
  routes,
  secureAdminRoutes,
  secureRoutes
} from "../../routes/RouteList";
import TopNavBar from "./TopNavBar";

const PageHeaderRoutes = () => {
  return (
    <Switch>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.topbar}
        />
      ))}
      {secureRoutes.map(route => (
        <SecureRoute
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.topbar}
        />
      ))}
      {secureAdminRoutes.map(route => (
        <SecureAdminRoute
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.topbar}
        />
      ))}
      <Route component={TopNavBar} />
    </Switch>
  );
};

export default PageHeaderRoutes;
