import { fetchSecureData } from "../utils";

import IGroup from "../models/IGroup";

export const getGroups = async (accessToken: string) => {
  const data = await fetchSecureData(
    "GET",
    `/api/Group/GetGroups`,
    accessToken
  );
  const groups = ((await data.json()) as any).value as IGroup[];
  if (groups !== undefined) {
    return groups;
  } else {
    return [] as IGroup[];
  }
};

export default getGroups;
