import * as React from "react";
import { connect } from "react-redux";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap";
import {
  clearEditUserRoleIds,
  handleDeleteEditUserRoleId,
  handleLoadEditUserRoleIds,
  handleSaveEditUserRoleId
} from "../../../actions/editUserRoleIds";
import { handleLoadRoles } from "../../../actions/roles";
import RolesBadge from "../../../components/molecules/RolesBadge";
import IRole from "../../../models/IRole";
import { IStore } from "../../../models/IStore";

export interface IAssignUserRolesState {
  editUserRoleIds: number[];
  filteredRoles: IRole[];
}

export interface IAssignUserRolesProps {
  accessToken: string;
  roles: IRole[];
  rolesLoaded:boolean;
  editUserRoleIds: number[];
  userId: number;
  onHandleLoadRoles: (accessToken: string) => void;
  onHandleLoadEditUserRoleIds: (accessToken: string, userId: number) => void;
  onClearEditUserRoleIds: () => void;
  onHandleSaveEditUserRoleId: (
    accessToken: string,
    userId: number,
    roleId: number
  ) => void;
  onHandleDeleteEditUserRoleId: (
    accessToken: string,
    userId: number,
    roleId: number
  ) => void;
}

class AssignUserRoles extends React.Component<
  IAssignUserRolesProps,
  IAssignUserRolesState
> {
  public state = {
    editUserRoleIds: [] as number[],
    filteredRoles: [] as IRole[]
  };
  constructor(props: IAssignUserRolesProps) {
    super(props);
    this.roleCheckboxChange = this.roleCheckboxChange.bind(this);
    this.txtFilterChange = this.txtFilterChange.bind(this);
  }
  public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { roles } = this.props;
    const filteredRoles = roles.filter(role =>
      role.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    await this.setState(() => ({ filteredRoles }));
  };
  public roleCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      accessToken,
      userId,
      onHandleSaveEditUserRoleId,
      onHandleDeleteEditUserRoleId
    } = this.props;
    e.preventDefault();
    if (e.target.checked) {
      await onHandleSaveEditUserRoleId(
        accessToken,
        userId,
        Number(e.target.value)
      );
    } else {
      await onHandleDeleteEditUserRoleId(
        accessToken,
        userId,
        Number(e.target.value)
      );
    }
  };
  public async componentDidMount() {
    const {
      accessToken,
      roles,
      rolesLoaded,
      editUserRoleIds,
      onHandleLoadRoles,
      onHandleLoadEditUserRoleIds,
      userId
    } = this.props;
    if (!rolesLoaded) {
      await onHandleLoadRoles(accessToken);
    }
    await onHandleLoadEditUserRoleIds(accessToken, userId);
    this.setState({ editUserRoleIds });
    this.setState({ filteredRoles: roles });
  }

  public async componentDidUpdate(prevProps: IAssignUserRolesProps) {
    const { editUserRoleIds, roles } = this.props;
    if (prevProps.roles !== roles) {
      await this.setState(() => ({ filteredRoles: roles }));
    }
    if (prevProps.editUserRoleIds !== editUserRoleIds) {
      await this.setState(() => ({ editUserRoleIds }));
    }
  }

  public render() {
    const { filteredRoles, editUserRoleIds } = this.state;
    const { roles } = this.props;
    return (
      <Container>
        <Row>
          <Col>
            <h2>Assign Roles</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <RolesBadge roles={roles} roleIds={editUserRoleIds} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="txtFilter">Search: </Label>
            <Input
              type="text"
              id="txtFilter"
              onChange={this.txtFilterChange}
              placeholder="Search for..."
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup>
              {filteredRoles.map(role => (
                <ListGroupItem key={role.id}>
                  <FormGroup check={true}>
                    <Label check={true}>
                      <Input
                        type="checkbox"
                        value={role.id}
                        onChange={this.roleCheckboxChange}
                        checked={
                          this.state.editUserRoleIds.find(
                            id => id === role.id
                          ) !== undefined
                        }
                      />
                      {role.displayName}
                    </Label>
                  </FormGroup>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  const { onSave } = ownProps;
  return {
    accessToken: state.auth0.accessToken,
    roles: state.roles,
    rolesLoaded: state.loadingRoles,
    userId: state.editUserDetails.id,
    // tslint:disable-next-line:object-literal-sort-keys
    editUserRoleIds: state.editUserRoleIds,
    onSave
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadRoles: (accessToken: string) => {
    dispatch(handleLoadRoles(accessToken));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleLoadEditUserRoleIds: (accessToken: string, userId: number) => {
    dispatch(handleLoadEditUserRoleIds(accessToken, userId));
  },
  onClearEditUserRoleIds: () => {
    dispatch(clearEditUserRoleIds());
  },
  onHandleSaveEditUserRoleId: (
    accessToken: string,
    userId: number,
    roleId: number
  ) => {
    dispatch(handleSaveEditUserRoleId(accessToken, userId, roleId));
  },
  onHandleDeleteEditUserRoleId: (
    accessToken: string,
    userId: number,
    roleId: number
  ) => {
    dispatch(handleDeleteEditUserRoleId(accessToken, userId, roleId));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignUserRoles);
