export const SET_ACTIVE_KPI_ID = "SET_ACTIVE_KPI_ID";
export const CLEAR_ACTIVE_KPI_ID = "CLEAR_ACTIVE_KPI_ID";

export const setActiveKpiId = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_KPI_ID
  };
};

export const clearActiveKpiId = () => {
  return {
    type: CLEAR_ACTIVE_KPI_ID
  };
};
