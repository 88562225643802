import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import DataLoading from "../../../components/organisms/DataLoading";
import paths from "../../../constants";
import IKpi from "../../../models/IKpi";
import { IStore } from "../../../models/IStore";
import { handleLoadKpis } from "../../../actions/kpis";

export interface ISearchKpisPageProps {
  accessToken: string;
  onHandleLoadKpis: (accessToken: string) => void;
  kpis: IKpi[];
  kpisLoaded: boolean;
}

export interface ISearchKpisPageState {
  filteredKpis: IKpi[];
  matchedNameExact: boolean;
  displayName: string;
  noMatchingNames: boolean;
}
class SearchKpisPage extends React.Component<
  ISearchKpisPageProps,
  ISearchKpisPageState
  > {
  public isLoaded = false;
  public state = {
    filteredKpis: [] as IKpi[],
    matchedNameExact: false,
    // tslint:disable-next-line:object-literal-sort-keys
    displayName: "",
    noMatchingNames: false
  };
  constructor(props: ISearchKpisPageProps) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  public handleInputChange(e: any): void {
    e.preventDefault();
    const { kpis } = this.props;

    const searchKpiName = e.target.value.toLowerCase();
    if (!searchKpiName) {
      this.setState({
        filteredKpis: kpis
      });
    } else {
      const filteredKpis: IKpi[] = kpis.filter((kpi: IKpi) =>
        kpi.displayName.toLowerCase().includes(searchKpiName)
      );
      if (filteredKpis.length === 1) {
        this.setState({ matchedNameExact: true, noMatchingNames: false });
      } else if (filteredKpis.length === 0) {
        this.setState({ noMatchingNames: true, matchedNameExact: false });
      } else {
        this.setState({ matchedNameExact: false, noMatchingNames: false });
      }
      this.setState({
        filteredKpis
      });
    }
  }

  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadKpis,
      kpis,
      kpisLoaded
    } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (!kpisLoaded) {
        onHandleLoadKpis(accessToken);
      }
      this.setState({ filteredKpis: kpis });
    }
  }

  public componentDidUpdate(prevProps: ISearchKpisPageProps) {
    const { kpis } = this.props;
    if (prevProps.kpis !== kpis) {
      this.setState({
        filteredKpis: kpis
      });
    }
  }

  public render() {
    const { kpis, kpisLoaded } = this.props;
    const {
      noMatchingNames,
      matchedNameExact,
      displayName,
      filteredKpis
    } = this.state;
    return (
      <React.Fragment>
        <h1 style={{ backgroundColor: "white" }}>Kpi Administration</h1>
        {!kpisLoaded && <DataLoading dataTitle="Kpis" />}
        {kpisLoaded && (
          <React.Fragment>
            <FormGroup>
              <Card>
                <CardTitle>
                  <Label for="displayName">Select Kpi To Edit</Label>
                </CardTitle>
                <CardBody tag="span">
                  <Input
                    type="text"
                    name="displayName"
                    id="displayName"
                    placeholder="Filter Kpi"
                    onChange={this.handleInputChange}
                    valid={matchedNameExact}
                    invalid={noMatchingNames}
                  />
                  <FormFeedback valid={true}>
                    {matchedNameExact && (
                      <span>
                        Kpi found! Select to edit
                        {displayName}
                      </span>
                    )}
                  </FormFeedback>
                  <FormFeedback invalid="true">
                    {noMatchingNames && (
                      <span>No Kpis match displayName entered </span>
                    )}
                  </FormFeedback>
                </CardBody>
              </Card>
            </FormGroup>
            <Card>
              <CardBody>
                {kpis.length > 0 &&
                  filteredKpis.map((kpi: IKpi) => (
                    <div key={kpi.id}>
                      <Link to={paths.editKpi.concat(kpi.id.toString())}>
                        <span>{kpi.displayName}</span>
                      </Link>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    kpis: state.kpis,
    kpisLoaded: state.loadingKpis
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadKpis: (accessToken: string) => {
    dispatch(handleLoadKpis(accessToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchKpisPage);
