export const SET_ACTIVE_KPI_PREDICATE_ID = "SET_ACTIVE_KPI_PREDICATE_ID";
export const CLEAR_ACTIVE_KPI_PREDICATE_ID = "CLEAR_ACTIVE_KPI_PREDICATE_ID";

export const setActiveKpiPredicateId = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_KPI_PREDICATE_ID
  };
};

export const clearActiveKpiPredicateId = () => {
  return {
    type: CLEAR_ACTIVE_KPI_PREDICATE_ID
  };
};
