import * as React from "react";
import { Progress } from "reactstrap";
import { userWizardStages } from "../../constants";

export interface IUserWizardProgressProps {
  currentStage: number;
}
const UserWizardProgress: React.SFC<IUserWizardProgressProps> = (
  props: IUserWizardProgressProps
) => {
  const { currentStage } = props;
  return (
    <Progress multi={true}>
      {currentStage >= userWizardStages.addInitialUserDetails && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === userWizardStages.addInitialUserDetails}
          value="30"
        >
          {currentStage === userWizardStages.addInitialUserDetails && (
            <span>Initial User Details</span>
          )}
        </Progress>
      )}
      {currentStage >= userWizardStages.assignRoles&& (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === userWizardStages.assignRoles}
          value="30"
        >
          {currentStage === userWizardStages.assignRoles && (
            <span>Assign Roles</span>
          )}
        </Progress>
      )}
      {currentStage >= userWizardStages.assignGroups && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === userWizardStages.assignGroups}
          value="30"
        >
          {currentStage === userWizardStages.assignGroups && (
            <span>Assign Groups</span>
          )}
        </Progress>
      )}
      {currentStage >= userWizardStages.reviewAndComplete && (
        <Progress
          color="success"
          bar={true}
          animated={currentStage === userWizardStages.reviewAndComplete}
          value="10"
        >
          {currentStage === userWizardStages.reviewAndComplete && (
            <span>Review and Complete</span>
          )}
        </Progress>
      )}
    </Progress>
  );
};

export default UserWizardProgress;
