import * as React from "react";
import { Table } from "reactstrap";
import IPredicate from "../../models/IPredicate";
import IRolePredicate from "../../models/IRolePredicate";

export interface IRolePredicatesTableProps {
  rolePredicates: IRolePredicate[];
  predicates: IPredicate[];
}

const RolePredicatesTable: React.SFC<IRolePredicatesTableProps> = (
  props: IRolePredicatesTableProps
) => {
  const { predicates, rolePredicates } = props;
  return (
    <React.Fragment>
      {rolePredicates.length > 0 && (
        <Table striped={true}>
          <thead>
            <tr>
              <th>Predicate Name</th>
              <th>Predicate Value</th>
            </tr>
          </thead>
          <tbody>
            {rolePredicates.map(sp => (
              <React.Fragment key={sp.predicateId}>
                <tr>
                  <td>{(predicates.find(predicate=>predicate.id===sp.predicateId) as IPredicate).name}</td>
                  <td>
                    {sp.value.length > 25 ? (
                      <textarea
                        disabled={true}
                        style={{ width: "400px", height: "200px" }}
                        value={sp.value}
                      />
                    ) : (
                      sp.value
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}
      {rolePredicates.length === 0 && <div>None defined</div>}
    </React.Fragment>
  );
};

export default RolePredicatesTable;
