import IUserProfile from "../models/IUserProfile";
import { fetchSecureData } from "../utils";
import saveUserProfile from "./saveUserProfile";

export const getUser = async (accessToken: string, emailAddress: string) => {
  try {
    let data = await fetchSecureData(
      "GET",
      `/api/UserProfile/GetUserProfileByEmail?emailAddress=${emailAddress}`,
      accessToken
    );
    let user = ((await data.json()) as any).value as IUserProfile;
    if (user === undefined) {
      // email address is not in our database, so add it
      await saveUserProfile(accessToken, emailAddress, true);
      data = await fetchSecureData(
        "GET",
        `/api/UserProfile/GetUserProfileByEmail?emailAddress=${emailAddress}`,
        accessToken
      );
      user = ((await data.json()) as any).value as IUserProfile;
    }
    return user;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/UserProfile/GetUserProfileByEmail?emailAddress=${emailAddress}`,
      err
    );
  }
  return {} as IUserProfile;
};

export default getUser;
