import * as React from "react";
import { Input, Table } from "reactstrap";
import ISqlReport from "../../models/ISqlReport";
import { settings } from "../../constants";

export interface IReportDetailsTableProps {
  report: ISqlReport;
}

const ReportDetailsTable: React.SFC<IReportDetailsTableProps> = (
  props: IReportDetailsTableProps
) => {
  const { report } = props;
  const {
    displayName,
    name,
    sqlReportingViewerUrl,
    sqlReportingServerUrl,
    path,
    parameters,
    defaultZoomMode,
    defaultZoomPercent,
    isEnabled
  } = report;
  const {
    allowAlternateSqlReportingServerUrl,
    allowAlternateSqlReportingViewerUrl,
    allowDisableReports
  } = settings;
  return (
    <Table striped={true}>
      {/* <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead> */}
      <tbody>
        <tr>
          <th scope="row">Display Name</th>
          <td> {displayName}</td>
        </tr>
        <tr>
          <th scope="row">Name</th>
          <td> {name}</td>
        </tr>
        <tr>
          <th scope="row">Path</th>
          <td> {path}</td>
        </tr>
        <tr>
          <th scope="row">Parameters</th>
          <td> {parameters}</td>
        </tr>
        <tr>
          <th scope="row">Default Zoom Mode</th>
          <td> {defaultZoomMode}</td>
        </tr>
        {defaultZoomMode === "Percent" && (
        <tr>
          <th scope="row">Default Zoom Percent</th>
          <td> {defaultZoomPercent}</td>
        </tr>
        )}
        {allowAlternateSqlReportingViewerUrl && (
          <tr>
            <th scope="row">SQL Reporting Viewer URL</th>
            <td>
              {" "}
              {sqlReportingViewerUrl === "" ||
              sqlReportingServerUrl === undefined
                ? "DEFAULT"
                : sqlReportingViewerUrl}
            </td>
          </tr>
        )}
        {allowAlternateSqlReportingServerUrl && (
          <tr>
            <th scope="row">SQL Reporting Server URL</th>
            <td>
              {" "}
              {sqlReportingServerUrl === "" ||
              sqlReportingServerUrl === undefined
                ? "DEFAULT"
                : sqlReportingServerUrl}
            </td>
          </tr>
        )}
        {allowDisableReports && (
          <tr>
            <th scope="row">Enabled</th>
            <td>
              <Input type="checkbox" checked={isEnabled} readOnly={true} />
              {isEnabled}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ReportDetailsTable;
