import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  Table
} from "reactstrap";
import {
  handleLoadFinancialYears,
  handleSaveFinancialYear
} from "../../../actions/financialYears";
import AddTextField from "../../../components/molecules/AddTextField";
import { paths, settings } from "../../../constants";
import IFinancialYear from "../../../models/IFinancialYear";
import { IStore } from "../../../models/IStore";
import {
  validateFinancialYearDisplayName,
  validateFinancialYearEndYear
} from "../../../utils";

export interface IAddFinancialYearPageProps extends RouteComponentProps {
  accessToken: string;
  existingFinancialYears: IFinancialYear[];
  onHandleLoadFinancialYears: (accessToken: string) => void;
  onHandleSaveFinancialYear: (
    accessToken: string,
    displayName: string,
    endYear: number,
    isEnabled: boolean
  ) => void;
}
export interface IAddFinancialYearPageState {
  displayName: string;
  endYear: number;
  isEnabled: boolean;
  isReadyToSave: boolean;
}

class AddFinancialYearPage extends React.Component<
  IAddFinancialYearPageProps,
  IAddFinancialYearPageState
> {
  public isLoaded = false;
  public state = {
    displayName: "",
    endYear: 0,
    // tslint:disable-next-line:object-literal-sort-keys
    isEnabled: true,
    isReadyToSave: false
  };

  constructor(props: IAddFinancialYearPageProps) {
    super(props);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public readyToSave = () => {
    const { displayName, endYear } = this.state;

    if (
      displayName === undefined ||
      displayName === "" ||
      !validateFinancialYearDisplayName(displayName)
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }

    if (endYear === undefined || endYear === 0 || isNaN(endYear)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }

    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public save = (addMore: boolean = false) => {
    const { accessToken, onHandleSaveFinancialYear, history } = this.props;
    const { displayName, endYear, isEnabled } = this.state;
    if (this.readyToSave()) {
      onHandleSaveFinancialYear(accessToken, displayName, endYear, isEnabled);
      if (!addMore) {
        history.push(paths.home);
      } else {
        this.setState(() => ({
          displayName: "",
          endYear: 0,
          // tslint:disable-next-line:object-literal-sort-keys
          isEnabled: true
        }));
      }
    }
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };

  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadFinancialYears,
      existingFinancialYears
    } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingFinancialYears.length === 0) {
        onHandleLoadFinancialYears(accessToken);
      }
    }
  }
  public render() {
    const { existingFinancialYears } = this.props;
    const { displayName, endYear, isEnabled } = this.state;
    const { allowDisableFinancialYears } = settings;
    return (
      <Form>
        <Card>
          <CardHeader>Add New Financial Year</CardHeader>
          <CardBody>
            <React.Fragment>
              <AddTextField
                noDuplicates={true}
                isValid={validateFinancialYearDisplayName}
                title="Name"
                value={displayName}
                existingValues={existingFinancialYears.map(
                  fy => fy.displayName
                )}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (n: string) => {
                  await this.setState(() => ({ displayName: n }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                noDuplicates={true}
                isValid={validateFinancialYearEndYear}
                title="End Year"
                value={endYear.toString()}
                existingValues={existingFinancialYears.map(fy =>
                  fy.endYear.toString()
                )}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (ey: string) => {
                  await this.setState(() => ({ endYear: Number(ey) }));
                  this.readyToSave();
                }}
              />
              {allowDisableFinancialYears && (
                <Table>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <Input
                        type="checkbox"
                        defaultChecked={isEnabled}
                        onChange={this.isEnabledChange}
                      />
                      {isEnabled}
                    </td>
                  </tr>
                </Table>
              )}
            </React.Fragment>
          </CardBody>
          <CardFooter>
            <React.Fragment>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save()}
                style={{ margin: 4, padding: 4 }}
              >
                Save Changes
              </Button>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save(true)}
                style={{ margin: 4, padding: 4 }}
              >
                Save And Add Another
              </Button>
              <Button onClick={this.cancel} style={{ margin: 4, padding: 4 }}>
                Cancel
              </Button>
            </React.Fragment>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingFinancialYears: state.financialYears
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadFinancialYears: (accessToken: string) => {
    dispatch(handleLoadFinancialYears(accessToken));
  },
  onHandleSaveFinancialYear: (
    accessToken: string,
    displayName: string,
    endYear: number,
    isEnabled: boolean
  ) => {
    dispatch(
      handleSaveFinancialYear(accessToken, displayName, endYear, isEnabled)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFinancialYearPage);
