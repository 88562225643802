import * as React from "react";
import Manage from "../../assets/icons/recentWhite.svg";
import Icon from "./Icon";

const KpiIcon = () => {
  return (
    <React.Fragment>
      <Icon icon={Manage} text="KPI Management" id="kpiManage" />
    </React.Fragment>
  );
};

export default KpiIcon;
