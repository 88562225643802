import * as React from "react";
import { connect } from "react-redux";
import { Input, Table } from "reactstrap";
import { handleLoadEditUserGroupIds } from "../../actions/editUserGroupIds";
import { handleLoadEditUserRoleIds } from "../../actions/editUserRoleIds";
import { handleLoadGroups } from "../../actions/groups";
import { handleLoadRoles } from "../../actions/roles";
import { settings } from "../../constants";
import IGroup from "../../models/IGroup";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";
import IUserProfile from "../../models/IUserProfile";
import GroupsBadge from "../molecules/GroupsBadge";
import RolesBadge from "../molecules/RolesBadge";

export interface IUserDetailsTableProps {
  accessToken: string;
  user: IUserProfile;
  roles: IRole[];
  rolesLoaded: boolean;
  onHandleLoadRoles: (accessToken: string) => void;
  roleIds: number[];
  roleIdsLoaded: boolean;
  onHandleLoadEditUserRoleIds: (accessToken: string, userId: number) => void;
  groups: IGroup[];
  groupsLoaded: boolean;
  onHandleLoadGroups: (accessToken: string) => void;
  groupIds: number[];
  groupIdsLoaded: boolean;
  onHandleLoadEditUserGroupIds: (accessToken: string, userId: number) => void;
}

class UserDetailsTable extends React.Component<IUserDetailsTableProps> {
  public loaded = false;
  public constructor(props: IUserDetailsTableProps) {
    super(props);
  }
  public componentDidMount() {
    const {
      accessToken,
      user,
      rolesLoaded,
      onHandleLoadRoles,
      groupsLoaded,
      onHandleLoadGroups,
      roleIdsLoaded,
      onHandleLoadEditUserRoleIds,
      groupIdsLoaded,
      onHandleLoadEditUserGroupIds
    } = this.props;
    this.loaded = true;
    if (this.loaded) {
      if (!rolesLoaded) {
        onHandleLoadRoles(accessToken);
      }
      if (!groupsLoaded) {
        onHandleLoadGroups(accessToken);
      }
      if (!roleIdsLoaded) {
        onHandleLoadEditUserRoleIds(accessToken, user.id);
      }
      if (!groupIdsLoaded) {
        onHandleLoadEditUserGroupIds(accessToken, user.id);
      }
    }
  }
  public render() {
    const { user, roles, roleIds, groups, groupIds } = this.props;
    const { emailAddress, isEnabled } = user;
    const { allowDisableUsers } = settings;
    return (
      <Table striped={true}>
        <tbody>
          <tr>
            <th scope="row">Email Address</th>
            <td> {emailAddress}</td>
          </tr>
          <tr>
            <th scope="row">Roles</th>
            <td>
              <RolesBadge roles={roles} roleIds={roleIds} />
            </td>
          </tr>
          <tr>
            <th scope="row">Groups</th>
            <td>
              <GroupsBadge groups={groups} groupIds={groupIds} />
            </td>
          </tr>
          {allowDisableUsers && (
            <tr>
              <th scope="row">Enabled</th>
              <td>
                <Input type="checkbox" checked={isEnabled} readOnly={true} />
                {isEnabled}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  return {
    accessToken: state.auth0.accessToken,
    user: ownProps.user,
    // tslint:disable-next-line:object-literal-sort-keys
    roles: state.roles,
    rolesLoaded: state.loadingRoles,
    roleIds: state.editUserRoleIds,
    roleIdsLoaded: state.loadingEditUserRoles,
    groups: state.groups,
    groupsLoaded: state.loadingGroups,
    groupIds: state.editUserGroupIds,
    groupIdsLoaded: state.loadingEditUserGroups
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadRoles: (accessToken: string) => {
    dispatch(handleLoadRoles(accessToken));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleLoadGroups: (accessToken: string) => {
    dispatch(handleLoadGroups(accessToken));
  },
  onHandleLoadEditUserRoleIds: (accessToken: string, userId: number) => {
    dispatch(handleLoadEditUserRoleIds(accessToken, userId));
  },
  onHandleLoadEditUserGroupIds: (accessToken: string, userId: number) => {
    dispatch(handleLoadEditUserGroupIds(accessToken, userId));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailsTable);
