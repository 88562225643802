import * as React from "react";
import Help from "../../assets/icons/helpWhite.svg";
import Icon from "./Icon";

const HelpIcon = () => {
  return (
    <React.Fragment>
      <Icon icon={Help} text="Get Support" id="get-support" />
    </React.Fragment>
  );
};

export default HelpIcon;
