import * as Redux from "redux";
import getRoleReports from "../api/getRoleReports";
import removeRoleReport from "../api/removeRoleReport";
import saveRoleReport from "../api/saveRoleReport";
import { setEditRoleReportsLoaded } from "./loadingEditRoleReports";
export const ADD_EDIT_ROLE_REPORT_ID = "ADD_EDIT_ROLE_REPORT_ID";
export const REMOVE_EDIT_ROLE_REPORT_ID = "REMOVE_EDIT_ROLE_REPORT_ID";
export const LOAD_EDIT_ROLE_REPORT_IDS = "LOAD_EDIT_ROLE_REPORT_IDS";
export const CLEAR_EDIT_ROLE_REPORT_IDS = "CLEAR_EDIT_ROLE_REPORT_IDS";

export const addEditRoleReportId = (id: number) => {
  return {
    id,
    type: ADD_EDIT_ROLE_REPORT_ID
  };
};

export const removeEditRoleReportId = (id: number) => {
  return {
    id,
    type: REMOVE_EDIT_ROLE_REPORT_ID
  };
};

export const loadEditRoleReportIds = (ids: number[]) => {
  return {
    ids,
    type: LOAD_EDIT_ROLE_REPORT_IDS
  };
};

export const clearEditRoleReportIds = () => {
  return {
    type: CLEAR_EDIT_ROLE_REPORT_IDS
  };
};

export const handleLoadEditRoleReportIds = (
  accessToken: string,
  roleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const roleReports = await getRoleReports(accessToken, roleId);
    if (roleReports === undefined) {
      dispatch(clearEditRoleReportIds());
      dispatch(setEditRoleReportsLoaded(false));
    } else {
      if (roleReports.length > 0) {
        dispatch(loadEditRoleReportIds(roleReports.map(report => report.id)));
      }
      dispatch(setEditRoleReportsLoaded(true));
    }
  };
};

export const handleSaveEditRoleReportId = (
  accessToken: string,
  roleId: number,
  reportId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await saveRoleReport(accessToken, roleId, reportId);
    dispatch(addEditRoleReportId(reportId));
  };
};

export const handleDeleteEditRoleReportId = (
  accessToken: string,
  roleId: number,
  reportId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await removeRoleReport(accessToken, roleId, reportId);
    dispatch(removeEditRoleReportId(reportId));
  };
};
