import * as React from 'react';
import {Container as BootstrapContainer} from 'reactstrap';

export interface IContainerProps{
  children: React.ReactNode;
  fluid?:boolean;
  id?: string;
  style?: React.CSSProperties;
}


const Container:React.SFC<IContainerProps> = (props:IContainerProps) => {
  const {children, fluid, id, style} = props;
  return (
    <BootstrapContainer style={style} fluid={fluid} id={id}>
      {children}
    </BootstrapContainer>
  );
};

export default Container;