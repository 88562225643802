import * as React from "react";
import Text from "../atoms/Text";

const ErrorPage = () => {
  return (
    <React.Fragment>
      <Text>Sorry, this page does not exist</Text>
    </React.Fragment>
  );
};

export default ErrorPage;
