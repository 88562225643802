import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import { clearActiveKpiId, setActiveKpiId } from "../../actions/activeKpi";
import { handleLoadKpis } from "../../actions/kpis";
import IKpi from "../../models/IKpi";
import { IStore } from "../../models/IStore";
import DataLoading from "../organisms/DataLoading";

export interface IKpiSelectorProps {
  accessToken: string;
  kpis: IKpi[];
  kpisLoaded: boolean;
  roleId: number;
  predicateId: number;
  clientId: number;
  kpiId: number;
  onHandleLoadKpis: (accessToken: string) => void;
  onHandleSetActiveKpiId: (selectedKpiId: number) => void;
  onHandleClearActiveKpiId: () => void;
}

export interface IKpiSelectorState {
  selectedKpiId: number;
}

class KpiSelector extends React.Component<
  IKpiSelectorProps,
  IKpiSelectorState
> {
  public state = {
    selectedKpiId: 0
  };
  public constructor(props: IKpiSelectorProps) {
    super(props);
  }
  public selectedKpiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onHandleSetActiveKpiId } = this.props;
    e.preventDefault();
    const selectedKpiId =
      e.target.value === undefined ? 0 : Number(e.target.value as string);
    onHandleSetActiveKpiId(selectedKpiId);
    this.setState(() => ({ selectedKpiId }));
  };
  public componentDidMount() {
    const { kpisLoaded, onHandleLoadKpis, accessToken } = this.props;
    if (!kpisLoaded) {
      onHandleLoadKpis(accessToken);
    }
  }

  public componentDidUpdate(prevProps: IKpiSelectorProps) {
    const {
      accessToken,
      kpisLoaded,
      roleId,
      predicateId,
      clientId,
      kpiId,
      onHandleClearActiveKpiId,
      onHandleLoadKpis
    } = this.props;
    if (!kpisLoaded) {
      onHandleLoadKpis(accessToken);
    }
    if (
      prevProps.roleId !== roleId ||
      prevProps.predicateId !== predicateId ||
      prevProps.clientId !== clientId ||
      prevProps.kpiId !== kpiId
    ) {
      onHandleClearActiveKpiId();
      this.setState({ selectedKpiId: 0 });
    }
  }

  public componentWillUnmount() {
    const { onHandleClearActiveKpiId } = this.props;
    onHandleClearActiveKpiId();
  }
  public render() {
    const { kpis, kpisLoaded } = this.props;
    const { selectedKpiId } = this.state;
    return (
      <FormGroup>
        {!kpisLoaded && <DataLoading dataTitle="Kpis" />}
        {kpisLoaded && (
          <React.Fragment>
            <Label for="kpi">KPI</Label>
            <Input
              id="kpi"
              type="select"
              placeholder="KPI"
              value={selectedKpiId}
              onChange={this.selectedKpiChange}
            >
              <option value="0" disabled={true}>
                Select KPI
              </option>
              {kpis.map(kpi => (
                <option key={kpi.id} value={kpi.id}>
                  {kpi.displayName}
                </option>
              ))}
            </Input>
          </React.Fragment>
        )}
      </FormGroup>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    kpis: state.kpis,
    kpisLoaded: state.loadingKpis,
    roleId: state.activeRoleId,
    // tslint:disable-next-line:object-literal-sort-keys
    predicateId: state.activeKpiPredicateId,
    clientId: state.activeKpiClientId,
    kpiId: state.activeKpiFinancialYearId
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadKpis: (accessToken: string) => {
    dispatch(handleLoadKpis(accessToken));
  },
  onHandleSetActiveKpiId: (selectedKpiId: number) => {
    dispatch(setActiveKpiId(selectedKpiId));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleClearActiveKpiId: () => {
    dispatch(clearActiveKpiId());
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KpiSelector);
