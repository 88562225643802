import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  Table
} from "reactstrap";
import {
  handleLoadDashboards,
  handleSaveDashboard
} from "../../../actions/dashboards";
import AddTextField from "../../../components/molecules/AddTextField";
import { paths, settings } from "../../../constants";
import IPbiDashboard from "../../../models/IPbiDashboard";
import { IStore } from "../../../models/IStore";
import {
  validateDashboardDisplayName,
  validateDashboardName,
  validateGuid,
  validateNumber
} from "../../../utils";
export interface IAddDashboardPageProps extends RouteComponentProps {
  accessToken: string;
  existingDashboards: IPbiDashboard[];
  onHandleLoadDashboards: (accessToken: string) => void;
  onHandleSaveDashboard: (
    accessToken: string,
    name: string,
    displayName: string,
    groupId: string,
    reportId: string,
    dashboardViewerVersion: number,
    isEnabled: boolean
  ) => void;
}
export interface IAddDashboardPageState {
  displayName: string;
  name: string;
  groupId: string;
  reportId: string;
  dashboardViewerVersion: number;
  isEnabled: boolean;
  isReadyToSave: boolean;
}

export class AddDashboardPage extends React.Component<
  IAddDashboardPageProps,
  IAddDashboardPageState
> {
  public isLoaded = false;
  public state = {
    displayName: "",
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    groupId: "",
    reportId: "",
    dashboardViewerVersion: 0,
    isEnabled: true,
    isReadyToSave: false
  };

  constructor(props: IAddDashboardPageProps) {
    super(props);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public readyToSave = () => {
    const {
      displayName,
      name,
      groupId,
      reportId,
      dashboardViewerVersion
    } = this.state;
    if (
      displayName === undefined ||
      displayName === "" ||
      !validateDashboardDisplayName(displayName)
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (name === undefined || name === "" || !validateDashboardName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (groupId === undefined || groupId === "" || !validateGuid(groupId)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (reportId === undefined || reportId === "" || !validateGuid(reportId)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    if (dashboardViewerVersion === undefined || dashboardViewerVersion < 1) {
      this.setState(() => ({ isReadyToSave: false }));
    }
    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public save = (addMore: boolean = false) => {
    const { accessToken, onHandleSaveDashboard, history } = this.props;
    const {
      name,
      displayName,
      groupId,
      reportId,
      dashboardViewerVersion,
      isEnabled
    } = this.state;
    if (this.readyToSave()) {
      onHandleSaveDashboard(
        accessToken,
        name,
        displayName,
        groupId,
        reportId,
        dashboardViewerVersion,
        isEnabled
      );
      if (!addMore) {
        history.push(paths.home);
      } else {
        this.setState(() => ({
          displayName: "",
          name: "",
          // tslint:disable-next-line:object-literal-sort-keys
          groupId: "",
          reportId: "",
          dashboardViewerVersion: 0,
          isEnabled: true
        }));
      }
    }
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };

  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadDashboards,
      existingDashboards
    } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingDashboards.length === 0) {
        onHandleLoadDashboards(accessToken);
      }
    }
  }
  public render() {
    const { existingDashboards } = this.props;
    const {
      displayName,
      name,
      groupId,
      reportId,
      dashboardViewerVersion,
      isEnabled
    } = this.state;
    const { allowDisableDashboards } = settings;
    return (
      <Form>
        <Card>
          <CardHeader>Add New Dashboard Details</CardHeader>
          <CardBody>
            <React.Fragment>
              <AddTextField
                noDuplicates={true}
                isValid={validateDashboardDisplayName}
                title="Display Name"
                value={displayName}
                existingValues={existingDashboards.map(d => d.displayName)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (dn: string) => {
                  await this.setState(() => ({ displayName: dn }));
                  this.readyToSave();
                }}
              />

              <AddTextField
                noDuplicates={true}
                isValid={validateDashboardName}
                title="Name"
                value={name}
                existingValues={existingDashboards.map(d => d.name)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (n: string) => {
                  await this.setState(() => ({ name: n }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                noDuplicates={false}
                isValid={validateGuid}
                title="Group ID"
                value={groupId}
                existingValues={existingDashboards.map(d => d.groupId)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (gId: string) => {
                  await this.setState(() => ({ groupId: gId }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                noDuplicates={true}
                isValid={validateGuid}
                title="Report ID"
                value={reportId}
                existingValues={existingDashboards.map(d => d.reportId)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (rId: string) => {
                  await this.setState(() => ({ reportId: rId }));
                  this.readyToSave();
                }}
              />

              <AddTextField
                noDuplicates={false}
                isValid={validateNumber}
                title="Dashboard Viewer Version Number"
                value={dashboardViewerVersion.toString()}
                existingValues={existingDashboards.map(d => d.reportId)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (dvver: string) => {
                  await this.setState(() => ({ dashboardViewerVersion: parseInt(dvver) }));
                  this.readyToSave();
                }}
              />

              {allowDisableDashboards && (
                <Table>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <Input
                        type="checkbox"
                        defaultChecked={isEnabled}
                        onChange={this.isEnabledChange}
                      />
                      {isEnabled}
                    </td>
                  </tr>
                </Table>
              )}
            </React.Fragment>
          </CardBody>
          <CardFooter>
            <React.Fragment>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save()}
                style={{ margin: 4, padding: 4 }}
              >
                Save Changes
              </Button>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save(true)}
                style={{ margin: 4, padding: 4 }}
              >
                Save And Add Another
              </Button>
              <Button onClick={this.cancel} style={{ margin: 4, padding: 4 }}>
                Cancel
              </Button>
            </React.Fragment>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingDashboards: state.dashboards
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadDashboards: (accessToken: string) => {
    dispatch(handleLoadDashboards(accessToken));
  },
  onHandleSaveDashboard: (
    accessToken: string,
    name: string,
    displayName: string,
    groupId: string,
    reportId: string,
    dashboardViewerVersion: number,
    isEnabled: boolean
  ) => {
    dispatch(
      handleSaveDashboard(
        accessToken,
        name,
        displayName,
        groupId,
        reportId,
        dashboardViewerVersion,
        isEnabled
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDashboardPage);
