import * as Redux from "redux";
import getGroups from "../api/getGroups";
import { removeGroup as deleteGroup } from "../api/removeGroup";
import saveGroup from "../api/saveGroup";
import saveUpdatedGroup from "../api/saveUpdatedGroup";
import IUserGroup from "../models/IGroup";
import { setGroupsLoaded } from "./loadingGroups";
export const ADD_GROUP = "ADD_GROUP";
export const REMOVE_GROUP = "REMOVE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const LOAD_GROUPS = "LOAD_GROUPS";

export const addGroup = (group: IUserGroup) => {
  return {
    group,
    type: ADD_GROUP
  };
};

export const removeGroup = (id: number) => {
  return {
    id,
    type: REMOVE_GROUP
  };
};

export const updateGroup = (group: IUserGroup) => {
  return {
    group,
    type: UPDATE_GROUP
  };
};

export const loadGroups = (groups: IUserGroup[]) => {
  return {
    groups,
    type: LOAD_GROUPS
  };
};

export const handleSaveGroup = (
  accessToken: string,
  name: string,
  isEnabled: boolean
) => {
  return async (dispatch: Redux.Dispatch) => {
    const group = await saveGroup(accessToken, name, isEnabled);
    if (group !== null && group !== undefined) {
      dispatch(addGroup(group));
    }
  };
};

export const handleUpdateGroup = (accessToken: string, group: IUserGroup) => {
  return async (dispatch: Redux.Dispatch) => {
    const grp = await saveUpdatedGroup(accessToken, group);
    if (grp !== null && grp !== undefined) {
      dispatch(updateGroup(grp));
    }
  };
};

export const handleDeleteGroup = (accessToken: string, id: number) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deleteGroup(accessToken, id);
    if (result === 200) {
      dispatch(removeGroup(id));
    }
  };
};

export const handleLoadGroups = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const groups = await getGroups(accessToken);
    if (groups !== undefined) {
      if (groups.length > 0) {
        groups.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        dispatch(loadGroups(groups));
      }
      dispatch(setGroupsLoaded(true));
    }
  };
};
