import IPredicate from "../models/IPredicate";
import { fetchSecureData } from "../utils";

export const savePredicate = async (
  accessToken: string,
  displayName: string,
  name: string,
  isKpiGrouping: boolean,
  isEnabled: boolean
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/Predicate/Add?displayName=${displayName}&name=${name}&isKpiGrouping=${isKpiGrouping}&isEnabled=${isEnabled}`,
      accessToken
    );
    const predicate = ((await data.json()) as any).value as IPredicate;
    return predicate;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/Predicate/Add?displayName=${displayName}&name=${name}&isKpiGrouping=${isKpiGrouping}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as IPredicate;
};

export default savePredicate;
