import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, CardFooter, CardHeader, Form, Input, Table } from "reactstrap";
import { handleLoadKpis, handleSaveKpi } from "../../../actions/kpis";
import AddTextField from "../../../components/molecules/AddTextField";
import { paths, settings } from "../../../constants";
import IKpi from "../../../models/IKpi";
import { IStore } from "../../../models/IStore";
import { validateKpiDisplayName, validateKpiName } from "../../../utils";
export interface IAddKpiPageProps extends RouteComponentProps {
  accessToken: string;
  existingKpis: IKpi[];
  onHandleLoadKpis: (accessToken: string) => void;
  onHandleSaveKpi: (
    accessToken: string,
    displayName: string,
    name: string,
    isEnabled: boolean
  ) => void;
}
export interface IAddKpiPageState {
  displayName: string;
  name: string;
  isEnabled: boolean;
  isReadyToSave: boolean;
}

export class AddKpiPage extends React.Component<
  IAddKpiPageProps,
  IAddKpiPageState
> {
  public isLoaded = false;
  public state = {
    displayName: "",
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    isEnabled: true,
    isReadyToSave: false
  };

  constructor(props: IAddKpiPageProps) {
    super(props);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public readyToSave = () => {
    const { displayName, name } = this.state;

    if (
      displayName === undefined ||
      displayName === "" ||
      !validateKpiDisplayName(displayName) ||
      name === undefined ||
      name === "" ||
      !validateKpiName(name)
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }

    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public save = (addMore: boolean = false) => {
    const { accessToken, onHandleSaveKpi, history } = this.props;
    const { displayName, name, isEnabled } = this.state;
    if (this.readyToSave()) {
      onHandleSaveKpi(accessToken, displayName, name, isEnabled);
      if (!addMore) {
        history.push(paths.home);
      } else {
        this.setState(() => ({
          displayName: "",
          name: "",
          // tslint:disable-next-line:object-literal-sort-keys
          isEnabled: true
        }));
      }
    }
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };

  public async componentDidMount() {
    const { accessToken, onHandleLoadKpis, existingKpis } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingKpis.length === 0) {
        onHandleLoadKpis(accessToken);
      }
    }
  }
  public render() {
    const { existingKpis } = this.props;
    const { displayName, name, isEnabled } = this.state;
    const { allowDisableKpis } = settings;
    return (
      <Form>
        <Card>
          <CardHeader>Add New KPI</CardHeader>
          <CardBody>
            <React.Fragment>
              <AddTextField
                noDuplicates={true}
                isValid={validateKpiName}
                title="Name"
                value={name}
                existingValues={existingKpis.map(kpi => kpi.name)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (n: string) => {
                  await this.setState(() => ({ name: n }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                noDuplicates={true}
                isValid={validateKpiDisplayName}
                title="Display Name"
                value={displayName}
                existingValues={existingKpis.map(kpi => kpi.displayName)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (dn: string) => {
                  await this.setState(() => ({ displayName: dn }));
                  this.readyToSave();
                }}
              />

              {allowDisableKpis && (
                <Table>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <Input
                        type="checkbox"
                        defaultChecked={isEnabled}
                        onChange={this.isEnabledChange}
                      />
                      {isEnabled}
                    </td>
                  </tr>
                </Table>
              )}
            </React.Fragment>
          </CardBody>
          <CardFooter>
            <React.Fragment>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save()}
                style={{ margin: 4, padding: 4 }}
              >
                Save Changes
              </Button>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save(true)}
                style={{ margin: 4, padding: 4 }}
              >
                Save And Add Another
              </Button>
              <Button onClick={this.cancel} style={{ margin: 4, padding: 4 }}>
                Cancel
              </Button>
            </React.Fragment>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingKpis: state.kpis
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadKpis: (accessToken: string) => {
    dispatch(handleLoadKpis(accessToken));
  },
  onHandleSaveKpi: (
    accessToken: string,
    displayName: string,
    name: string,
    isEnabled: boolean
  ) => {
    dispatch(handleSaveKpi(accessToken, displayName, name, isEnabled));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddKpiPage);
