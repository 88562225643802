import { AnyAction } from "redux";
import {
  CLEAR_ACTIVE_PAGINATED_REPORT_ID,
  SET_ACTIVE_PAGINATED_REPORT_ID
} from "../actions/activePaginatedReport";

export const activePaginatedReport = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case SET_ACTIVE_PAGINATED_REPORT_ID:
      return action.id as number;
    case CLEAR_ACTIVE_PAGINATED_REPORT_ID:
      return 0;
    default:
      return state;
  }
};

export default activePaginatedReport;
