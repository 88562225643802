import * as Redux from "redux";
import getRoleUsers from "../api/getRoleUsers";
import removeRoleUser from "../api/removeRoleUser";
import saveRoleUser from "../api/saveRoleUser";
import { setEditRoleUsersLoaded } from "./loadingEditRoleUsers";
export const ADD_EDIT_ROLE_USER_ID = "ADD_EDIT_ROLE_USER_ID";
export const REMOVE_EDIT_ROLE_USER_ID = "REMOVE_EDIT_ROLE_USER_ID";
export const LOAD_EDIT_ROLE_USER_IDS = "LOAD_EDIT_ROLE_USER_IDS";
export const CLEAR_EDIT_ROLE_USER_IDS = "CLEAR_EDIT_ROLE_USER_IDS";

export const addEditRoleUserId = (id: number) => {
  return {
    id,
    type: ADD_EDIT_ROLE_USER_ID
  };
};

export const removeEditRoleUserId = (id: number) => {
  return {
    id,
    type: REMOVE_EDIT_ROLE_USER_ID
  };
};

export const loadEditRoleUserIds = (ids: number[]) => {
  return {
    ids,
    type: LOAD_EDIT_ROLE_USER_IDS
  };
};

export const clearEditRoleUserIds = () => {
  return {
    type: CLEAR_EDIT_ROLE_USER_IDS
  };
};

export const handleLoadEditRoleUserIds = (
  accessToken: string,
  roleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const roleUsers = await getRoleUsers(accessToken, roleId);
    if (roleUsers === undefined) {
      dispatch(clearEditRoleUserIds());
      dispatch(setEditRoleUsersLoaded(false));
    } else {
      if (roleUsers.length > 0) {
        dispatch(loadEditRoleUserIds(roleUsers.map(user => user.id)));
      }
      dispatch(setEditRoleUsersLoaded(true));
    }
  };
};

export const handleSaveEditRoleUserId = (
  accessToken: string,
  roleId: number,
  userId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await saveRoleUser(accessToken, roleId, userId);
    dispatch(addEditRoleUserId(userId));
  };
};

export const handleDeleteEditRoleUserId = (
  accessToken: string,
  roleId: number,
  userId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await removeRoleUser(accessToken, roleId, userId);
    dispatch(removeEditRoleUserId(userId));
  };
};
