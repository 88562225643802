import { AnyAction } from "redux";
import { SET_USER_PAGINATED_REPORTS_LOADED } from "../actions/loadingUserPaginatedReports";

export const loadingUserPaginatedReports = (state = false, action: AnyAction) => {
    switch (action.type) {
        case SET_USER_PAGINATED_REPORTS_LOADED:
            return action.userPaginatedReportsLoaded;
        default:
            return state;
    }
};

export default loadingUserPaginatedReports;
