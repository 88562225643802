import { Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import paths from "../../constants";
import { IStore } from "../../models/IStore";

export interface ISecureRouteProps extends RouteProps {
  isAuthenticated: boolean;
  component: React.ComponentType<RouteProps>|any;
}

class SecureRoute extends React.Component<ISecureRouteProps> {
  public constructor(props: ISecureRouteProps) {
    super(props);
  }
  public render() {
    const {isAuthenticated} = this.props;
    const Component = this.props.component;
    return <Route
      {...this.props}
      render={
        // tslint:disable-next-line:jsx-no-lambda
        props =>
          isAuthenticated ? (
            <Component {...this.props} />
          ) : (
            <Redirect
              to={{
                pathname: paths.root,
                state: { from: props.location as Location }
              }}
            />
          )
      }
    />;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    isAuthenticated: state.auth0.isAuthenticated()
  };
};

export default connect(mapStateToProps)(SecureRoute);
