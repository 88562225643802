import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import {
  clearActiveKpiClientId,
  setActiveKpiClientId
} from "../../actions/activeKpiClient";
import {
  handleClearActiveRoleClients,
  handleLoadActiveRoleClients
} from "../../actions/activeRoleClients";
import IClient from "../../models/IClient";
import { IStore } from "../../models/IStore";
import DataLoading from "../organisms/DataLoading";

export interface IKpiClientSelectorProps {
  accessToken: string;
  roleId: number;
  predicateId: number;
  clients: IClient[];
  clientsLoaded: boolean;
  onHandleLoadClients: (
    accessToken: string,
    roleId: number,
    predicateId: number
  ) => void;
  onHandleSetActiveClientId: (selectedClientId: number) => void;
  onHandleClearActiveClientId: () => void;
  onHandleClearLoadedClients: () => void;
}

export interface IKpiClientSelectorState {
  selectedClientId: number;
}

class KpiClientSelector extends React.Component<
  IKpiClientSelectorProps,
  IKpiClientSelectorState
> {
  public state = {
    selectedClientId: 0
  };
  public constructor(props: IKpiClientSelectorProps) {
    super(props);
  }
  public selectedClientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onHandleSetActiveClientId } = this.props;
    e.preventDefault();
    const selectedClientId =
      e.target.value === undefined ? 0 : Number(e.target.value as string);
    onHandleSetActiveClientId(selectedClientId);
    this.setState(() => ({ selectedClientId }));
  };
  public componentDidMount() {
    const {
      clientsLoaded,
      onHandleLoadClients,
      accessToken,
      roleId,
      predicateId
    } = this.props;
    if (!clientsLoaded) {
      onHandleLoadClients(accessToken, roleId, predicateId);
    }
  }

  public componentDidUpdate(prevProps: IKpiClientSelectorProps) {
    const {
      accessToken,
      roleId,
      predicateId,
      clientsLoaded,
      onHandleClearActiveClientId,
      onHandleClearLoadedClients,
      onHandleLoadClients
    } = this.props;
    if (!clientsLoaded) {
      onHandleLoadClients(accessToken, roleId, predicateId);
    }
    if (prevProps.roleId !== roleId || prevProps.predicateId !== predicateId) {
      onHandleClearLoadedClients();
      onHandleClearActiveClientId();
      this.setState({ selectedClientId: 0 });
    }
  }

  public componentWillUnmount() {
    const {
      onHandleClearActiveClientId,
      onHandleClearLoadedClients
    } = this.props;
    onHandleClearLoadedClients();
    onHandleClearActiveClientId();
  }
  public render() {
    const { clients, clientsLoaded } = this.props;
    const { selectedClientId } = this.state;
    return (
      <FormGroup>
        {!clientsLoaded && <DataLoading dataTitle="Clients" />}
        {clientsLoaded && (
          <React.Fragment>
            <Label for="kpiGrouping">Client</Label>
            <Input
              id="kpiGrouping"
              type="select"
              placeholder="Client"
              value={selectedClientId}
              onChange={this.selectedClientChange}
            >
              <option value="0" disabled={true}>
                Select Client
              </option>
              {clients.map(client => (
                <option key={client.id} value={client.id}>
                  {client.displayName}
                </option>
              ))}
            </Input>
          </React.Fragment>
        )}
      </FormGroup>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    roleId: state.activeRoleId,
    // tslint:disable-next-line:object-literal-sort-keys
    predicateId: state.activeKpiPredicateId,
    clients: state.activeRoleClients,
    clientsLoaded: state.loadingRoleClients
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadClients: (
    accessToken: string,
    roleId: number,
    predicateId: number
  ) => {
    dispatch(handleLoadActiveRoleClients(accessToken, roleId, predicateId));
  },
  onHandleSetActiveClientId: (selectedClientId: number) => {
    dispatch(setActiveKpiClientId(selectedClientId));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleClearActiveClientId: () => {
    dispatch(clearActiveKpiClientId());
  },
  onHandleClearLoadedClients: () => {
    dispatch(handleClearActiveRoleClients());
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KpiClientSelector);
