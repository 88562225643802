import getAccessToken from "./getAccessToken";
import getUser from "./getUser";

export const getInitialData = async (
  accessToken: string,
  emailAddress: string
) => {
  const user = await getUser(accessToken, emailAddress);
  const tokenData = await getAccessToken(accessToken);

  return {
    user,
    // tslint:disable-next-line:object-literal-sort-keys
    tokenData
  };
};
