import ISqlReport from "../models/ISqlReport";
import { fetchSecureData } from "../utils";

export const getUserReports = async (accessToken: string, userId: number) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/SqlReport/GetSqlReportsForUserId?userId=${userId}`,
      accessToken
    );
    const userReports = ((await data.json()) as any).value as ISqlReport[];
    if (userReports !== undefined) {
      return userReports;
    } else {
      return [] as ISqlReport[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/SqlReport/GetSqlReportsForUserId?userId=${userId}`,
      err
    );
  }
  return [] as ISqlReport[];
};

export default getUserReports;
