import * as React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

const PrivacyPolicyCard = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle>Privacy Policy</CardTitle>
        <CardText>
          To review our privacy policy please click{" "}
          <a
            href="https://www.travelctm.com/global-privacy-policy/"
            target="_new"
          >
            here
          </a>
          .
        </CardText>
      </CardBody>
    </Card>
  );
};

export default PrivacyPolicyCard;
