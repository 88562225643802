import IUserProfile from "../models/IUserProfile";
import { fetchSecureData } from "../utils";

export const getUserProfiles = async (accessToken: string) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/UserProfile/GetUserProfiles`,
      accessToken
    );
    const userProfiles = ((await data.json()) as any).value as IUserProfile[];
    if (userProfiles !== undefined) {
      return userProfiles;
    } else {
      return [] as IUserProfile[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/UserProfile/GetUserProfiles`, err);
  }
  return [] as IUserProfile[];
};

export default getUserProfiles;
