import { AnyAction } from "redux";
import { CLEAR_ACTIVE_KPI_PREDICATE_ID, SET_ACTIVE_KPI_PREDICATE_ID } from "../actions/activeKpiPredicate";

export const activeKpiPredicate = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case SET_ACTIVE_KPI_PREDICATE_ID:
      return action.id as number;
    case CLEAR_ACTIVE_KPI_PREDICATE_ID:
      return 0;
    default:
      return state;
  }
};

export default activeKpiPredicate;
