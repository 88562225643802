export const SET_ROLE_KPI_GROUPING_PREDICATES_LOADED =
  "SET_ROLE_KPI_GROUPING_PREDICATES_LOADED";

export const setRoleKpiGroupingPredicatesLoaded = (
  roleKpiGroupingPredicatesLoaded: boolean
) => {
  return {
    roleKpiGroupingPredicatesLoaded,
    type: SET_ROLE_KPI_GROUPING_PREDICATES_LOADED
  };
};
