export const SET_ACTIVE_USER_GROUP_ID = "SET_ACTIVE_USER_GROUP_ID";
export const CLEAR_ACTIVE_USER_GROUP_ID = "CLEAR_ACTIVE_USER_GROUP_ID";

export const setActiveUserGroupId = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_USER_GROUP_ID
  };
};

export const clearActiveUserGroupId = () => {
  return {
    type: CLEAR_ACTIVE_USER_GROUP_ID
  };
};
