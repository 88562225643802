import * as Redux from "redux";
import getCurrencyCodes from "../api/getCurrencyCodes";
import ICurrencyCode from "../models/ICurrencyCode";
import { setCurrencyCodesLoaded } from "./loadingCurrencyCodes";

export const ADD_CURRENCY_CODE = "ADD_CURRENCY_CODE";
export const REMOVE_CURRENCY_CODE = "REMOVE_CURRENCY_CODE";
export const UPDATE_CURRENCY_CODE = "UPDATE_CURRENCY_CODE";
export const LOAD_CURRENCY_CODES = "LOAD_CURRENCY_CODES";

export const addCurrencyCode = (currencyCode: ICurrencyCode) => {
  return {
    currencyCode,
    type: ADD_CURRENCY_CODE
  };
};

export const removeCurrencyCode = (id: number) => {
  return {
    id,
    type: REMOVE_CURRENCY_CODE
  };
};

export const updateCurrencyCode = (currencyCode: ICurrencyCode) => {
  return {
    currencyCode,
    type: UPDATE_CURRENCY_CODE
  };
};

export const loadCurrencyCodes = (currencyCodes: ICurrencyCode[]) => {
  return {
    currencyCodes,
    type: LOAD_CURRENCY_CODES
  };
};

export const handleLoadCurrencyCodes = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const currencyCodes = await getCurrencyCodes(accessToken);
    if (currencyCodes !== undefined) {
      if (currencyCodes.length > 0) {
        currencyCodes.sort((a, b) =>
          a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1
        );
        dispatch(loadCurrencyCodes(currencyCodes));
      }
      dispatch(setCurrencyCodesLoaded(true));
    }
  };
};
