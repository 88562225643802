import IPbiDashboard from "../models/IPbiDashboard";
import { fetchSecureData } from "../utils";

export const getRoleDashboards = async (
  accessToken: string,
  roleId: number
) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/PbiDashboard/GetPbiDashboardsForRoleId?roleId=${roleId}`,
      accessToken
    );
    const roleDashboards = ((await data.json()) as any)
      .value as IPbiDashboard[];
    if (roleDashboards !== undefined) {
      return roleDashboards;
    } else {
      return [] as IPbiDashboard[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/PbiDashboard/GetPbiDashboardsForRoleId?roleId=${roleId}`,
      err
    );
  }
  return [] as IPbiDashboard[];
};

export default getRoleDashboards;
