import { AnyAction } from "redux";
import { SET_ROLES_LOADED } from "../actions/loadingRoles";

export const loadingRoles = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_ROLES_LOADED:
      return action.rolesLoaded;
    default:
      return state;
  }
};

export default loadingRoles;
