import { AnyAction } from "redux";
import {
  ADD_EDIT_ROLE_PREDICATE,
  CLEAR_EDIT_ROLE_PREDICATES,
  LOAD_EDIT_ROLE_PREDICATES,
  REMOVE_EDIT_ROLE_PREDICATE,
  UPDATE_EDIT_ROLE_PREDICATE
} from "../actions/editRolePredicates";
import IRolePredicate from "../models/IRolePredicate";

export const editRolePredicates = (
  state = [] as IRolePredicate[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_EDIT_ROLE_PREDICATE:
      const newState: IRolePredicate[] = state.slice();
      newState.push(action.rolePredicate as IRolePredicate);
      return newState;
    case REMOVE_EDIT_ROLE_PREDICATE:
      return Object.assign(
        [],
        state.filter(
          (rp: IRolePredicate) =>
            rp.predicateId !== (action.predicateId as number)
        )
      ) as IRolePredicate[];
    case UPDATE_EDIT_ROLE_PREDICATE:
      return Object.assign(
        [],
        state.map((rp: IRolePredicate) => {
          if (
            rp.roleId === (action.rolePredicate as IRolePredicate).roleId &&
            rp.predicateId ===
              (action.rolePredicate as IRolePredicate).predicateId
          ) {
            return action.rolePredicate as IRolePredicate;
          }
          return rp;
        })
      ) as IRolePredicate[];
    case LOAD_EDIT_ROLE_PREDICATES:
      return action.rolePredicates as IRolePredicate[];
    case CLEAR_EDIT_ROLE_PREDICATES:
      return [] as IRolePredicate[];
    default:
      return state;
  }
};

export default editRolePredicates;
