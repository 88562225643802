import * as React from "react";
import { Col as BootstrapCol } from "reactstrap";

export interface IColProps {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  children: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
}

const Col: React.SFC<IColProps> = (props: IColProps) => {
  const { children, id, xs, sm, md, lg, style } = props;
  return (
    <BootstrapCol xs={xs} sm={sm} md={md} lg={lg} style={style} id={id}>
      {children}
    </BootstrapCol>
  );
};

export default Col;
