import { AnyAction } from "redux";
import { SET_FY_START_MONTHS_LOADED } from "../actions/loadingFyStartMonths";

export const loadingFyStartMonths = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_FY_START_MONTHS_LOADED:
      return action.fyStartMonthsLoaded;
    default:
      return state;
  }
};

export default loadingFyStartMonths;
