import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import { handleDeleteRole, handleUpdateRole } from "../../../actions/roles";
import AddCurrencyCode from "../../../components/molecules/AddCurrencyCode";
import AddFYStartMonth from "../../../components/molecules/AddFYStartMonth";
import AddTextField from "../../../components/molecules/AddTextField";
import RoleDetailsTable from "../../../components/molecules/RoleDetailsTable";
import paths, { settings } from "../../../constants";
import IRole from "../../../models/IRole";
import { IStore } from "../../../models/IStore";
import { validateRoleDisplayName, validateRoleName } from "../../../utils";

export interface IEditRoleDetailsProps extends RouteComponentProps {
  accessToken: string;
  role: IRole;
  existingRoles: IRole[];
  onHandleUpdateRole: (accessToken: string, role: IRole) => void;
  onHandleDeleteRole: (accessToken: string, id: number) => void;
}

export interface IEditRoleDetailsState {
  editModeOn: boolean;
  displayName: string;
  name: string;
  currencyCodeId: number;
  fyStartMonthId: number;
  isEnabled: boolean;
  showDeleteModal: boolean;
}

class EditRoleDetails extends React.Component<
  IEditRoleDetailsProps,
  IEditRoleDetailsState
> {
  public state = {
    editModeOn: false,
    // tslint:disable-next-line:object-literal-sort-keys
    displayName: this.props.role.displayName,
    name: this.props.role.name,
    currencyCodeId: this.props.role.currencyCodeId,
    fyStartMonthId: this.props.role.fyStartMonthId,
    isEnabled: true,
    showDeleteModal: false
  };
  constructor(props: IEditRoleDetailsProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.delete = this.delete.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }
  public toggleEdit() {
    this.setState({ editModeOn: !this.state.editModeOn });
  }
  public toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  public delete = (e: React.MouseEvent) => {
    const { history, accessToken, role, onHandleDeleteRole } = this.props;
    // do delete
    onHandleDeleteRole(accessToken, role.id);
    history.push(paths.home);
  };
  public readyToSave = () => {
    const { displayName, name } = this.state;
    if (
      displayName === undefined ||
      displayName === "" ||
      !validateRoleDisplayName(displayName)
    ) {
      return false;
    }
    if (name === undefined || name === "" || !validateRoleName(name)) {
      return false;
    }
    return true;
  };
  public save = (e: React.MouseEvent<HTMLElement>) => {
    const { accessToken, role, onHandleUpdateRole } = this.props;
    const {
      displayName,
      name,
      currencyCodeId,
      fyStartMonthId,
      isEnabled
    } = this.state;
    if (this.readyToSave()) {
      // do save
      onHandleUpdateRole(accessToken, {
        id: role.id,
        // tslint:disable-next-line:object-literal-sort-keys
        displayName,
        name,
        currencyCodeId,
        fyStartMonthId,
        isEnabled
      } as IRole);
    }
    this.toggleEdit();
  };
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public render() {
    const { allowDisableRoles } = settings;
    const { role, existingRoles } = this.props;
    const {
      editModeOn,
      displayName,
      name,
      currencyCodeId,
      fyStartMonthId,
      isEnabled
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>
            Delete Role: {displayName}
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete Role: {displayName}{" "}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Confirm Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Card>
          <CardBody>
            {!editModeOn && <RoleDetailsTable role={role} />}
            {editModeOn && (
              <React.Fragment>
                <AddTextField
                  noDuplicates={true}
                  isValid={validateRoleDisplayName}
                  title="Display Name"
                  value={displayName}
                  existingValues={existingRoles.map(r => r.displayName)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={(dn: string) =>
                    this.setState(() => ({ displayName: dn }))
                  }
                />
                <AddTextField
                  noDuplicates={true}
                  isValid={validateRoleName}
                  title="Name"
                  value={name}
                  existingValues={existingRoles.map(r => r.name)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={(n: string) => this.setState(() => ({ name: n }))}
                />
                <AddCurrencyCode
                  currencyCodeId={currencyCodeId}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={(cc: number) =>
                    this.setState(() => ({ currencyCodeId: cc }))
                  }
                />
                <AddFYStartMonth
                  fyStartMonthId={fyStartMonthId}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={(fysm: number) =>
                    this.setState(() => ({ fyStartMonthId: fysm }))
                  }
                />
                {allowDisableRoles && (
                  <Table>
                    <tr>
                      <th scope="row">Enabled</th>
                      <td>
                        <Input
                          type="checkbox"
                          defaultChecked={isEnabled}
                          onChange={this.isEnabledChange}
                        />
                        {isEnabled}
                      </td>
                    </tr>
                  </Table>
                )}
              </React.Fragment>
            )}
            <CardFooter>
              {!editModeOn && (
                <React.Fragment>
                  <Button
                    onClick={this.toggleEdit}
                    style={{ margin: 4, padding: 4 }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    onClick={this.toggleDeleteModal}
                    style={{
                      margin: 4,
                      padding: 4
                    }}
                  >
                    <strong>Delete Role</strong>
                  </Button>
                </React.Fragment>
              )}
              {editModeOn && (
                <React.Fragment>
                  {this.readyToSave() && (
                    <Button
                      onClick={this.save}
                      style={{ margin: 4, padding: 4 }}
                    >
                      Save Changes
                    </Button>
                  )}
                  <Button
                    onClick={this.toggleEdit}
                    style={{ margin: 4, padding: 4 }}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              )}
            </CardFooter>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingRoles: state.roles.filter(
      role => role.id !== state.editRoleDetails.id
    ),
    role: state.editRoleDetails
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleUpdateRole: (accessToken: string, role: IRole) => {
    dispatch(handleUpdateRole(accessToken, role));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleDeleteRole: (accessToken: string, id: number) => {
    dispatch(handleDeleteRole(accessToken, id));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRoleDetails);
