import * as Redux from "redux";
import getRolePredicates from "../api/getRolePredicates";
import { removeRolePredicate as deleteRolePredicate } from "../api/removeRolePredicate";
import saveRolePredicate from "../api/saveRolePredicate";
import saveUpdatedRolePredicate from "../api/saveUpdatedRolePredicate";
import IRolePredicate from "../models/IRolePredicate";

export const ADD_ROLE_PREDICATE = "ADD_ROLE_PREDICATE";
export const REMOVE_ROLE_PREDICATE = "REMOVE_ROLE_PREDICATE";
export const UPDATE_ROLE_PREDICATE = "UPDATE_ROLE_PREDICATE";
export const LOAD_ROLE_PREDICATES = "LOAD_ROLE_PREDICATEES";

export const addRolePredicate = (rolePredicate: IRolePredicate) => {
  return {
    rolePredicate,
    type: ADD_ROLE_PREDICATE
  };
};

export const removeRolePredicate = (roleId: number, predicateId: number) => {
  return {
    roleId,
    // tslint:disable-next-line:object-literal-sort-keys
    predicateId,
    type: REMOVE_ROLE_PREDICATE
  };
};

export const updateRolePredicate = (rolePredicate: IRolePredicate) => {
  return {
    rolePredicate,
    type: UPDATE_ROLE_PREDICATE
  };
};

export const loadRolePredicates = (rolePredicates: IRolePredicate[]) => {
  return {
    rolePredicates,
    type: LOAD_ROLE_PREDICATES
  };
};

export const handleLoadRolePredicates = (
  accessToken: string,
  roleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const rolePredicates = await getRolePredicates(accessToken, roleId);
    if (rolePredicates!==undefined){
      if (rolePredicates.length>0){
        dispatch(loadRolePredicates(rolePredicates));
      }
      // dispatch(setRolePredicatesLoaded(true));
    }
  };
};

export const handleSaveRolePredicate = (
  accessToken: string,
  roleId: number,
  predicateId: number,
  value: string
) => {
  return async (dispatch: Redux.Dispatch) => {
    const rolePredicate = await saveRolePredicate(
      accessToken,
      roleId,
      predicateId,
      value
    );
    if (rolePredicate !== null && rolePredicate !== undefined) {
      dispatch(addRolePredicate(rolePredicate));
    }
  };
};

export const handleUpdateRolePredicate = (
  accessToken: string,
  rolePredicate: IRolePredicate
) => {
  return async (dispatch: Redux.Dispatch) => {
    const rp = await saveUpdatedRolePredicate(accessToken, rolePredicate);
    if (rp !== null && rp !== undefined) {
      dispatch(updateRolePredicate(rp));
    }
  };
};

export const handleDeleteRolePredicate = (
  accessToken: string,
  roleId: number,
  predicateId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deleteRolePredicate(accessToken, roleId, predicateId);
    if (result === 200) {
      dispatch(removeRolePredicate(roleId, predicateId));
    }
  };
};
