import { AnyAction } from "redux";
import {
  ADD_ACTIVE_KPI_ENTRY,
  CLEAR_ACTIVE_KPI_ENTRIES,
  LOAD_ACTIVE_KPI_ENTRIES,
  REMOVE_ACTIVE_KPI_ENTRY
} from "../actions/activeKpiEntries";
import IKpiEntry from "../models/IKpiEntry";

export const activeKpiEntries = (
  state = [] as IKpiEntry[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_ACTIVE_KPI_ENTRY:
      const newState: IKpiEntry[] = state.slice();
      newState.push(action.kpiEntry as IKpiEntry);
      return newState;
    case REMOVE_ACTIVE_KPI_ENTRY:
      return Object.assign(
        [],
        state.filter(
          (kpiEntry: IKpiEntry) =>
            kpiEntry.kpiId !== (action.kpiEntry as IKpiEntry).kpiId &&
            kpiEntry.clientId === (action.kpiEntry as IKpiEntry).clientId &&
            kpiEntry.year === (action.kpiEntry as IKpiEntry).year &&
            kpiEntry.monthId === (action.kpiEntry as IKpiEntry).monthId &&
            kpiEntry.measuredValue ===
              (action.kpiEntry as IKpiEntry).measuredValue &&
            kpiEntry.thresholdValue ===
              (action.kpiEntry as IKpiEntry).thresholdValue
        )
      ) as IKpiEntry[];
    case LOAD_ACTIVE_KPI_ENTRIES:
      return action.kpiEntries as IKpiEntry[];
    case CLEAR_ACTIVE_KPI_ENTRIES:
      return [] as IKpiEntry[];
    default:
      return state;
  }
};

export default activeKpiEntries;
