import IRolePredicate from "../models/IRolePredicate";
import { fetchSecureData } from "../utils";

export const saveRolePredicate = async (
  accessToken: string,
  roleId: number,
  predicateId: number,
  value: string
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/RolePredicate/Add?roleId=${roleId}&predicateId=${predicateId}&value=${value}`,
      accessToken
    );
    const rolePredicate = ((await data.json()) as any).value as IRolePredicate;
    return rolePredicate;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/RolePredicate/Add?roleId=${roleId}&predicateId=${predicateId}&value=${value}`,
      err
    );
  }
  return {} as IRolePredicate;
};

export default saveRolePredicate;
