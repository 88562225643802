import { AnyAction } from "redux";
import {
  ADD_ROLE_PREDICATE,
  LOAD_ROLE_PREDICATES,
  REMOVE_ROLE_PREDICATE,
  UPDATE_ROLE_PREDICATE
} from "../actions/rolePredicates";
import IRolePredicate from "../models/IRolePredicate";

export const rolePredicates = (
  state = [] as IRolePredicate[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_ROLE_PREDICATE:
      const newState: IRolePredicate[] = state.slice();
      newState.push(action.rolePredicate as IRolePredicate);
      return newState;
    case REMOVE_ROLE_PREDICATE:
      return Object.assign(
        [],
        state.filter(
          (rp: IRolePredicate) => rp.roleId !== (action.roleId as number) && rp.predicateId!==(action.predicateId as number)
        )
      ) as IRolePredicate[];
    case UPDATE_ROLE_PREDICATE:
      return Object.assign(
        [],
        state.map((rp: IRolePredicate) => {
          if (rp.roleId === (action.rolePredicate as IRolePredicate).roleId && rp.predicateId===(action.rolePredicate as IRolePredicate).predicateId) {
            return action.rolePredicate as IRolePredicate;
          }
          return rp;
        })
      ) as IRolePredicate[];
    case LOAD_ROLE_PREDICATES:
      return action.rolePredicates as IRolePredicate[];
    default:
      return state;
  }
};

export default rolePredicates;
