import IPbiDashboard from "../models/IPbiDashboard";
import { fetchSecureData } from "../utils";

export const saveRoleDashboard = async (
  accessToken: string,
  roleId: number,
  dashboardId: number,
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/RoleDashboard/Add?roleId=${roleId}&dashboardId=${dashboardId}`,
      accessToken
    );
    const roleDashboard = ((await data.json()) as any).value as IPbiDashboard;
    return roleDashboard;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/RoleDashboard/Add?roleId=${roleId}&&dashboardId=${dashboardId}`
    );
  }
  return {} as IPbiDashboard;
};

export default saveRoleDashboard;
