import IRole from "../models/IRole";
import { fetchSecureData } from "../utils";

export const getRoles = async (accessToken: string) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/Role/GetRoles`,
      accessToken
    );
    const roles = ((await data.json()) as any).value as IRole[];
    if (roles !== undefined) {
      return roles;
    } else {
      return [] as IRole[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/Role/GetRoles`, err);
  }
  return [] as IRole[];
};

export default getRoles;
