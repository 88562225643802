import IFYStartMonth from "../models/IFYStartMonth";
import { fetchSecureData } from "../utils";

export const getFyStartMonths = async (accessToken: string) => {
  const data = await fetchSecureData(
    "GET",
    "/api/FYStartMonth/GetFinancialYearStartMonths",
    accessToken
  );
  const fyStartMonths = ((await data.json()) as any).value as IFYStartMonth[];
  if (fyStartMonths !== undefined) {
    return fyStartMonths;
  } else {
    return [] as IFYStartMonth[];
  }
};

export default getFyStartMonths;
