import * as Redux from "redux";
import IPbiDashboard from "../models/IPbiDashboard";

export const SET_ACTIVE_DASHBOARD_ID = "SET_ACTIVE_DASHBOARD_ID";
export const CLEAR_ACTIVE_DASHBOARD_ID = "CLEAR_ACTIVE_DASHBOARD_ID";

export const setActiveDashboard = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_DASHBOARD_ID
  };
};
export const clearActiveDashboard = () => {
  return {
    type: CLEAR_ACTIVE_DASHBOARD_ID
  };
};

export const handleLoadActiveDashboardData = (
  dashboardId: number,
  userDashboards: IPbiDashboard[]
) => {
  return async (dispatch: Redux.Dispatch) => {
    const dashboard = await userDashboards.find(
      dash => dash.id === dashboardId
    );
    if (dashboard === undefined) {
      // tslint:disable-next-line:no-console
      console.log(
        "This dashboard not found, or user does not have access to this dashboard"
      );
    } else {
      dispatch(setActiveDashboard(dashboard.id));
    }
  };
};
