import { AnyAction } from "redux";
import {
  ADD_CURRENCY_CODE,
  LOAD_CURRENCY_CODES,
  REMOVE_CURRENCY_CODE,
  UPDATE_CURRENCY_CODE
} from "../actions/currencyCodes";
import ICurrencyCode from "../models/ICurrencyCode";

export const currencyCodes = (
  state = [] as ICurrencyCode[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_CURRENCY_CODE:
      const newState: ICurrencyCode[] = state.slice();
      newState.push(action.currencyCode as ICurrencyCode);
      return newState;
    case REMOVE_CURRENCY_CODE:
      return Object.assign(
        {},
        state.filter((cc: ICurrencyCode) => cc.id !== (action.id as number))
      );
    case UPDATE_CURRENCY_CODE:
      return state.map((cc: ICurrencyCode) => {
        if (cc.id === (action.currencyCode as ICurrencyCode).id) {
          return action.currencyCode as ICurrencyCode;
        }
        return cc;
      });
    case LOAD_CURRENCY_CODES:
      return action.currencyCodes as ICurrencyCode[];
    default:
      return state;
  }
};

export default currencyCodes;
