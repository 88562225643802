import * as React from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { handleLoadFinancialYears } from "../../actions/financialYears";
import { handleLoadKpis } from "../../actions/kpis";
import IFinancialYear from "../../models/IFinancialYear";
import IKpi from "../../models/IKpi";
import { IStore } from "../../models/IStore";
import KpiEntriesEditTable from "./KpiEntriesEditTable";
import KpiEntriesTable from "./KpiEntriesTable";

export interface IKpiEntriesContainerProps {
  accessToken: string;
  financialYear: IFinancialYear;
  kpi: IKpi;
  financialYearsLoaded: boolean;
  kpisLoaded: boolean;
  onHandleLoadFinancialYears: (accessToken: string) => void;
  onHandleLoadKpis: (accessToken: string) => void;
}

export interface IKpiEntriesContainerState {
  editModeOn: boolean;
}

class KpiEntriesContainer extends React.Component<IKpiEntriesContainerProps> {
  public state = {
    editModeOn: false
  };
  constructor(props: IKpiEntriesContainerProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
  }
  public toggleEdit() {
    this.setState(() => ({ editModeOn: !this.state.editModeOn }));
  }
  public componentDidMount() {
    const {
      accessToken,
      financialYearsLoaded,
      onHandleLoadFinancialYears,
      kpisLoaded,
      onHandleLoadKpis
    } = this.props;
    if (!financialYearsLoaded) {
      onHandleLoadFinancialYears(accessToken);
    }
    if (!kpisLoaded) {
      onHandleLoadKpis(accessToken);
    }
  }
  public render() {
    const { financialYear, kpi } = this.props;
    const { editModeOn } = this.state;
    return (
      <Container>
        <Row>
          <Col>
            <h3>
              {kpi.displayName} Metrics for {financialYear.displayName}
            </h3>
          </Col>
        </Row>
        {!editModeOn && (
          <Row>
            <Col>
              <KpiEntriesTable />
              <Button
                onClick={this.toggleEdit}
                style={{ margin: 4, padding: 4 }}
              >
                Edit
              </Button>
            </Col>
          </Row>
        )}
        {editModeOn && (
          <Row>
            <Col>
              <KpiEntriesEditTable callback={this.toggleEdit}/>
              <Button
                onClick={this.toggleEdit}
                style={{ margin: 4, padding: 4 }}
              >
                Close
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    financialYear: state.financialYears.find(
      fy => fy.id === state.activeKpiFinancialYearId
    ) as IFinancialYear,
    financialYearsLoaded: state.loadingFinancialYears,
    kpi: state.kpis.find(kpi => kpi.id === state.activeKpiId) as IKpi,
    kpisLoaded: state.loadingKpis
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadFinancialYears: (accessToken: string) => {
    dispatch(handleLoadFinancialYears(accessToken));
  },
  onHandleLoadKpis: (accessToken: string) => {
    dispatch(handleLoadKpis(accessToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KpiEntriesContainer);
