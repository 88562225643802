import IRole from "../models/IRole";
import { fetchSecureData } from "../utils";

export const saveUpdatedRole = async (
  accessToken: string,
  role: IRole
) => {
  const payload = {
    id: role.id,
    name: role.name,
    // tslint:disable-next-line:object-literal-sort-keys
    displayName: role.displayName,
    currencyCodeId: role.currencyCodeId,
    fyStartMonthId: role.fyStartMonthId,
    isEnabled: role.isEnabled
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/Role/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as IRole;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/Role/Edit`, err);
  }
  return {} as IRole;
};

export default saveUpdatedRole;
