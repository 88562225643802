import Auth from "../auth/Auth";

export const INITIALISE_AUTH0 = "INITIALISE_AUTH0";
export const UPDATE_AUTH0 = "UPDATE_AUTH0";

export const initialiseAuth0 = (auth: Auth) => {
  return {
    payload: auth,
    type: INITIALISE_AUTH0
  };
};

export const updateAuth0 = (
  accessToken: string,
  expiresAt: number,
  idToken: string
) => {
  return {
    payload: { accessToken, expiresAt, idToken },
    type: UPDATE_AUTH0
  };
};
