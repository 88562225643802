import * as React from "react";

export interface ISpanProps {
  children: React.ReactNode;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

const Span: React.SFC<ISpanProps> = (props: ISpanProps) => {
  const { className, id, style, children } = props;
  return (
    <React.Fragment>
      <span className={className} id={id} style={style}>
        {children}
      </span>
    </React.Fragment>
  );
};

export default Span;
