import { AnyAction } from "redux";
import { INITIALISE_AUTH0, UPDATE_AUTH0 } from "../actions/auth0";
import Auth from "../auth/Auth";

export const auth0 = (state: Auth = new Auth(), action: AnyAction) => {
  switch (action.type) {
    case INITIALISE_AUTH0:
      return Object.assign({}, state, { auth0: action.payload as Auth });
    case UPDATE_AUTH0:
      const newState = Object.assign({}, state as Auth);
      newState.idToken = action.payload.idToken;
      newState.expiresAt = action.payload.expiresAt;
      newState.accessToken = action.payload.accessToken;
      return newState;
    default:
      return state;
  }
};

export default auth0;
