export const SET_ACTIVE_KPI_CLIENT_ID = "SET_ACTIVE_KPI_CLIENT_ID";
export const CLEAR_ACTIVE_KPI_CLIENT_ID = "CLEAR_ACTIVE_KPI_CLIENT_ID";

export const setActiveKpiClientId = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_KPI_CLIENT_ID
  };
};

export const clearActiveKpiClientId = () => {
  return {
    type: CLEAR_ACTIVE_KPI_CLIENT_ID
  };
};
