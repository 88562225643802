export const SET_ACTIVE_DASHBOARD_DEFAULT_PREDICATE_ID =
  "SET_ACTIVE_DASHBOARD_DEFAULT_PREDICATE_ID";
export const CLEAR_ACTIVE_DASHBOARD_DEFAULT_PREDICATE_ID =
  "CLEAR_ACTIVE_DASHBOARD_DEFAULT_PREDICATE_ID";

export const setActiveDashboardDefaultPredicateId = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_DASHBOARD_DEFAULT_PREDICATE_ID
  };
};

export const clearActiveDashboardDefaultPredicateId = () => {
  return {
    type: CLEAR_ACTIVE_DASHBOARD_DEFAULT_PREDICATE_ID
  };
};
