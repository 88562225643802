import { AnyAction } from "redux";
import { SET_ROLE_KPI_GROUPING_PREDICATES_LOADED } from "../actions/loadingRoleKpiGroupingPredicates";

export const loadingRoleKpiGroupingPredicates = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_ROLE_KPI_GROUPING_PREDICATES_LOADED:
      return action.roleKpiGroupingPredicatesLoaded;
    default:
      return state;
  }
};

export default loadingRoleKpiGroupingPredicates;
