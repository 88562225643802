import IFinancialYear from "../models/IFinancialYear";
import { fetchSecureData } from "../utils";

export const getFinancialYears = async (accessToken: string) => {
  try {
  const data = await fetchSecureData(
    "GET",
    `/api/FinancialYear/GetFinancialYears`,
    accessToken
  );
    const financialYears = ((await data.json()) as any)
      .value as IFinancialYear[];
    if (financialYears !== undefined) {
      return financialYears;
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log("Error occured: ", err);
    return [] as IFinancialYear[];
  }
  return [] as IFinancialYear[];
};

export default getFinancialYears;

// const defaultFinancialYears: IFinancialYear[] = [
//   {
//     id: 1,
//     // tslint:disable-next-line:object-literal-sort-keys
//     displayName: "FY2018",
//     endYear: 2018,
//     isEnabled: true
//   },
//   {
//     id: 1,
//     // tslint:disable-next-line:object-literal-sort-keys
//     displayName: "FY2019",
//     endYear: 2019,
//     isEnabled: true
//   },
//   {
//     id: 1,
//     // tslint:disable-next-line:object-literal-sort-keys
//     displayName: "FY2020",
//     endYear: 2020,
//     isEnabled: true
//   }
// ];
