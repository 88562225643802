import { AnyAction } from "redux";
import { SET_USERS_LOADED } from "../actions/loadingUsers";

export const loadingUsers = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_USERS_LOADED:
      return action.usersLoaded;
    default:
      return state;
  }
};

export default loadingUsers;
