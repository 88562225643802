import IUserProfile from "../models/IUserProfile";

export const SET_EDIT_USER_DETAILS = "SET_EDIT_USER_DETAILS";
export const CLEAR_EDIT_USER_DETAILS = "CLEAR_EDIT_USER_DETAILS";

export const setEditUserDetails = (user: IUserProfile) => {
  return {
    user,
    // tslint:disable-next-line:object-literal-sort-keys
    type: SET_EDIT_USER_DETAILS
  };
};

export const clearEditUserDetails = () => {
  return {
    type: CLEAR_EDIT_USER_DETAILS
  };
};
