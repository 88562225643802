import * as React from "react";
import { connect } from "react-redux";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap";
import {
  clearEditRoleUserIds,
  handleDeleteEditRoleUserId,
  handleLoadEditRoleUserIds,
  handleSaveEditRoleUserId
} from "../../../actions/editRoleUserIds";
import { handleLoadUserProfiles } from "../../../actions/profiles";
import UsersBadge from "../../../components/molecules/UsersBadge";
import DataLoading from "../../../components/organisms/DataLoading";
import { IStore } from "../../../models/IStore";
import IUserProfile from "../../../models/IUserProfile";

export interface IAssignRoleUsersState {
  editRoleUserIds: number[];
  filteredUsers: IUserProfile[];
}

export interface IAssignRoleUsersProps {
  accessToken: string;
  users: IUserProfile[];
  usersLoaded: boolean;
  editRoleUserIds: number[];
  editRoleUserIdsLoaded: boolean;
  roleId: number;
  onHandleLoadUsers: (accessToken: string) => void;
  onHandleLoadEditRoleUserIds: (accessToken: string, roleId: number) => void;
  onClearEditRoleUserIds: () => void;
  onHandleSaveEditRoleUserId: (
    accessToken: string,
    roleId: number,
    userId: number
  ) => void;
  onHandleDeleteEditRoleUserId: (
    accessToken: string,
    roleId: number,
    userId: number
  ) => void;
}

class AssignRoleUsers extends React.Component<
  IAssignRoleUsersProps,
  IAssignRoleUsersState
> {
  public state = {
    editRoleUserIds: [] as number[],
    filteredUsers: [] as IUserProfile[]
  };
  constructor(props: IAssignRoleUsersProps) {
    super(props);
    this.userCheckboxChange = this.userCheckboxChange.bind(this);
    this.txtFilterChange = this.txtFilterChange.bind(this);
  }
  public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { users } = this.props;
    const filteredUsers = users.filter(user =>
      user.emailAddress.toLowerCase().includes(e.target.value.toLowerCase())
    );
    await this.setState(() => ({ filteredUsers }));
  };
  public userCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      accessToken,
      roleId,
      onHandleSaveEditRoleUserId,
      onHandleDeleteEditRoleUserId
    } = this.props;
    e.preventDefault();
    if (e.target.checked) {
      await onHandleSaveEditRoleUserId(
        accessToken,
        roleId,
        Number(e.target.value)
      );
    } else {
      await onHandleDeleteEditRoleUserId(
        accessToken,
        roleId,
        Number(e.target.value)
      );
    }
  };
  public async componentDidMount() {
    const {
      accessToken,
      users,
      usersLoaded,
      editRoleUserIds,
      onHandleLoadUsers,
      onHandleLoadEditRoleUserIds,
      roleId
    } = this.props;
    if (!usersLoaded) {
      await onHandleLoadUsers(accessToken);
    }
    await onHandleLoadEditRoleUserIds(accessToken, roleId);
    this.setState({ editRoleUserIds });
    this.setState({ filteredUsers: users });
  }

  public async componentDidUpdate(prevProps: IAssignRoleUsersProps) {
    const { editRoleUserIds, users } = this.props;
    if (prevProps.users !== users) {
      await this.setState(() => ({ filteredUsers: users }));
    }
    if (prevProps.editRoleUserIds !== editRoleUserIds) {
      await this.setState(() => ({ editRoleUserIds }));
    }
  }

  public render() {
    const { filteredUsers, editRoleUserIds } = this.state;
    const { users, usersLoaded, editRoleUserIdsLoaded } = this.props;
    return (
      <React.Fragment>
        {!usersLoaded && <DataLoading dataTitle="Users " />}
        {!editRoleUserIdsLoaded && <DataLoading dataTitle="Role Users " />}
        {usersLoaded && editRoleUserIdsLoaded && (
          <Container>
            <Row>
              <Col>
                <h2>Assign Users</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                {usersLoaded && (
                  <UsersBadge users={users} userIds={editRoleUserIds} />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Label for="txtFilter">Search: </Label>
                <Input
                  type="text"
                  id="txtFilter"
                  onChange={this.txtFilterChange}
                  placeholder="Search for..."
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroup>
                  {filteredUsers.map(user => (
                    <ListGroupItem key={user.id}>
                      <FormGroup check={true}>
                        <Label check={true}>
                          <Input
                            type="checkbox"
                            value={user.id}
                            onChange={this.userCheckboxChange}
                            checked={
                              this.state.editRoleUserIds.find(
                                id => id === user.id
                              ) !== undefined
                            }
                          />
                          {user.emailAddress}
                        </Label>
                      </FormGroup>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  const { onSave } = ownProps;
  return {
    accessToken: state.auth0.accessToken,
    users: state.profiles,
    usersLoaded: state.loadingUsers,
    // tslint:disable-next-line:object-literal-sort-keys
    roleId: state.editRoleDetails.id,
    editRoleUserIds: state.editRoleUserIds,
    editRoleUserIdsLoaded: state.loadingEditRoleUsers,
    onSave
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadUsers: (accessToken: string) => {
    dispatch(handleLoadUserProfiles(accessToken));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleLoadEditRoleUserIds: (accessToken: string, roleId: number) => {
    dispatch(handleLoadEditRoleUserIds(accessToken, roleId));
  },
  onClearEditRoleUserIds: () => {
    dispatch(clearEditRoleUserIds());
  },
  onHandleSaveEditRoleUserId: (
    accessToken: string,
    roleId: number,
    userId: number
  ) => {
    dispatch(handleSaveEditRoleUserId(accessToken, roleId, userId));
  },
  onHandleDeleteEditRoleUserId: (
    accessToken: string,
    roleId: number,
    userId: number
  ) => {
    dispatch(handleDeleteEditRoleUserId(accessToken, roleId, userId));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignRoleUsers);
