import * as Redux from "redux";
import getKpiEntriesForPredicateClientKpiYear from "../api/getKpiEntriesForPredicateClientKpiYear";
import saveKpiEntry from "../api/saveKpiEntry";
import saveUpdatedKpiEntry from "../api/saveUpdatedKpiEntry";
import IKpiEntry from "../models/IKpiEntry";
import { setKpiEntriesLoaded } from "./loadingKpiEntries";

export const ADD_ACTIVE_KPI_ENTRY = "ADD_ACTIVE_KPI_ENTRY";
export const REMOVE_ACTIVE_KPI_ENTRY = "REMOVE_ACTIVE_KPI_ENTRY";
export const LOAD_ACTIVE_KPI_ENTRIES = "LOAD_ACTIVE_KPI_ENTRIES";
export const CLEAR_ACTIVE_KPI_ENTRIES = "CLEAR_ACTIVE_KPI_ENTRIES";

export const addActiveKpiEntry = (kpiEntry: IKpiEntry) => {
  return {
    kpiEntry,
    // tslint:disable-next-line:object-literal-sort-keys
    type: ADD_ACTIVE_KPI_ENTRY
  };
};

export const removeActiveKpiEntry = (kpiEntry: IKpiEntry) => {
  return {
    kpiEntry,
    type: REMOVE_ACTIVE_KPI_ENTRY
  };
};

const loadActiveKpiEntries = (kpiEntries: IKpiEntry[]) => {
  return {
    kpiEntries,
    // tslint:disable-next-line:object-literal-sort-keys
    type: LOAD_ACTIVE_KPI_ENTRIES
  };
};

const clearActiveKpiEntries = () => {
  return {
    type: CLEAR_ACTIVE_KPI_ENTRIES
  };
};

export const handleLoadActiveKpiEntries = (
  accessToken: string,
  predicateId: number,
  clientId: number,
  fyStartMonthId: number,
  endYear: number,
  kpiId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const kpiEntries = await getKpiEntriesForPredicateClientKpiYear(
      accessToken,
      predicateId,
      clientId,
      fyStartMonthId,
      endYear,
      kpiId
    );
    if (kpiEntries !== undefined) {
      if (kpiEntries.length > 0) {
        kpiEntries.sort((a, b) => (a.monthId > b.monthId ? 1 : -1));
        dispatch(loadActiveKpiEntries(kpiEntries));
      }
      dispatch(setKpiEntriesLoaded(true));
    }
  };
};

export const handleClearActiveKpiEntries = () => {
  return async (dispatch: Redux.Dispatch) => {
    dispatch(clearActiveKpiEntries());
    dispatch(setKpiEntriesLoaded(false));
  };
};

export const handleSaveKpiEntry = (
  accessToken: string,
  kpiEntry: IKpiEntry
) => {
  return async (dispatch: Redux.Dispatch) => {
    const newKpiEntry = await saveKpiEntry(
      accessToken,
      kpiEntry.predicateId,
      kpiEntry.clientId,
      kpiEntry.year,
      kpiEntry.monthId,
      kpiEntry.kpiId,
      kpiEntry.measuredValue,
      kpiEntry.thresholdValue,
      true
    );
    if (newKpiEntry !== undefined) {
      dispatch(addActiveKpiEntry(kpiEntry));
    }
  };
};

export const handleUpdateKpiEntry = (
  accessToken: string,
  kpiEntry: IKpiEntry,
  updatedKpiEntry: IKpiEntry
) => {
  return async (dispatch: Redux.Dispatch) => {
    dispatch(removeActiveKpiEntry(kpiEntry));
    dispatch(addActiveKpiEntry(updatedKpiEntry));
    const validUpdatedKpiEntry = await saveUpdatedKpiEntry(
      accessToken,
      updatedKpiEntry
    );
    if (validUpdatedKpiEntry === undefined) {
      // rollback the change if not successful update to back end
      dispatch(removeActiveKpiEntry(updatedKpiEntry));
      dispatch(addActiveKpiEntry(kpiEntry));
    }
  };
};
