import * as Redux from "redux";
import getRoleKpiGroupingPredicates from "../api/getRoleKpiGroupingPredicates";
import IPredicate from "../models/IPredicate";
import { setRoleKpiGroupingPredicatesLoaded } from "./loadingRoleKpiGroupingPredicates";

export const ADD_ACTIVE_ROLE_KPI_GROUPING_PREDICATE =
  "ADD_ACTIVE_ROLE_KPI_GROUPING_PREDICATE";
export const REMOVE_ACTIVE_ROLE_KPI_GROUPING_PREDICATE =
  "REMOVE_ACTIVE_ROLE_KPI_GROUPING_PREDICATE";
export const LOAD_ACTIVE_ROLE_KPI_GROUPING_PREDICATES =
  "LOAD_ACTIVE_ROLE_KPI_GROUPING_PREDICATES";
export const CLEAR_ACTIVE_ROLE_KPI_GROUPING_PREDICATES =
  "CLEAR_ACTIVE_ROLE_KPI_GROUPING_PREDICATES";

export const addActiveRoleKpiGroupingPredicate = (predicate: IPredicate) => {
  return {
    predicate,
    // tslint:disable-next-line:object-literal-sort-keys
    type: ADD_ACTIVE_ROLE_KPI_GROUPING_PREDICATE
  };
};

export const removeActiveRoleKpiGroupingPredicate = (predicate: IPredicate) => {
  return {
    predicate,
    type: REMOVE_ACTIVE_ROLE_KPI_GROUPING_PREDICATE
  };
};

const loadActiveRoleKpiGroupingPredicates = (predicates: IPredicate[]) => {
  return {
    predicates,
    // tslint:disable-next-line:object-literal-sort-keys
    type: LOAD_ACTIVE_ROLE_KPI_GROUPING_PREDICATES
  };
};

const clearActiveRoleKpiGroupingPredicates = () => {
  return {
    type: CLEAR_ACTIVE_ROLE_KPI_GROUPING_PREDICATES
  };
};

export const handleLoadActiveRoleKpiGroupingPredicates = (
  accessToken: string,
  roleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const predicates = await getRoleKpiGroupingPredicates(accessToken, roleId);
    if (predicates !== undefined) {
      if (predicates.length > 0) {
        predicates.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        dispatch(loadActiveRoleKpiGroupingPredicates(predicates));
      }
      dispatch(setRoleKpiGroupingPredicatesLoaded(true));
    }
  };
};

export const handleClearActiveRoleKpiGroupingPredicates = () => {
  return async (dispatch: Redux.Dispatch) => {
    dispatch(clearActiveRoleKpiGroupingPredicates());
    dispatch(setRoleKpiGroupingPredicatesLoaded(false));
  };
};
