import IKpiEntry from "../models/IKpiEntry";
import { fetchSecureData } from "../utils";

export const getKpiEntriesForPredicateClientKpiYear = async (
  accessToken: string,
  predicateId: number,
  clientId: number,
  fyStartMonthId: number,
  endYear: number,
  kpiId: number
) => {
  // // tslint:disable-next-line:no-console
  // console.log(
  //   "predicateId:",
  //   predicateId,
  //   "clientId:",
  //   clientId,
  //   "fyStartMonthId:",
  //   fyStartMonthId,
  //   "endYear",
  //   endYear,
  //   "kpiId",
  //   kpiId
  // );
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/KpiEntry/GetKpiEntriesForPredicateAndClientForYearAndKpi?predicateId=${predicateId}&clientId=${clientId}&fyStartMonthId=${fyStartMonthId}&endYear=${endYear}&kpiId=${kpiId}`,
      accessToken
    );
    const kpiEntries = ((await data.json()) as any).value as IKpiEntry[];
    // // tslint:disable-next-line:no-console
    // console.log("kpiEntries:", kpiEntries);
    if (kpiEntries !== undefined) {
      return kpiEntries;
    } else {
      return [] as IKpiEntry[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/KpiEntry/GetKpiEntriesForPredicateAndClientForYearAndKpi?predicateId=${predicateId}&clientId=${clientId}&fyStartMonthId=${fyStartMonthId}&endYear=${endYear}&kpiId=${kpiId}`,
      err
    );
  }
  return [] as IKpiEntry[];
};

export default getKpiEntriesForPredicateClientKpiYear;
