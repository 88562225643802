import IUserProfile from "../models/IUserProfile";
import { fetchSecureData } from "../utils";

export const getRoleUsers = async (accessToken: string, roleId: number) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/UserProfile/GetUserProfilesForRoleId?roleId=${roleId}`,
      accessToken
    );
    const roleUsers = ((await data.json()) as any).value as IUserProfile[];
    if (roleUsers !== undefined) {
      return roleUsers;
    } else {
      return [] as IUserProfile[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/UserProfile/GetUserProfilesForRoleId?roleId=${roleId}`,
      err
    );
  }
  return [] as IUserProfile[];
};

export default getRoleUsers;
