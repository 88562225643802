import ISqlReport from "../models/ISqlReport";
import { fetchSecureData } from "../utils";

export const saveRoleReport = async (
  accessToken: string,
  roleId: number,
  reportId: number,
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/RoleReport/Add?roleId=${roleId}&reportId=${reportId}`,
      accessToken
    );
    const roleReport = ((await data.json()) as any).value as ISqlReport;
    return roleReport;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/RoleReport/Add?roleId=${roleId}&reportId=${reportId}`
    );
  }
  return {} as ISqlReport;
};

export default saveRoleReport;
