import * as React from "react";
import Span from "./Span";

export interface IWhiteTextProps {
  children: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
  smallText?: boolean;
}

const Text: React.SFC<IWhiteTextProps> = (props: IWhiteTextProps) => {
  const { children, id, smallText, style } = props;
  const fontSize = smallText ? "0.8em" : "1em";

  return (
    <Span
      id={id}
      style={
        style === undefined ? { padding: 4, color: "#FFF", fontSize } : style
      }
    >
      {children}
    </Span>
  );
};

export default Text;
