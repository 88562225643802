import { AnyAction } from "redux";
import {
  CLEAR_PBI_ACCESS_TOKEN,
  SET_PBI_ACCESS_TOKEN
} from "../actions/tokenData";
import { ITokenData } from "../models/ITokenData";

export const tokenData = (state = {} as ITokenData, action: AnyAction) => {
  switch (action.type) {
    case SET_PBI_ACCESS_TOKEN:
      return Object.assign({}, state, action.tokenData as ITokenData);
    case CLEAR_PBI_ACCESS_TOKEN:
      return Object.assign({}, {} as ITokenData);
    default:
      return state;
  }
};

export default tokenData;
