import IClient from "models/IClient";
import { AnyAction } from "redux";
import {
  CLEAR_ACTIVE_DASHBOARD_DEFAULT_CLIENT,
  SET_ACTIVE_DASHBOARD_DEFAULT_CLIENT
} from "../actions/activeDashboardDefaultClient";

export const activeDashboardDefaultClient = (state = {} as IClient, action: AnyAction) => {
  switch (action.type) {
    case SET_ACTIVE_DASHBOARD_DEFAULT_CLIENT:
      return action.client as IClient;
    case CLEAR_ACTIVE_DASHBOARD_DEFAULT_CLIENT:
      return {} as IClient;
    default:
      return state;
  }
};

export default activeDashboardDefaultClient;
