import * as Redux from "redux";
import getUserProfiles from "../api/getUserProfiles";
import { removeUserProfile as deleteUserProfile } from "../api/removeUserProfile";
import saveUpdatedUserProfile from "../api/saveUpdatedUserProfile";
import saveUserProfile from "../api/saveUserProfile";
import IUserProfile from "../models/IUserProfile";
import { setEditUserDetails } from "./editUserDetails";
import { setUsersLoaded } from "./loadingUsers";
export const ADD_USER_PROFILE = "ADD_USER_PROFILE";
export const REMOVE_USER_PROFILE = "REMOVE_USER_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const LOAD_USER_PROFILES = "LOAD_USER_PROFILES";

export const addUserProfile = (userProfile: IUserProfile) => {
  return {
    type: ADD_USER_PROFILE,
    userProfile
  };
};

export const removeUserProfile = (id: number) => {
  return {
    id,
    type: REMOVE_USER_PROFILE
  };
};

export const updateUserProfile = (userProfile: IUserProfile) => {
  return {
    type: UPDATE_USER_PROFILE,
    userProfile
  };
};

export const loadUserProfiles = (userProfiles: IUserProfile[]) => {
  return {
    type: LOAD_USER_PROFILES,
    userProfiles
  };
};

export const handleSaveUserProfile = (
  accessToken: string,
  emailAddress: string,
  isEnabled: boolean
) => {
  return async (dispatch: Redux.Dispatch) => {
    const userProfile = await saveUserProfile(
      accessToken,
      emailAddress,
      isEnabled
    );
    if (userProfile !== null && userProfile !== undefined) {
      dispatch(addUserProfile(userProfile));
      dispatch(setEditUserDetails(userProfile));
    }
  };
};

export const handleUpdateUserProfile = (
  accessToken: string,
  userProfile: IUserProfile
) => {
  return async (dispatch: Redux.Dispatch) => {
    const usr = await saveUpdatedUserProfile(accessToken, userProfile);
    if (usr !== null && usr !== undefined) {
      dispatch(updateUserProfile(usr));
      dispatch(setEditUserDetails(usr));
    }
  };
};

export const handleDeleteUserProfile = (accessToken: string, id: number) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deleteUserProfile(accessToken, id);
    if (result === 200) {
      dispatch(removeUserProfile(id));
    }
  };
};

export const handleLoadUserProfiles = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const userProfiles = await getUserProfiles(accessToken);
    if (userProfiles !== undefined) {
      if (userProfiles.length > 0) {
        userProfiles.sort((a, b) =>
          a.emailAddress.toLowerCase() > b.emailAddress.toLowerCase() ? 1 : -1
        );
        dispatch(loadUserProfiles(userProfiles));
      }
      dispatch(setUsersLoaded(true));
    }
  };
};
