import * as React from "react";
import { Row as BootstrapRow } from "reactstrap";

export interface IRowProps {
  style?: React.CSSProperties;
  id?: string;
  children: React.ReactNode;
}

const Row: React.SFC<IRowProps> = (props: IRowProps) => {
  const { children, id, style } = props;
  return <BootstrapRow style={style} id={id}>{children} </BootstrapRow>;
};

export default Row;
