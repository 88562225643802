import ISqlReport from "../models/ISqlReport";
import { fetchSecureData } from "../utils";

export const saveUpdatedReport = async (
  accessToken: string,
  report: ISqlReport
) => {
  const payload = {
    id: report.id,
    name: report.name,
    // tslint:disable-next-line:object-literal-sort-keys
    displayName: report.displayName,
    defaultZoomMode: report.defaultZoomMode,
    defaultZoomPercent: report.defaultZoomPercent,
    parameters: report.parameters,
    path: report.path,
    sqlReportingServerUrl: report.sqlReportingServerUrl,
    sqlReportingViewerUrl: report.sqlReportingViewerUrl,
    isEnabled: report.isEnabled
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/SqlReport/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as ISqlReport;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/SqlReport/Edit`, err);
  }
  return {} as ISqlReport;
};

export default saveUpdatedReport;
