import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import IFYStartMonth from "../../models/IFYStartMonth";
import { IStore } from "../../models/IStore";

export interface IAddFYStartMonthProps {
  fyStartMonthId: number;
  fyStartMonths: IFYStartMonth[];
  callback: (currencyCodeId: number) => void;
}
export interface IAddFYStartMonthState {
  fyStartMonthId: number;
}
class AddFYStartMonth extends React.Component<
  IAddFYStartMonthProps,
  IAddFYStartMonthState
> {
  public state = {
    fyStartMonthId: 1
  };
  constructor(props: IAddFYStartMonthProps) {
    super(props);
    this.fyStartMonthIdChange = this.fyStartMonthIdChange.bind(this);
  }
  public fyStartMonthIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { callback } = this.props;
    const fyStartMonthId =
      e.target.value === undefined ? 1 : Number(e.target.value as string);
    this.setState(() => ({ fyStartMonthId }));
    callback(fyStartMonthId);
  };
  public async componentDidUpdate(prevProps: IAddFYStartMonthProps) {
    const { fyStartMonthId } = this.props;
    if (
      fyStartMonthId !== prevProps.fyStartMonthId &&
      fyStartMonthId !== undefined &&
      fyStartMonthId !== 0
    ) {
      await this.setState(() => ({ fyStartMonthId }));
    }
  }
  public async componentDidMount() {
    const { fyStartMonthId } = this.props;
    if (fyStartMonthId !== undefined && fyStartMonthId !== 0) {
      await this.setState(() => ({ fyStartMonthId }));
    }
  }
  public render() {
    const { fyStartMonths } = this.props;
    const { fyStartMonthId } = this.state;
    return (
      <FormGroup>
        <Label for="fystartMonthId">Financial Year Start Month</Label>
        <Input
          id="fyStartMonthId"
          type="select"
          placeholder="Financial Year Start Month"
          value={fyStartMonthId.toString()}
          onChange={this.fyStartMonthIdChange}
        >
          {fyStartMonths.map(fys => (
            <option key={fys.id} value={fys.id}>
              {fys.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  return {
    callback: ownProps.callback,
    fyStartMonthId: ownProps.fyStartMonthId,
    fyStartMonths: state.fyStartMonths
  };
};

export default connect(mapStateToProps)(AddFYStartMonth);
