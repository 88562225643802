import * as React from "react";
import Dashboard from "../../assets/icons/dashboardWhite.svg";
import Icon from "./Icon";

const DashboardIcon = () => {
  return (
    <React.Fragment>
      <Icon icon={Dashboard} text="Dashboard" id="dashboard" />
    </React.Fragment>
  );
};

export default DashboardIcon;
