import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Form, Jumbotron } from "reactstrap";
import { IStore } from "../../models/IStore";
import KpiClientSelector from "../molecules/KpiClientSelector";
import KpiEntriesContainer from "../molecules/KpiEntriesContainer";
import KpiFYSelector from "../molecules/KpiFYSelector";
import KpiGroupingSelector from "../molecules/KpiGroupingSelector";
import KpiSelector from "../molecules/KpiSelector";
export interface IKpiTargetsPageProps extends RouteComponentProps {
  accessToken: string;
  predicateSelected: boolean;
  clientSelected: boolean;
  fySelected: boolean;
  kpiSelected: boolean;
}
export interface IKpiTargetsPageState {
  isLoaded: boolean;
}

export class KpiTargetsPage extends React.Component<IKpiTargetsPageProps, IKpiTargetsPageState> {
  public isLoaded = false;
  public state = {
    isLoaded: false
  };

  public constructor(props: IKpiTargetsPageProps) {
    super(props);
  }

  public async componentDidUpdate() {
    // const { accessToken } = this.props;
  }

  public async componentDidMount() {
    // const { accessToken } = this.props;
  }
  public render() {
    const {
      predicateSelected,
      clientSelected,
      fySelected,
      kpiSelected
    } = this.props;
    return (
      <Jumbotron>
        <Form>
          <KpiGroupingSelector />
          {predicateSelected && (
            <React.Fragment>
              <KpiClientSelector />
              {clientSelected && (
                <React.Fragment>
                  <KpiFYSelector />
                  {fySelected && (
                    <React.Fragment>
                      <KpiSelector />
                      {kpiSelected && (
                        <React.Fragment>
                          <KpiEntriesContainer />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Form>
      </Jumbotron>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    predicateSelected: state.activeKpiPredicateId !== 0,
    // tslint:disable-next-line:object-literal-sort-keys
    clientSelected: state.activeKpiClientId !== 0,
    fySelected: state.activeKpiFinancialYearId !== 0,
    kpiSelected: state.activeKpiId !== 0
  };
};

export default connect(mapStateToProps)(KpiTargetsPage);
