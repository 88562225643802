export const SET_EDIT_ROLE_PREDICATES_LOADED =
  "SET_EDIT_ROLE_PREDICATES_LOADED";

export const setEditRolePredicatesLoaded = (
  editRolePredicatesLoaded: boolean
) => {
  return {
    editRolePredicatesLoaded,
    type: SET_EDIT_ROLE_PREDICATES_LOADED
  };
};
