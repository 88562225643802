import { AnyAction } from "redux";
import {
  ADD_USER_PROFILE,
  LOAD_USER_PROFILES,
  REMOVE_USER_PROFILE,
  UPDATE_USER_PROFILE
} from "../actions/profiles";
import IUserProfile from "../models/IUserProfile";

export const profiles = (state = [] as IUserProfile[], action: AnyAction) => {
  switch (action.type) {
    case ADD_USER_PROFILE:
      const newState: IUserProfile[] = state.slice();
      newState.push(action.userProfile as IUserProfile);
      return newState;
    case REMOVE_USER_PROFILE:
      return Object.assign(
        [],
        state.filter((usr: IUserProfile) => usr.id !== (action.id as number))
      ) as IUserProfile[];
    case UPDATE_USER_PROFILE:
      return Object.assign(
        [],
        state.map((usr: IUserProfile) => {
          if (usr.id === (action.userProfile as IUserProfile).id) {
            return action.userProfile as IUserProfile;
          }
          return usr;
        })
      ) as IUserProfile[];
    case LOAD_USER_PROFILES:
      return action.userProfiles as IUserProfile[];
    default:
      return state;
  }
};

export default profiles;
