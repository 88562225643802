import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import {
  handleDeleteKpi,
  handleLoadKpis,
  handleUpdateKpi
} from "../../../actions/kpis";
import AddTextField from "../../../components/molecules/AddTextField";
import KpiDetailsTable from "../../../components/molecules/KpiDetailsTable";
import { paths, settings } from "../../../constants";
import IKpi from "../../../models/IKpi";
import { IStore } from "../../../models/IStore";
import { validateKpiDisplayName, validateKpiName } from "../../../utils";
export interface IEditKpiPageProps extends RouteComponentProps {
  accessToken: string;
  kpi: IKpi;
  existingKpis: IKpi[];
  kpisLoaded: boolean;
  onHandleLoadKpis: (accessToken: string) => void;
  onHandleUpdateKpi: (accessToken: string, kpi: IKpi) => void;
  onHandleDeleteKpi: (accessToken: string, id: number) => void;
}
export interface IEditKpiPageState {
  editModeOn: boolean;
  displayName: string;
  name: string;
  isEnabled: boolean;
  showDeleteModal: boolean;
  isReadyToSave: boolean;
}

class EditKpiPage extends React.Component<
  IEditKpiPageProps,
  IEditKpiPageState
> {
  public isLoaded = false;
  public state = {
    editModeOn: false,
    // tslint:disable-next-line:object-literal-sort-keys
    displayName: "",
    name: "",
    isEnabled: true,
    showDeleteModal: false,
    isReadyToSave: false
  };
  constructor(props: IEditKpiPageProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.delete = this.delete.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }

  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public save = (e: React.MouseEvent) => {
    e.preventDefault();
    const { accessToken, onHandleUpdateKpi, history, kpi } = this.props;
    const { displayName, name, isEnabled, isReadyToSave } = this.state;
    const updatedGroup = {
      id: kpi.id,
      // tslint:disable-next-line:object-literal-sort-keys
      displayName,
      name,
      isEnabled
    } as IKpi;
    if (this.readyToSave() && isReadyToSave) {
      onHandleUpdateKpi(accessToken, updatedGroup);
      history.push(paths.home);
    }
  };
  public delete = (e: React.MouseEvent) => {
    const { accessToken, kpi, history, onHandleDeleteKpi } = this.props;
    onHandleDeleteKpi(accessToken, kpi.id);
    history.push(paths.home);
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };
  public toggleEdit() {
    this.setState({ editModeOn: !this.state.editModeOn });
  }
  public toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  public readyToSave = () => {
    const { displayName, name } = this.state;
    if (
      displayName === undefined ||
      displayName === "" ||
      !validateKpiDisplayName(displayName) ||
      name === undefined ||
      name === "" ||
      !validateKpiName(name)
    ) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public async componentDidMount() {
    const { accessToken, onHandleLoadKpis, kpi, kpisLoaded } = this.props;
    const { displayName, name, isEnabled } = kpi;
    this.setState(() => ({ displayName, name, isEnabled }));
    this.isLoaded = true;
    if (this.isLoaded) {
      if (!kpisLoaded) {
        onHandleLoadKpis(accessToken);
      }
    }
  }
  public render() {
    const { kpi, existingKpis } = this.props;
    const {
      editModeOn,
      displayName,
      name,
      isEnabled,
      isReadyToSave
    } = this.state;
    const { allowDisableKpis } = settings;
    return (
      <Form>
        <Modal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>
            Delete KPI: {displayName}
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete KPI: {displayName}
            {"?"}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Confirm Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Card>
          <CardHeader>KPI Details for {displayName}</CardHeader>
          <CardBody>
            {!editModeOn && <KpiDetailsTable kpi={kpi} />}
            {editModeOn && (
              <React.Fragment>
                <AddTextField
                  noDuplicates={true}
                  isValid={validateKpiName}
                  title="Name"
                  value={name}
                  existingValues={existingKpis.map(k => k.name)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (n: string) => {
                    await this.setState(() => ({ name: n }));
                    this.readyToSave();
                  }}
                />
                <AddTextField
                  noDuplicates={true}
                  isValid={validateKpiDisplayName}
                  title="Display Name"
                  value={displayName}
                  existingValues={existingKpis.map(k => k.displayName)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (dn: string) => {
                    await this.setState(() => ({ displayName: dn }));
                    this.readyToSave();
                  }}
                />

                {allowDisableKpis && (
                  <Table>
                    <tr>
                      <th scope="row">Enabled</th>
                      <td>
                        <Input
                          type="checkbox"
                          defaultChecked={isEnabled}
                          onChange={this.isEnabledChange}
                        />
                        {isEnabled}
                      </td>
                    </tr>
                  </Table>
                )}
              </React.Fragment>
            )}
          </CardBody>
          <CardFooter>
            {!editModeOn && (
              <React.Fragment>
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  onClick={this.toggleDeleteModal}
                  style={{
                    margin: 4,
                    padding: 4
                  }}
                >
                  <strong>Delete KPI</strong>
                </Button>
              </React.Fragment>
            )}
            {editModeOn && (
              <React.Fragment>
                {isReadyToSave && (
                  <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
                    Save Changes
                  </Button>
                )}
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let kpi = {} as IKpi;
  const existingKpis = state.kpis.filter(
    k => k.id !== parseInt((match.params as any).id as string, 10)
  );
  if (match !== null) {
    kpi = state.kpis.find(k => {
      return k.id === parseInt((match.params as any).id as string, 10);
    }) as IKpi;
    // if (kpi === undefined) {
    //   // tslint:disable-next-line:no-console
    //   console.log("didn't find the KPI");
    //   return { kpi: {} as IKpi };
    // }
  }
  return {
    accessToken: state.auth0.accessToken,
    existingKpis,
    kpi,
    kpisLoaded: state.loadingKpis
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadKpis: (accessToken: string) => {
    dispatch(handleLoadKpis(accessToken));
  },
  onHandleUpdateKpi: (accessToken: string, kpi: IKpi) => {
    dispatch(handleUpdateKpi(accessToken, kpi));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleDeleteKpi: (accessToken: string, id: number) => {
    dispatch(handleDeleteKpi(accessToken, id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditKpiPage);
