import { AnyAction } from "redux";
import {
  ADD_USER_ROLE,
  LOAD_USER_ROLES,
  REMOVE_USER_ROLE
} from "../actions/userRoles";
import IRole from "../models/IRole";

export const userRoles = (state = [] as IRole[], action: AnyAction) => {
  switch (action.type) {
    case ADD_USER_ROLE:
      const newState: IRole[] = state.slice();
      newState.push(action.userRole as IRole);
      return newState;
    case REMOVE_USER_ROLE:
      return Object.assign(
        {},
        state.filter((r: IRole) => r.id !== (action.id as number))
      );
    case LOAD_USER_ROLES:
      return action.userRoles as IRole[];

    default:
      return state;
  }
};

export default userRoles;
