import { AnyAction } from "redux";
import {
  CLEAR_EDIT_ROLE_DETAILS,
  SET_EDIT_ROLE_DETAILS
} from "../actions/editRoleDetails";
import IRole from "../models/IRole";

export const editRoleDetails = (
  state = {} as IRole,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_EDIT_ROLE_DETAILS:
      return action.role as IRole;
    case CLEAR_EDIT_ROLE_DETAILS:
      return {} as IRole;
    default:
      return state;
  }
};

export default editRoleDetails;
