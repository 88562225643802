import * as Redux from "redux";
import getRoles from "../api/getRoles";
import saveRole from "../api/saveRole";
import saveUpdatedRole from "../api/saveUpdatedRole";
import { removeRole as deleteRole } from "../api/removeRole";
import IRole from "../models/IRole";
import { clearEditRoleDetails, setEditRoleDetails } from "./editRoleDetails";
import { setRolesLoaded } from "./loadingRoles";
export const ADD_ROLE = "ADD_ROLE";
export const REMOVE_ROLE = "REMOVE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const LOAD_ROLES = "LOAD_ROLES";

export const addRole = (role: IRole) => {
  return {
    role,
    type: ADD_ROLE
  };
};

export const removeRole = (id: number) => {
  return {
    id,
    type: REMOVE_ROLE
  };
};

export const updateRole = (role: IRole) => {
  return {
    role,
    type: UPDATE_ROLE
  };
};

export const loadRoles = (roles: IRole[]) => {
  return {
    roles,
    type: LOAD_ROLES
  };
};

export const handleSaveRole = (
  accessToken: string,
  name: string,
  displayName: string,
  currenyCodeId: number,
  fyStartMonthId: number,
  isEnabled: boolean
) => {
  return async (dispatch: Redux.Dispatch) => {
    const role = await saveRole(
      accessToken,
      name,
      displayName,
      currenyCodeId,
      fyStartMonthId,
      isEnabled
    );
    if (role !== null && role !== undefined) {
      dispatch(addRole(role));
      dispatch(setEditRoleDetails(role));
    }
  };
};

export const handleUpdateRole = (accessToken: string, role: IRole) => {
  return async (dispatch: Redux.Dispatch) => {
    const dsh = await saveUpdatedRole(accessToken, role);
    if (dsh !== null && dsh !== undefined) {
      dispatch(updateRole(role));
      dispatch(setEditRoleDetails(role));
    }
  };
};

export const handleDeleteRole = (accessToken: string, id: number) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deleteRole(accessToken, id);
    if (result === 200) {
      dispatch(removeRole(id));
      dispatch(clearEditRoleDetails());
    }
  };
};

export const handleLoadRoles = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const roles = await getRoles(accessToken);
    if (roles !== undefined) {
      if (roles.length > 0) {
        roles.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
        dispatch(loadRoles(roles));
      }
      dispatch(setRolesLoaded(true));
    }
  };
};
