import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  clearEditUserDetails,
  setEditUserDetails
} from "../../../actions/editUserDetails";
import { clearEditUserGroupIds } from "../../../actions/editUserGroupIds";
import { clearEditUserRoleIds } from "../../../actions/editUserRoleIds";
import { setEditUserDetailsLoaded } from "../../../actions/loadingEditUserDetails";
import { setEditUserGroupsLoaded } from "../../../actions/loadingEditUserGroups";
import { setEditUserRolesLoaded } from "../../../actions/loadingEditUserRoles";
import { handleLoadUserProfiles } from "../../../actions/profiles";
import UserWizardProgress from "../../../components/molecules/UserWizardProgress";
import AddUserDetails from "../../../components/templates/admin/AddUserDetails";
import AssignUserGroups from "../../../components/templates/admin/AssignUserGroups";
import AssignUserRoles from "../../../components/templates/admin/AssignUserRoles";
import EditUserDetails from "../../../components/templates/admin/EditUserDetails";
import UserSummary from "../../../components/templates/admin/UserSummary";
import paths, { userWizardStages } from "../../../constants";
import { IStore } from "../../../models/IStore";
import IUserProfile from "../../../models/IUserProfile";

export interface IUserProfileWizardPageProps extends RouteComponentProps {
  accessToken: string;
  users: IUserProfile[];
  usersLoaded: boolean;
  userId: number;
  editUserDetailsLoaded: boolean;
  onHandleLoadEditUser: (user: IUserProfile) => void;
  onHandleLoadUsers: (accessToken: string) => void;
  onHandleClearEditUser: () => void;
  onHandleClearEditUserRoleIds: () => void;
  onHandleClearEditUserGroupIds: () => void;
}

export interface IUserProfileWizardPageState {
  currentStage: number;
  user: IUserProfile;
}

class UserWizardPage extends React.Component<
  IUserProfileWizardPageProps,
  IUserProfileWizardPageState
> {
  public state = {
    currentStage: 0,
    user: {
      id: 0,
      // tslint:disable-next-line:object-literal-sort-keys
      emailAddress: "",
      isEnabled: true
    } as IUserProfile
  };
  constructor(props: IUserProfileWizardPageProps) {
    super(props);
    this.incrementStage = this.incrementStage.bind(this);
    this.decrementStage = this.decrementStage.bind(this);
    this.renderCurrentStage = this.renderCurrentStage.bind(this);
    this.cancel = this.cancel.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
  }

  public incrementStage = (e: React.MouseEvent<HTMLElement>) => {
    const { currentStage } = this.state;
    this.setState(() => ({ currentStage: currentStage + 1 }));
  };

  public decrementStage = (e: React.MouseEvent<HTMLElement>) => {
    const { currentStage } = this.state;
    this.setState(() => ({ currentStage: currentStage - 1 }));
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };
  public renderCurrentStage = (currentStage: number) => {
    switch (currentStage) {
      case userWizardStages.addInitialUserDetails:
        if (this.state.user.id === 0) {
          return <AddUserDetails />;
        } else {
          return <EditUserDetails {...this.props} />;
        }

      case userWizardStages.assignRoles:
        return <AssignUserRoles />;
      case userWizardStages.assignGroups:
        return <AssignUserGroups />;
      case userWizardStages.reviewAndComplete:
        return <UserSummary {...this.props} />;
      default:
        return (
          <Card>
            <CardBody>Not sure what stage we're at</CardBody>
          </Card>
        );
    }
  };

  public loadUserData = async () => {
    const {
      accessToken,
      users,
      usersLoaded,
      userId,
      onHandleLoadUsers,
      onHandleLoadEditUser,
      onHandleClearEditUser,
      onHandleClearEditUserRoleIds,
      onHandleClearEditUserGroupIds,
      editUserDetailsLoaded
    } = this.props;
    if (!usersLoaded) {
      await onHandleLoadUsers(accessToken);
    }
    if (!editUserDetailsLoaded && usersLoaded) {
      const user = users.find(r => r.id === userId);
      if (user !== undefined) {
        this.setState(() => ({ user }));
        await onHandleLoadEditUser(user);
      } else {
        await onHandleClearEditUser();
        await onHandleClearEditUserRoleIds();
        await onHandleClearEditUserGroupIds();
        this.setState(() => ({
          user: {
            id: 0,
            // tslint:disable-next-line:object-literal-sort-keys
            emailAddress: "",
            isEnabled: true
          } as IUserProfile
        }));
      }
    }
  };

  public async componentDidMount() {
    this.loadUserData();
  }

  public async componentDidUpdate(prevProps: IUserProfileWizardPageProps) {
    const {
      userId,
      onHandleClearEditUser,
      onHandleClearEditUserRoleIds,
      onHandleClearEditUserGroupIds
    } = this.props;
    if (userId !== prevProps.userId) {
      onHandleClearEditUser();
      onHandleClearEditUserRoleIds();
      onHandleClearEditUserGroupIds();
      await this.loadUserData();
    }
  }
  public componentWillUnmount() {
    this.props.onHandleClearEditUser();
    this.props.onHandleClearEditUserRoleIds();
    this.props.onHandleClearEditUserGroupIds();
  }
  public render() {
    const { currentStage } = this.state;
    return (
      <React.Fragment>
        <Container style={{ backgroundColor: "white" }}>
          <Row>
            <Col>
              <h1>Client User Wizard</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <UserWizardProgress currentStage={currentStage} />
            </Col>
          </Row>
          <Row>
            <Col>
              {currentStage > userWizardStages.addInitialUserDetails && (
                <Button
                  onClick={this.decrementStage}
                  style={{ padding: 4, margin: 4 }}
                >
                  Previous
                </Button>
              )}
              {currentStage < userWizardStages.reviewAndComplete && (
                <React.Fragment>
                  {this.props.userId !== 0 && (
                    <Button
                      onClick={this.incrementStage}
                      style={{ padding: 4, margin: 4 }}
                    >
                      Next
                    </Button>
                  )}
                </React.Fragment>
              )}

              {/* <Button onClick={this.cancel} style={{ padding: 4, margin: 4 }}>
                Cancel
              </Button> */}
            </Col>
          </Row>
          <Row>
            <Col>{this.renderCurrentStage(currentStage)}</Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  const userId =
    (match.params as any).id !== undefined
      ? parseInt((match.params as any).id as string, 10) // we're editing an existing user
      : // we're adding a new user
      state.editUserDetails.id === undefined
      ? 0 // we haven't saved the user yet so not yet in store
      : state.editUserDetails.id;

  return {
    accessToken: state.auth0.accessToken,
    userId,
    users: state.profiles,
    usersLoaded: state.loadingUsers,
    // tslint:disable-next-line:object-literal-sort-keys
    editUserDetailsLoaded: state.loadingEditUserDetails
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadEditUser: (user: IUserProfile) => {
    dispatch(setEditUserDetails(user));
    dispatch(setEditUserDetailsLoaded(true));
  },
  onHandleLoadUsers: async (accessToken: string) => {
    await dispatch(handleLoadUserProfiles(accessToken));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleClearEditUser: () => {
    dispatch(clearEditUserDetails());
    dispatch(setEditUserDetailsLoaded(false));
  },
  onHandleClearEditUserRoleIds: () => {
    dispatch(clearEditUserRoleIds());
    dispatch(setEditUserRolesLoaded(false));
  },
  onHandleClearEditUserGroupIds: () => {
    dispatch(clearEditUserGroupIds());
    dispatch(setEditUserGroupsLoaded(false));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserWizardPage);
