import * as Redux from "redux";
import getFyStartMonths from "../api/getFyStartMonths";
import IFYStartMonth from "../models/IFYStartMonth";
import { setFyStartMonthsLoaded } from "./loadingFyStartMonths";

export const ADD_FY_START_MONTH = "ADD_FY_START_MONTH";
export const REMOVE_FY_START_MONTH = "REMOVE_FY_START_MONTH";
export const UPDATE_FY_START_MONTH = "UPDATE_FY_START_MONTH";
export const LOAD_FY_START_MONTHS = "LOAD_FY_START_MONTHS";

export const addFyStartMonth = (fyStartMonth: IFYStartMonth) => {
  return {
    fyStartMonth,
    type: ADD_FY_START_MONTH
  };
};

export const removeFyStartMonth = (id: number) => {
  return {
    id,
    type: REMOVE_FY_START_MONTH
  };
};

export const updateFyStartMonth = (fyStartMonth: IFYStartMonth) => {
  return {
    fyStartMonth,
    type: UPDATE_FY_START_MONTH
  };
};

export const loadFyStartMonths = (fyStartMonths: IFYStartMonth[]) => {
  return {
    fyStartMonths,
    type: LOAD_FY_START_MONTHS
  };
};

export const handleLoadFyStartMonths = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const fyStartMonths = await getFyStartMonths(accessToken);
    if (fyStartMonths !== undefined) {
      if (fyStartMonths.length > 0) {
        fyStartMonths.sort((a, b) => (a.id > b.id ? 1 : -1));
        dispatch(loadFyStartMonths(fyStartMonths));
      }
      dispatch(setFyStartMonthsLoaded(true));
    }
  };
};
