import * as Redux from "redux";
import getRolePredicates from "../api/getRolePredicates";
import { removeRolePredicate as deleteRolePredicate } from "../api/removeRolePredicate";
import saveRolePredicate from "../api/saveRolePredicate";
import saveUpdatedRolePredicate from "../api/saveUpdatedRolePredicate";
import IRolePredicate from "../models/IRolePredicate";
import { setEditRolePredicatesLoaded } from "./loadingEditRolePredicates";

export const ADD_EDIT_ROLE_PREDICATE = "ADD_EDIT_ROLE_PREDICATE";
export const UPDATE_EDIT_ROLE_PREDICATE = "UPDATE_EDIT_ROLE_PREDICATE";
export const REMOVE_EDIT_ROLE_PREDICATE = "REMOVE_EDIT_ROLE_PREDICATE";
export const LOAD_EDIT_ROLE_PREDICATES = "LOAD_EDIT_ROLE_PREDICATES";
export const CLEAR_EDIT_ROLE_PREDICATES = "CLEAR_EDIT_ROLE_PREDICATES";

export const addEditRolePredicate = (rolePredicate: IRolePredicate) => {
  return {
    rolePredicate,
    type: ADD_EDIT_ROLE_PREDICATE
  };
};

export const updateEditRolePredicate = (rolePredicate: IRolePredicate) => {
  return {
    rolePredicate,
    type: UPDATE_EDIT_ROLE_PREDICATE
  };
};

export const removeEditRolePredicate = (
  roleId: number,
  predicateId: number
) => {
  return {
    roleId,
    // tslint:disable-next-line:object-literal-sort-keys
    predicateId,
    type: REMOVE_EDIT_ROLE_PREDICATE
  };
};

export const loadEditRolePredicates = (rolePredicates: IRolePredicate[]) => {
  return {
    rolePredicates,
    type: LOAD_EDIT_ROLE_PREDICATES
  };
};

export const clearEditRolePredicates = () => {
  return {
    type: CLEAR_EDIT_ROLE_PREDICATES
  };
};

export const handleLoadEditRolePredicates = (
  accessToken: string,
  roleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const rolePredicates = await getRolePredicates(accessToken, roleId);
    if (rolePredicates === undefined) {
      // tslint:disable-next-line:no-console
      // console.log(
      //   "No Role Predicates found, or user does not have access to edit these role predicates"
      // );
      dispatch(clearEditRolePredicates());
      dispatch(setEditRolePredicatesLoaded(false));
    } else {
      if (rolePredicates.length > 0) {
        dispatch(loadEditRolePredicates(rolePredicates));
      }
      dispatch(setEditRolePredicatesLoaded(true));
    }
  };
};

export const handleSaveRolePredicate = (
  accessToken: string,
  rolePredicate: IRolePredicate
) => {
  return async (dispatch: Redux.Dispatch) => {
    const rp = await saveRolePredicate(
      accessToken,
      rolePredicate.roleId,
      rolePredicate.predicateId,
      rolePredicate.value
    );
    dispatch(addEditRolePredicate(rp));
  };
};

export const handleUpdateRolePredicate = (
  accessToken: string,
  rolePredicate: IRolePredicate
) => {
  return async (dispatch: Redux.Dispatch) => {
    await saveUpdatedRolePredicate(accessToken, rolePredicate);
    dispatch(updateEditRolePredicate(rolePredicate));
  };
};

export const handleDeleteRolePredicate = (
  accessToken: string,
  roleId: number,
  predicateId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await deleteRolePredicate(accessToken, roleId, predicateId);
    dispatch(removeEditRolePredicate(roleId, predicateId));
  };
};
