import * as React from "react";
import { connect } from "react-redux";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap";
import { handleLoadDashboards } from "../../../actions/dashboards";
import {
  clearEditRoleDashboardIds,
  handleDeleteEditRoleDashboardId,
  handleLoadEditRoleDashboardIds,
  handleSaveEditRoleDashboardId
} from "../../../actions/editRoleDashboardIds";
import DashboardsBadge from "../../../components/molecules/DashboardsBadge";
import DataLoading from "../../../components/organisms/DataLoading";
import IPbiDashboard from "../../../models/IPbiDashboard";
import { IStore } from "../../../models/IStore";

export interface IAssignRoleDashboardsState {
  editRoleDashboardIds: number[];
  filteredDashboards: IPbiDashboard[];
}

export interface IAssignRoleDashboardsProps {
  accessToken: string;
  dashboards: IPbiDashboard[];
  dashboardsLoaded: boolean;
  editRoleDashboardIds: number[];
  editRoleDashboardIdsLoaded: boolean;
  roleId: number;
  onHandleLoadDashboards: (accessToken: string) => void;
  onHandleLoadEditRoleDashboardIds: (
    accessToken: string,
    roleId: number
  ) => void;
  onClearEditRoleDashboardIds: () => void;
  onHandleSaveEditRoleDashboardId: (
    accessToken: string,
    roleId: number,
    dashboardId: number
  ) => void;
  onHandleDeleteEditRoleDashboardId: (
    accessToken: string,
    roleId: number,
    dashboardId: number
  ) => void;
}

class AssignRoleDashboards extends React.Component<
  IAssignRoleDashboardsProps,
  IAssignRoleDashboardsState
> {
  public state = {
    editRoleDashboardIds: [] as number[],
    filteredDashboards: [] as IPbiDashboard[]
  };
  constructor(props: IAssignRoleDashboardsProps) {
    super(props);
    this.dashboardCheckboxChange = this.dashboardCheckboxChange.bind(this);
    this.txtFilterChange = this.txtFilterChange.bind(this);
  }
  public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { dashboards } = this.props;
    const filteredDashboards = dashboards.filter(dashboard =>
      dashboard.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    await this.setState(() => ({ filteredDashboards }));
  };
  public dashboardCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      accessToken,
      roleId,
      onHandleSaveEditRoleDashboardId,
      onHandleDeleteEditRoleDashboardId
    } = this.props;
    e.preventDefault();
    if (e.target.checked) {
      await onHandleSaveEditRoleDashboardId(
        accessToken,
        roleId,
        Number(e.target.value)
      );
    } else {
      await onHandleDeleteEditRoleDashboardId(
        accessToken,
        roleId,
        Number(e.target.value)
      );
    }
  };
  public async componentDidMount() {
    const {
      accessToken,
      dashboards,
      dashboardsLoaded,
      editRoleDashboardIds,
      onHandleLoadDashboards,
      onHandleLoadEditRoleDashboardIds,
      roleId
    } = this.props;
    if (!dashboardsLoaded) {
      await onHandleLoadDashboards(accessToken);
    }
    await onHandleLoadEditRoleDashboardIds(accessToken, roleId);
    this.setState({ editRoleDashboardIds });
    this.setState({ filteredDashboards: dashboards });
  }

  public async componentDidUpdate(prevProps: IAssignRoleDashboardsProps) {
    const { editRoleDashboardIds, dashboards } = this.props;
    if (prevProps.dashboards !== dashboards) {
      await this.setState(() => ({ filteredDashboards: dashboards }));
    }
    if (prevProps.editRoleDashboardIds !== editRoleDashboardIds) {
      await this.setState(() => ({ editRoleDashboardIds }));
    }
  }

  public render() {
    const { filteredDashboards, editRoleDashboardIds } = this.state;
    const {
      dashboards,
      dashboardsLoaded,
      editRoleDashboardIdsLoaded
    } = this.props;
    return (
      <React.Fragment>
        {!dashboardsLoaded && <DataLoading dataTitle="Dashboards " />}
        {!editRoleDashboardIdsLoaded && (
          <DataLoading dataTitle="Role Dashboards " />
        )}
        {dashboardsLoaded && editRoleDashboardIdsLoaded && (
          <Container>
            <Row>
              <Col>
                <h2>Assign Dashboards</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                {dashboardsLoaded && (
                  <DashboardsBadge
                    dashboards={dashboards}
                    dashboardIds={editRoleDashboardIds}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Label for="txtFilter">Search: </Label>
                <Input
                  type="text"
                  id="txtFilter"
                  onChange={this.txtFilterChange}
                  placeholder="Search for..."
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroup>
                  {filteredDashboards.map(dashboard => (
                    <ListGroupItem key={dashboard.id}>
                      <FormGroup check={true}>
                        <Label check={true}>
                          <Input
                            type="checkbox"
                            value={dashboard.id}
                            onChange={this.dashboardCheckboxChange}
                            checked={
                              this.state.editRoleDashboardIds.find(
                                id => id === dashboard.id
                              ) !== undefined
                            }
                          />
                          {dashboard.displayName}
                        </Label>
                      </FormGroup>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
  const { onSave } = ownProps;
  return {
    accessToken: state.auth0.accessToken,
    dashboards: state.dashboards,
    dashboardsLoaded: state.loadingDashboards,
    roleId: state.editRoleDetails.id,
    // tslint:disable-next-line:object-literal-sort-keys
    editRoleDashboardIds: state.editRoleDashboardIds,
    editRoleDashboardIdsLoaded: state.loadingEditRoleDashboards,
    onSave
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadDashboards: (accessToken: string) => {
    dispatch(handleLoadDashboards(accessToken));
  },
  onHandleLoadEditRoleDashboardIds: (accessToken: string, roleId: number) => {
    dispatch(handleLoadEditRoleDashboardIds(accessToken, roleId));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onClearEditRoleDashboardIds: () => {
    dispatch(clearEditRoleDashboardIds());
  },
  onHandleSaveEditRoleDashboardId: (
    accessToken: string,
    roleId: number,
    dashboardId: number
  ) => {
    dispatch(handleSaveEditRoleDashboardId(accessToken, roleId, dashboardId));
  },
  onHandleDeleteEditRoleDashboardId: (
    accessToken: string,
    roleId: number,
    dashboardId: number
  ) => {
    dispatch(handleDeleteEditRoleDashboardId(accessToken, roleId, dashboardId));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignRoleDashboards);
