import { AnyAction } from "redux";
import {
  ADD_FINANCIAL_YEAR,
  LOAD_FINANCIAL_YEARS,
  REMOVE_FINANCIAL_YEAR,
  UPDATE_FINANCIAL_YEAR
} from "../actions/financialYears";
import IFinancialYear from "../models/IFinancialYear";

export const financialYears = (
  state = [] as IFinancialYear[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_FINANCIAL_YEAR:
      const newState: IFinancialYear[] = state.slice();
      newState.push(action.financialYear as IFinancialYear);
      return newState;
    case REMOVE_FINANCIAL_YEAR:
      return Object.assign(
        {},
        state.filter((fy: IFinancialYear) => fy.id !== (action.id as number))
      );
    case UPDATE_FINANCIAL_YEAR:
      return state.map((fy: IFinancialYear) => {
        if (fy.id === (action.financialYear as IFinancialYear).id) {
          return action.financialYear as IFinancialYear;
        }
        return fy;
      });
    case LOAD_FINANCIAL_YEARS:
      return action.financialYears as IFinancialYear[];
    default:
      return state;
  }
};

export default financialYears;
