import * as React from "react";
import { Input, Table } from "reactstrap";
import { settings } from "../../constants";
import IKpi from "../../models/IKpi";

export interface IKpiDetailsTableProps {
  kpi: IKpi;
}

const KpiDetailsTable: React.SFC<IKpiDetailsTableProps> = (
  props: IKpiDetailsTableProps
) => {
  const { kpi } = props;
  const { displayName, name, isEnabled } = kpi;
  const { allowDisableKpis } = settings;
  return (
    <Table striped={true}>
      <tbody>
        <tr>
          <th scope="row">Name</th>
          <td> {name}</td>
        </tr>
        <tr>
          <th scope="row">Display Name</th>
          <td> {displayName}</td>
        </tr>
        {allowDisableKpis && (
          <tr>
            <th scope="row">Enabled</th>
            <td>
              <Input type="checkbox" checked={isEnabled} readOnly={true} />
              {isEnabled}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default KpiDetailsTable;
