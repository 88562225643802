import refreshAccessToken from "../api/refreshAccessToken";
import { ITokenData } from "../models/ITokenData";

export const SET_PBI_ACCESS_TOKEN = "SET_PBI_ACCESS_TOKEN";
export const CLEAR_PBI_ACCESS_TOKEN = "CLEAR_PBI_ACCESS_TOKEN";

export const setPbiAccessToken = (tokenData: ITokenData) => {
  return {
    tokenData,
    type: SET_PBI_ACCESS_TOKEN
  };
};

export const clearPbiAccessToken = () => {
  return {
    type: CLEAR_PBI_ACCESS_TOKEN
  };
};
export const handleRefreshToken = (
  accessToken: string,
  refreshToken: string
) => {
  return async (dispatch: any) => {
    const tokenData = await refreshAccessToken(accessToken, refreshToken);

    await dispatch(setPbiAccessToken(tokenData));
  };
};
