import * as React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardFooter } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import { handleSaveRole } from "../../../actions/roles";
import AddCurrencyCode from "../../../components/molecules/AddCurrencyCode";
import AddFYStartMonth from "../../../components/molecules/AddFYStartMonth";
import AddTextField from "../../../components/molecules/AddTextField";
import IRole from "../../../models/IRole";
import { IStore } from "../../../models/IStore";
import { validateRoleDisplayName, validateRoleName } from "../../../utils";

export interface IAddRoleDetailsProps {
  accessToken: string;
  roles: IRole[];
  onHandleSaveRole: (
    accessToken: string,
    name: string,
    displayName: string,
    currencyCodeId: number,
    fyStartMonthId: number,
    isEnabled: boolean
  ) => void;
}
export interface IAddRoleDetailsState {
  displayName: string;
  name: string;
  currencyCodeId: number;
  fyStartMonthId: number;
}

class AddRoleDetails extends React.Component<
  IAddRoleDetailsProps,
  IAddRoleDetailsState
> {
  public state = {
    displayName: "",
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    currencyCodeId: 1,
    fyStartMonthId: 1
  };
  constructor(props: IAddRoleDetailsProps) {
    super(props);
    this.save = this.save.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
  }
  public readyToSave = () => {
    const { displayName, name } = this.state;
    if (
      displayName === undefined ||
      displayName === "" ||
      !validateRoleDisplayName(displayName)
    ) {
      return false;
    }
    if (name === undefined || name === "" || !validateRoleName(name)) {
      return false;
    }
    return true;
  };
  public save = (e: React.MouseEvent) => {
    const { accessToken } = this.props;
    const { displayName, name, currencyCodeId, fyStartMonthId } = this.state;
    // do save
    if (this.readyToSave()) {
      this.props.onHandleSaveRole(
        accessToken,
        name,
        displayName,
        currencyCodeId,
        fyStartMonthId,
        true
      );
    }

    // callback and do next
  };
  public render() {
    const { roles } = this.props;
    const { displayName, name, currencyCodeId, fyStartMonthId } = this.state;
    return (
      <Card>
        <CardTitle>Add New Role</CardTitle>
        <CardBody>
          <AddTextField
            noDuplicates={true}
            isValid={validateRoleDisplayName}
            title="Display Name"
            value={displayName}
            existingValues={roles.map(role => role.displayName)}
            // tslint:disable-next-line:jsx-no-lambda
            callback={(dn: string) =>
              this.setState(() => ({ displayName: dn }))
            }
          />
          <AddTextField
            noDuplicates={true}
            isValid={validateRoleName}
            title="Name"
            value={name}
            existingValues={roles.map(role => role.name)}
            // tslint:disable-next-line:jsx-no-lambda
            callback={(n: string) => this.setState(() => ({ name: n }))}
          />
          <AddCurrencyCode
            currencyCodeId={currencyCodeId}
            // tslint:disable-next-line:jsx-no-lambda
            callback={(cc: number) =>
              this.setState(() => ({ currencyCodeId: cc }))
            }
          />
          <AddFYStartMonth
            fyStartMonthId={fyStartMonthId}
            // tslint:disable-next-line:jsx-no-lambda
            callback={(fysm: number) =>
              this.setState(() => ({ fyStartMonthId: fysm }))
            }
          />
        </CardBody>
        <CardFooter>
          {this.readyToSave() && (
            <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
              Save Changes
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    roles: state.roles
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleSaveRole: (
    accessToken: string,
    name: string,
    displayName: string,
    currencyCodeId: number,
    fyStartMonthId: number,
    isEnabled: boolean
  ) => {
    dispatch(
      handleSaveRole(
        accessToken,
        name,
        displayName,
        currencyCodeId,
        fyStartMonthId,
        isEnabled
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRoleDetails);
