import IRole from "../models/IRole";

export const SET_EDIT_ROLE_DETAILS = "SET_EDIT_ROLE_DETAILS";
export const CLEAR_EDIT_ROLE_DETAILS = "CLEAR_EDIT_ROLE_DETAILS";

export const setEditRoleDetails = (role: IRole) => {
  return {
    role,
    type: SET_EDIT_ROLE_DETAILS
  };
};

export const clearEditRoleDetails = () => {
  return {
    type: CLEAR_EDIT_ROLE_DETAILS
  };
};
