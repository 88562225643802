import { AnyAction } from "redux";
import {
  ADD_FY_START_MONTH,
  LOAD_FY_START_MONTHS,
  REMOVE_FY_START_MONTH,
  UPDATE_FY_START_MONTH
} from "../actions/fyStartMonths";
import IFYStartMonth from "../models/IFYStartMonth";

export const fyStartMonths = (
  state = [] as IFYStartMonth[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_FY_START_MONTH:
      const newState: IFYStartMonth[] = state.slice();
      newState.push(action.fyStartMonth as IFYStartMonth);
      return newState;
    case REMOVE_FY_START_MONTH:
      return Object.assign(
        {},
        state.filter((fysm: IFYStartMonth) => fysm.id !== (action.id as number))
      );
    case UPDATE_FY_START_MONTH:
      return state.map((fysm: IFYStartMonth) => {
        if (fysm.id === (action.fyStartMonth as IFYStartMonth).id) {
          return action.fyStartMonth as IFYStartMonth;
        }
        return fysm;
      });
    case LOAD_FY_START_MONTHS:
      return action.fyStartMonths as IFYStartMonth[];
    default:
      return state;
  }
};

export default fyStartMonths;
