import { AnyAction } from "redux";
import { SET_DASHBOARDS_LOADED } from "../actions/loadingDashboards";

export const loadingDashboards = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_DASHBOARDS_LOADED:
      return action.dashboardsLoaded;
    default:
      return state;
  }
};

export default loadingDashboards;
