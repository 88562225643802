import * as Redux from "redux";
import getFinancialYears from "../api/getFinancialYears";
import { removeFinancialYear as deleteFinancialYear } from "../api/removeFinancialYear";
import saveFinancialYear from "../api/saveFinancialYear";
import saveUpdatedFinancialYear from "../api/saveUpdatedFinancialYear";
import IFinancialYear from "../models/IFinancialYear";
import { setFinancialYearsLoaded } from "./loadingFinancialYears";

export const ADD_FINANCIAL_YEAR = "ADD_FINANCIAL_YEAR";
export const REMOVE_FINANCIAL_YEAR = "REMOVE_FINANCIAL_YEAR";
export const UPDATE_FINANCIAL_YEAR = "UPDATE_FINANCIAL_YEAR";
export const LOAD_FINANCIAL_YEARS = "LOAD_FINANCIAL_YEARS";

export const addFinancialYear = (financialYear: IFinancialYear) => {
  return {
    financialYear,
    type: ADD_FINANCIAL_YEAR
  };
};

export const removeFinancialYear = (id: number) => {
  return {
    id,
    type: REMOVE_FINANCIAL_YEAR
  };
};

export const updateFinancialYear = (financialYear: IFinancialYear) => {
  return {
    financialYear,
    type: UPDATE_FINANCIAL_YEAR
  };
};

const loadFinancialYears = (financialYears: IFinancialYear[]) => {
  return {
    financialYears,
    type: LOAD_FINANCIAL_YEARS
  };
};

export const handleLoadFinancialYears = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const financialYears = await getFinancialYears(accessToken);
    if (financialYears !== undefined) {
      if (financialYears.length > 0) {
        financialYears.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
        dispatch(loadFinancialYears(financialYears));
      }
      dispatch(setFinancialYearsLoaded(true));
    }
  };
};

export const handleSaveFinancialYear = (
  accessToken: string,
  displayName: string,
  endYear: number,
  isEnabled: boolean
) => {
  return async (dispatch: Redux.Dispatch) => {
    const financialYear = await saveFinancialYear(
      accessToken,
      displayName,
      endYear,
      isEnabled
    );
    if (financialYear !== null && financialYear !== undefined) {
      dispatch(addFinancialYear(financialYear));
    }
  };
};

export const handleUpdateFinancialYear = (
  accessToken: string,
  financialYear: IFinancialYear
) => {
  return async (dispatch: Redux.Dispatch) => {
    const fy = await saveUpdatedFinancialYear(accessToken, financialYear);
    if (fy !== null && fy !== undefined) {
      dispatch(updateFinancialYear(financialYear));
    }
  };
};

export const handleDeleteFinancialYear = (accessToken: string, id: number) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deleteFinancialYear(accessToken, id);
    if (result === 200) {
      dispatch(removeFinancialYear(id));
    }
  };
};
