import IKpiEntry from "../models/IKpiEntry";
import { fetchSecureData } from "../utils";

export const saveKpiEntry = async (
  accessToken: string,
  predicateId: number,
  clientId: number,
  year: number,
  monthId: number,
  kpiId: number,
  measuredValue: number,
  thresholdValue: number,
  isEnabled: boolean
) => {
  // tslint:disable-next-line:no-console
  // console.log(
  //   "predicateId:",
  //   predicateId,
  //   "clientId:",
  //   clientId,
  //   "monthId:",
  //   monthId,
  //   "year",
  //   year,
  //   "kpiId",
  //   kpiId,
  //   "measuredValue",
  //   measuredValue,
  //   "thresholdValue",
  //   thresholdValue
  // );
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/KpiEntry/Add?kpiId=${kpiId}&predicateId=${predicateId}&clientId=${clientId}&year=${year}&monthId=${monthId}&measuredValue=${measuredValue}&thresholdValue=${thresholdValue}&isEnabled=${isEnabled}`,
      accessToken
    );
    const kpiEntry = ((await data.json()) as any).value as IKpiEntry;
    return kpiEntry;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/KpiEntry/Add?kpiId=${kpiId}&predicateId=${predicateId}&clientId=${clientId}&year=${year}&monthId=${monthId}&measuredValue=${measuredValue}&thresholdValue=${thresholdValue}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as IKpiEntry;
};

export default saveKpiEntry;
