import { settings } from "../constants";
import ISqlReport from "../models/ISqlReport";
import { fetchSecureData } from "../utils";

export const saveReport = async (
  accessToken: string,
  name: string,
  displayName: string,
  defaultZoomMode: string,
  defaultZoomPercent: number,
  parameters: string,
  path: string,
  sqlReportingServerUrl: string,
  sqlReportingViewerUrl: string,
  isEnabled: boolean
) => {
  const {
    allowAlternateSqlReportingServerUrl,
    allowAlternateSqlReportingViewerUrl
  } = settings;

  let data;
  try {
    if (
      allowAlternateSqlReportingServerUrl &&
      allowAlternateSqlReportingViewerUrl
    ) {
      data = await fetchSecureData(
        "POST",
        `/api/SqlReport/Add?name=${name}&displayName=${displayName}&defaultZoomMode=${defaultZoomMode}&defaultZoomPercent=${defaultZoomPercent}&parameters=${parameters}&path=${path}&sqlReportingServerUrl=${sqlReportingServerUrl}&sqlReportingViewerUrl=${sqlReportingViewerUrl}&isEnabled=${isEnabled}`,
        accessToken
      );
    } else {
      data = await fetchSecureData(
        "POST",
        `/api/SqlReport/Add?name=${name}&displayName=${displayName}&defaultZoomMode=${defaultZoomMode}&defaultZoomPercent=${defaultZoomPercent}&parameters=${parameters}&path=${path}&isEnabled=${isEnabled}`,
        accessToken
      );
    }
    const report = ((await data.json()) as any).value as ISqlReport;
    return report;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/SqlReport/Add?name=${name}&displayName=${displayName}&defaultZoomMode=${defaultZoomMode}&defaultZoomPercent=${defaultZoomPercent}&parameters=${parameters}&path=${path}&sqlReportingServerUrl=${sqlReportingServerUrl}&sqlReportingViewerUrl=${sqlReportingViewerUrl}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as ISqlReport;
};

export default saveReport;
