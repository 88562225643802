import { AnyAction } from "redux";
import {
  ADD_EDIT_USER_ROLE_ID,
  CLEAR_EDIT_USER_ROLE_IDS,
  LOAD_EDIT_USER_ROLE_IDS,
  REMOVE_EDIT_USER_ROLE_ID
} from "../actions/editUserRoleIds";

export const editUserRoleIds = (
  state = [] as number[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_EDIT_USER_ROLE_ID:
      const newState: number[] = state.slice();
      newState.push(action.id as number);
      return newState;
    case REMOVE_EDIT_USER_ROLE_ID:
      return Object.assign(
        [],
        state.filter((id: number) => id !== (action.id as number))
      ) as number[];
    case LOAD_EDIT_USER_ROLE_IDS:
      return action.ids as number[];
    case CLEAR_EDIT_USER_ROLE_IDS:
      return [] as number[];
    default:
      return state;
  }
};

export default editUserRoleIds;
