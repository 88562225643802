import { fetchSecureData } from "../utils";

export const removeRoleDashboard = async (accessToken: string, roleId: number, dashboardId: number) => {
  try {
    const data = await fetchSecureData(
      "DELETE",
      `/api/RoleDashboard/Remove?roleId=${roleId}&dashboardId=${dashboardId}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/RoleDashboard/Remove?roleId=${roleId}&dashboardId=${dashboardId}`, err);
  }
  return 404;
};

export default removeRoleDashboard;
