import { AnyAction } from "redux";
import {
  ADD_KPI,
  LOAD_KPIS,
  REMOVE_KPI,
  UPDATE_KPI
} from "../actions/kpis";
import IKpi from "../models/IKpi";

export const kpis = (state = [] as IKpi[], action: AnyAction) => {
  switch (action.type) {
    case ADD_KPI:
      const newState: IKpi[] = state.slice();
      newState.push(action.kpi as IKpi);
      return newState;
    case REMOVE_KPI:
      return Object.assign(
        [],
        state.filter((k: IKpi) => k.id !== (action.id as number))
      ) as IKpi[];
    case UPDATE_KPI:
      return Object.assign(
        [],
        state.map((k: IKpi) => {
          if (k.id === (action.kpi as IKpi).id) {
            return action.kpi as IKpi;
          }
          return k;
        })
      ) as IKpi[];
    case LOAD_KPIS:
      return action.kpis as IKpi[];
    default:
      return state;
  }
};

export default kpis;
