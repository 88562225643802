import * as React from "react";
import { Tooltip } from "reactstrap";

export interface IToolTipState {
  isOpen: boolean;
}
export interface IToolTipProps {
  placement?:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start";
  target: string;
  children?: React.ReactNode;
}

export class ToolTip extends React.Component<IToolTipProps, IToolTipState> {
  public state = { isOpen: false };
  constructor(props: IToolTipProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }
  public toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  public render() {
    const { children, placement, target } = this.props;
    const {isOpen} = this.state;
    return (
      <Tooltip
        placement={placement === undefined ? "top" : placement}
        isOpen={isOpen}
        target={target}
        toggle={this.toggle}
      >
        {children}
      </Tooltip>
    );
  }
}

export default ToolTip;
