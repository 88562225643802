import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ProfileCard from "../molecules/ProfileCard";
import ProfileBar from "./ProfileBar";

export interface IProfileModalState {
  modal: boolean;
}
export interface IProfileModalProps {
  className?: string;
}
export default class ProfileModal extends React.Component<
  IProfileModalProps,
  IProfileModalState
> {
  public state: IProfileModalState = {
    modal: false
  };
  constructor(props: IProfileModalProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  public toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  public render() {
    const { className } = this.props;
    return (
      <div>
        <div onClick={this.toggle}>
          <ProfileBar />
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={className}
        >
          <ModalHeader toggle={this.toggle}>Profile</ModalHeader>
          <ModalBody>
            <ProfileCard />
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-info" onClick={this.toggle}>
              OK
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
