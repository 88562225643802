import { fetchSecureData } from "../utils";

export const removeRolePredicate = async (accessToken: string, roleId: number, predicateId:number) => {
  try {
    const data = await fetchSecureData(
      "DELETE",
      `/api/RolePredicate/Remove?roleId=${roleId}&predicateId=${predicateId}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/RolePredicate/Remove?roleId=${roleId}&predicateId=${predicateId}`, err);
  }
  return 404;
};

export default removeRolePredicate;
