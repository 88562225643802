import IFinancialYear from "../models/IFinancialYear";
import { fetchSecureData } from "../utils";

export const saveFinancialYear = async (
  accessToken: string,
  displayName: string,
  endYear: number,
  isEnabled: boolean
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/FinancialYear/Add?displayName=${displayName}&endYear=${endYear}&isEnabled=${isEnabled}`,
      accessToken
    );
    const financialYear = ((await data.json()) as any).value as IFinancialYear;
    return financialYear;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/FinancialYear/Add?displayName=${displayName}&endYear=${endYear}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as IFinancialYear;
};

export default saveFinancialYear;
