import IGroup from "../models/IGroup";
import { fetchSecureData } from "../utils";

export const saveGroup = async (
  accessToken: string,
  name: string,
  isEnabled: boolean
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/Group/Add?name=${name}&isEnabled=${isEnabled}`,
      accessToken
    );
    const group = ((await data.json()) as any).value as IGroup;
    return group;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/Group/Add?name=${name}&isEnabled=${isEnabled}`,
      err
    );
  }
  return {} as IGroup;
};

export default saveGroup;
