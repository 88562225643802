import { AnyAction } from "redux";
import {
  ADD_ACTIVE_ROLE_CLIENT,
  CLEAR_ACTIVE_ROLE_CLIENTS,
  LOAD_ACTIVE_ROLE_CLIENTS,
  REMOVE_ACTIVE_ROLE_CLIENT
} from "../actions/activeRoleClients";
import IClient from "../models/IClient";

export const activeRoleClients = (state = [] as IClient[], action: AnyAction) => {
  switch (action.type) {
    case ADD_ACTIVE_ROLE_CLIENT:
      const newState: IClient[] = state.slice();
      newState.push(action.client as IClient);
      return newState;
    case REMOVE_ACTIVE_ROLE_CLIENT:
      return Object.assign(
        [],
        state.filter((client: IClient) => client.id !== (action.client as IClient).id)
      ) as IClient[];
    case LOAD_ACTIVE_ROLE_CLIENTS:
      return action.clients as IClient[];
    case CLEAR_ACTIVE_ROLE_CLIENTS:
      return [] as IClient[];
    default:
      return state;
  }
};

export default activeRoleClients;
