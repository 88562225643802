import * as Redux from "redux";
import getPredicates from "../api/getPredicates";
import { removePredicate as deletePredicate } from "../api/removePredicate";
import savePredicate from "../api/savePredicate";
import saveUpdatedPredicate from "../api/saveUpdatedPredicate";
import IPredicate from "../models/IPredicate";
import { setPredicatesLoaded } from "./loadingPredicates";

export const ADD_PREDICATE = "ADD_PREDICATE";
export const REMOVE_PREDICATE = "REMOVE_PREDICATE";
export const UPDATE_PREDICATE = "UPDATE_PREDICATE";
export const LOAD_PREDICATES = "LOAD_PREDICATES";

export const addPredicate = (predicate: IPredicate) => {
  return {
    predicate,
    type: ADD_PREDICATE
  };
};

export const removePredicate = (id: number) => {
  return {
    id,
    type: REMOVE_PREDICATE
  };
};

export const updatePredicate = (predicate: IPredicate) => {
  return {
    predicate,
    type: UPDATE_PREDICATE
  };
};

export const loadPredicates = (predicates: IPredicate[]) => {
  return {
    predicates,
    type: LOAD_PREDICATES
  };
};

export const handleLoadPredicates = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const predicates = await getPredicates(accessToken);
    if (predicates !== undefined) {
      if (predicates.length > 0) {
        predicates.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        dispatch(loadPredicates(predicates));
      }
      dispatch(setPredicatesLoaded(true));
    }
  };
};

export const handleSavePredicate = (
  accessToken: string,
  displayName:string,
  name: string,
  isKpiGrouping: boolean,
  isEnabled: boolean
) => {
  return async (dispatch: Redux.Dispatch) => {
    const predicate = await savePredicate(
      accessToken,
      displayName,
      name,
      isKpiGrouping,
      isEnabled
    );
    if (predicate !== null && predicate !== undefined) {
      dispatch(addPredicate(predicate));
    }
  };
};

export const handleUpdatePredicate = (
  accessToken: string,
  predicate: IPredicate
) => {
  return async (dispatch: Redux.Dispatch) => {
    const pred = await saveUpdatedPredicate(accessToken, predicate);
    if (pred !== null && pred !== undefined) {
      dispatch(updatePredicate(pred));
    }
  };
};

export const handleDeletePredicate = (accessToken: string, id: number) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deletePredicate(accessToken, id);
    if (result === 200) {
      dispatch(removePredicate(id));
    }
  };
};
