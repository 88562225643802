import { AnyAction } from "redux";
import {
  ADD_REPORT,
  LOAD_REPORTS,
  REMOVE_REPORT,
  UPDATE_REPORT
} from "../actions/reports";
import ISqlReport from "../models/ISqlReport";

export const reports = (state = [] as ISqlReport[], action: AnyAction) => {
  switch (action.type) {
    case ADD_REPORT:
      const newState: ISqlReport[] = state.slice();
      newState.push(action.report as ISqlReport);
      return newState;
    case REMOVE_REPORT:
      return Object.assign(
        [],
        state.filter((rpt: ISqlReport) => rpt.id !== (action.id as number))
      ) as ISqlReport[];
    case UPDATE_REPORT:
      return Object.assign(
        [],
        state.map((rpt: ISqlReport) => {
          if (rpt.id === (action.report as ISqlReport).id) {
            return action.report as ISqlReport;
          }
          return rpt;
        })
      );
    case LOAD_REPORTS:
      return action.reports as ISqlReport[];
    default:
      return state;
  }
};

export default reports;
