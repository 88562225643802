import * as React from "react";
import Logout from "../../assets/icons/logoutWhite.svg";
import Icon from "./Icon";

const LogoutIcon = () => {
  return (
    <React.Fragment>
      <Icon icon={Logout} text="Logout" id="logout" />
    </React.Fragment>
  );
};

export default LogoutIcon;
