import IUserProfile from "../models/IUserProfile";
import { fetchSecureData } from "../utils";

export const saveRoleUser = async (
  accessToken: string,
  roleId: number,
  userId: number,
) => {
  try {
    const data = await fetchSecureData(
      "POST",
      `/api/RoleUser/Add?roleId=${roleId}&userId=${userId}`,
      accessToken
    );
    const roleUser = ((await data.json()) as any).value as IUserProfile;
    return roleUser;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/roleUser/Add?roleId=${roleId}&&userId=${userId}`
    );
  }
  return {} as IUserProfile;
};

export default saveRoleUser;
