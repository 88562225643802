import * as React from "react";
import { Button } from "reactstrap";

export interface IResetSizeToWindowButtonProps {
  onClick: () => void;
  isVisible: boolean;
}

const ResetSizeToWindowButton: React.SFC<IResetSizeToWindowButtonProps> = (
  props: IResetSizeToWindowButtonProps
) => {
  const { isVisible, onClick } = props;
  return (
    <Button
      onClick={onClick}
      size="sm"
      color="info"
      style={{
        display: isVisible ? "block" : "none",
        float: "right",
        margin: 4
      }}
    >
      Reset size to window
    </Button>
  );
};

export default ResetSizeToWindowButton;
