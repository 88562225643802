import { AnyAction } from "redux";
import {
  ADD_GROUP,
  LOAD_GROUPS,
  REMOVE_GROUP,
  UPDATE_GROUP
} from "../actions/groups";
import IUserGroup from "../models/IGroup";

export const groups = (state = [] as IUserGroup[], action: AnyAction) => {
  switch (action.type) {
    case ADD_GROUP:
      const newState: IUserGroup[] = state.slice();
      newState.push(action.group as IUserGroup);
      return newState;
    case REMOVE_GROUP:
      return Object.assign(
        [],
        state.filter((grp: IUserGroup) => grp.id !== (action.id as number))
      ) as IUserGroup[];
    case UPDATE_GROUP:
      return Object.assign(
        [],
        state.map((grp: IUserGroup) => {
          if (grp.id === (action.group as IUserGroup).id) {
            return action.group as IUserGroup;
          }
          return grp;
        })
      ) as IUserGroup[];
    case LOAD_GROUPS:
      return action.groups as IUserGroup[];
    default:
      return state;
  }
};

export default groups;
