import * as Redux from "redux";
import ISqlReport from "../models/ISqlReport";

export const SET_ACTIVE_REPORT_ID = "SET_ACTIVE_REPORT_ID";
export const CLEAR_ACTIVE_REPORT_ID = "CLEAR_ACTIVE_REPORT_ID";

export const setActiveReport = (id: number) => {
  return {
    id,
    type: SET_ACTIVE_REPORT_ID
  };
};

export const clearActiveReport = () => {
  return {
    type: CLEAR_ACTIVE_REPORT_ID
  };
};

export const handleLoadActiveReportData = (
  accessToken: string,
  reportId: number,
  userReports: ISqlReport[]
) => {
  return async (dispatch: Redux.Dispatch) => {
    const report = await userReports.find(rep => rep.id === reportId);
    if (report === undefined) {
      // tslint:disable-next-line:no-console
      console.log(
        "This report not found, or user does not have access to edit this report"
      );
    } else {
      dispatch(setActiveReport(report.id));
    }
  };
};
