import IKpiEntry from "../models/IKpiEntry";
import { fetchSecureData } from "../utils";

export const saveUpdatedKpiEntry = async (
  accessToken: string,
  kpiEntry: IKpiEntry
) => {
  const payload = {
    predicateId: kpiEntry.predicateId,
    // tslint:disable-next-line:object-literal-sort-keys
    clientId: kpiEntry.clientId,
    year: kpiEntry.year,
    kpiId: kpiEntry.kpiId,
    monthId: kpiEntry.monthId,
    measuredValue: kpiEntry.measuredValue,
    thresholdValue: kpiEntry.thresholdValue,
    isEnabled: kpiEntry.isEnabled
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/KpiEntry/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as IKpiEntry;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/KpiEntry/Edit`, err);
  }
  return {} as IKpiEntry;
};

export default saveUpdatedKpiEntry;
