import IUserProfile from "../models/IUserProfile";

export const SET_ACTIVE_USER_PROFILE = "SET_ACTIVE_USER_PROFILE";

export const setActiveUserProfile = (user: IUserProfile) => {
  return {
    type: SET_ACTIVE_USER_PROFILE,
    user
  };
};

