import * as React from "react";

export interface INavLinkProps {
  children: React.ReactNode;
  to: string;
  active?: boolean;
}

const NavLink: React.SFC<INavLinkProps> = (props: INavLinkProps) => {
  const { children, to, active } = props;
  return (
    <a className={active ? "nav-link active" : "nav-link"} href={to}>
      {children}
    </a>
  );
};

export default NavLink;
