import IRolePredicateClient from "../models/IRolePredicateClient";
import { fetchSecureData } from "../utils";

const getDefaultRolePredicateClient = async (
  accessToken: string,
  roleId: number
) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/RolePredicateClient/GetDefaultRolePredicateClientForRole?roleId=${roleId}`,
      accessToken
    );
    // tslint:disable-next-line:no-console
    const rolePredicateClient = ((await data.json()) as any)
      .value as IRolePredicateClient;
    if (rolePredicateClient !== undefined) {
      return rolePredicateClient;
    } else {
      return {} as IRolePredicateClient;
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/RolePredicateClient/GetDefaultRolePredicateClientForRole?roleId=${roleId}`,
      err
    );
  }
  return {} as IRolePredicateClient;
};

export default getDefaultRolePredicateClient;
