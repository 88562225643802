import IRolePredicate from "../models/IRolePredicate";
import { fetchSecureData } from "../utils";

export const getRolePredicates = async (
  accessToken: string,
  roleId: number
) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/RolePredicate/GetRolePredicatesForRole?roleId=${roleId}`,
      accessToken
    );
    const rolePredicates = ((await data.json()) as any)
      .value as IRolePredicate[];
    if (rolePredicates !== undefined) {
      return rolePredicates;
    } else {
      return [] as IRolePredicate[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/RolePredicate/GetRolePredicatesForRole?roleId=${roleId}`,
      err
    );
  }
  return [] as IRolePredicate[];
};

export default getRolePredicates;
