import { fetchSecureData } from "../utils";

export const removeRoleUser = async (accessToken: string, roleId: number, userId: number) => {
  try {
    const data = await fetchSecureData(
      "DELETE",
      `/api/RoleUser/Remove?roleId=${roleId}&userId=${userId}`,
      accessToken
    );
    const result = await data.json();
    return result.statusCode;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/RoleUser/Remove?roleId=${roleId}&userId=${userId}`, err);
  }
  return 404;
};

export default removeRoleUser;
