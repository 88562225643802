import { AnyAction } from "redux";
import {
  ADD_USER_PAGINATED_REPORT,
  LOAD_USER_PAGINATED_REPORTS,
  REMOVE_USER_PAGINATED_REPORT
} from "../actions/userPaginatedReports";
import IPbiPaginatedReport from "../models/IPbiPaginatedReport";

export const userPaginatedReports = (
  state = [] as IPbiPaginatedReport[],
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_USER_PAGINATED_REPORT:
      const newState: IPbiPaginatedReport[] = state.slice();
      newState.push(action.userPaginatedReport as IPbiPaginatedReport);
      return newState;
    case REMOVE_USER_PAGINATED_REPORT:
      return Object.assign(
        {},
        state.filter((r: IPbiPaginatedReport) => r.id !== (action.id as number))
      );
    case LOAD_USER_PAGINATED_REPORTS:
      return action.userPaginatedReports as IPbiPaginatedReport[];
    default:
      return state;
  }
};

export default userPaginatedReports;
