import { AnyAction } from "redux";
import { SET_PREDICATES_LOADED } from "../actions/loadingPredicates";

export const loadingPredicates = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_PREDICATES_LOADED:
      return action.predicatesLoaded;
    default:
      return state;
  }
};

export default loadingPredicates;
