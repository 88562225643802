import { AnyAction } from "redux";
import { SET_EDIT_USER_GROUPS_LOADED } from "../actions/loadingEditUserGroups";

export const loadingEditUserGroups = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_EDIT_USER_GROUPS_LOADED:
      return action.editUserGroupsLoaded;
    default:
      return state;
  }
};

export default loadingEditUserGroups;
