import IKpi from "../models/IKpi";
import { fetchSecureData } from "../utils";

export const saveUpdatedKpi = async (accessToken: string, kpi: IKpi) => {
  const payload = {
    id: kpi.id,
    // tslint:disable-next-line:object-literal-sort-keys
    displayName: kpi.displayName,
    name: kpi.name,
    isEnabled: kpi.isEnabled
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/Kpi/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as IKpi;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/Kpi/Edit`, err);
  }
  return {} as IKpi;
};

export default saveUpdatedKpi;
