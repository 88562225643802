import { AnyAction } from "redux";
import { SET_PAGINATED_REPORTS_LOADED } from "../actions/loadingPaginatedReports";

export const loadingPaginatedReports = (state = false, action: AnyAction) => {
    switch (action.type) {
        case SET_PAGINATED_REPORTS_LOADED:
            return action.paginatedReportsLoaded;
        default:
            return state;
    }
};

export default loadingPaginatedReports;
