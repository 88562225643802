import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  Table
} from "reactstrap";
import {
  handleLoadPredicates,
  handleSavePredicate
} from "../../../actions/predicates";
import AddTextField from "../../../components/molecules/AddTextField";
import { paths, settings } from "../../../constants";
import IPredicate from "../../../models/IPredicate";
import { IStore } from "../../../models/IStore";
import { validateGroupName, validatePredicateName, validatePredicateDisplayName } from "../../../utils";
export interface IAddGroupPageProps extends RouteComponentProps {
  accessToken: string;
  existingPredicates: IPredicate[];
  onHandleLoadPredicates: (accessToken: string) => void;
  onHandleSavePredicate: (
    accessToken: string,
    displayName:string,
    name: string,
    isKpiGrouping: boolean,
    isEnabled: boolean
  ) => void;
}
export interface IAddGroupPageState {
  displayName:string;
  name: string;
  isKpiGrouping: boolean;
  isEnabled: boolean;
  isReadyToSave: boolean;
}

export class AddGroupPage extends React.Component<
  IAddGroupPageProps,
  IAddGroupPageState
> {
  public isLoaded = false;
  public state = {
    displayName:"",
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    isEnabled: true,
    isKpiGrouping: false,
    isReadyToSave: false
  };

  constructor(props: IAddGroupPageProps) {
    super(props);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
    this.isKpiGroupingChange = this.isKpiGroupingChange.bind(this);
  }
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public isKpiGroupingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isKpiGrouping = e.target.checked;
    this.setState(() => ({ isKpiGrouping }));
  };
  public readyToSave = () => {
    const { name } = this.state;

    if (name === undefined || name === "" || !validateGroupName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }

    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public save = (addMore: boolean = false) => {
    const { accessToken, onHandleSavePredicate, history } = this.props;
    const { displayName, name, isKpiGrouping, isEnabled } = this.state;
    if (this.readyToSave()) {
      onHandleSavePredicate(accessToken, displayName, name, isKpiGrouping, isEnabled);
      if (!addMore) {
        history.push(paths.home);
      } else {
        this.setState(() => ({
          displayName:"",
          name: "",
          // tslint:disable-next-line:object-literal-sort-keys
          isKpiGrouping: false,
          isEnabled: true
        }));
      }
    }
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };

  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadPredicates,
      existingPredicates
    } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingPredicates.length === 0) {
        onHandleLoadPredicates(accessToken);
      }
    }
  }
  public render() {
    const { existingPredicates } = this.props;
    const { displayName, name, isKpiGrouping, isEnabled } = this.state;
    const { allowDisablePredicates } = settings;
    return (
      <Form>
        <Card>
          <CardHeader>Add New Predicate</CardHeader>
          <CardBody>
            <React.Fragment>
              <AddTextField
                noDuplicates={true}
                isValid={validatePredicateName}
                title="Name"
                value={name}
                existingValues={existingPredicates.map(pred => pred.name)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (n: string) => {
                  await this.setState(() => ({ name: n }));
                  this.readyToSave();
                }}
              />
              <AddTextField
                noDuplicates={true}
                isValid={validatePredicateDisplayName}
                title="Name"
                value={displayName}
                existingValues={existingPredicates.map(pred => pred.displayName)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (dn: string) => {
                  await this.setState(() => ({ displayName: dn }));
                  this.readyToSave();
                }}
              />
              <Table>
                <tr>
                  <th scope="row">Is KPI Grouping</th>
                  <td>
                    <Input
                      type="checkbox"
                      defaultChecked={isKpiGrouping}
                      onChange={this.isKpiGroupingChange}
                    />
                    {isKpiGrouping}
                  </td>
                </tr>
              </Table>
              {allowDisablePredicates && (
                <Table>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <Input
                        type="checkbox"
                        defaultChecked={isEnabled}
                        onChange={this.isEnabledChange}
                      />
                      {isEnabled}
                    </td>
                  </tr>
                </Table>
              )}
            </React.Fragment>
          </CardBody>
          <CardFooter>
            <React.Fragment>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save()}
                style={{ margin: 4, padding: 4 }}
              >
                Save Changes
              </Button>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save(true)}
                style={{ margin: 4, padding: 4 }}
              >
                Save And Add Another
              </Button>
              <Button onClick={this.cancel} style={{ margin: 4, padding: 4 }}>
                Cancel
              </Button>
            </React.Fragment>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingPredicates: state.predicates
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadPredicates: (accessToken: string) => {
    dispatch(handleLoadPredicates(accessToken));
  },
  onHandleSavePredicate: (
    accessToken: string,
    displayName: string,
    name: string,
    isKpiGrouping: boolean,
    isEnabled: boolean
  ) => {
    dispatch(handleSavePredicate(accessToken, displayName, name, isKpiGrouping, isEnabled));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddGroupPage);
