import IRole from "../models/IRole";
import { fetchSecureData } from "../utils";

export const getUserRoles = async (accessToken: string, userId: number) => {
  try {
    const data = await fetchSecureData(
      "GET",
      `/api/Role/GetRolesForUserId?userId=${userId}`,
      accessToken
    );
    const userRoles = ((await data.json()) as any).value as IRole[];
    if (userRoles !== undefined) {
      return userRoles;
    } else {
      return [] as IRole[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/Role/GetRolesForUserId?userId=${userId}`,
      err
    );
  }
  return [] as IRole[];
};

export default getUserRoles;
