import * as Redux from "redux";
import getReports from "../api/getReports";
import { removeReport as deleteReport } from "../api/removeReport";
import saveReport from "../api/saveReport";
import saveUpdatedReport from "../api/saveUpdatedReport";
import ISqlReport from "../models/ISqlReport";
import { setReportsLoaded } from "./loadingReports";
export const ADD_REPORT = "ADD_REPORT";
export const REMOVE_REPORT = "REMOVE_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const LOAD_REPORTS = "LOAD_REPORTS";

export const addReport = (report: ISqlReport) => {
  return {
    report,
    type: ADD_REPORT
  };
};

export const removeReport = (id: number) => {
  return {
    id,
    type: REMOVE_REPORT
  };
};

export const updateReport = (report: ISqlReport) => {
  return {
    report,
    type: UPDATE_REPORT
  };
};

export const loadReports = (reports: ISqlReport[]) => {
  return {
    reports,
    type: LOAD_REPORTS
  };
};

export const handleSaveReport = (
  accessToken: string,
  name: string,
  displayName: string,
  defaultZoomMode: string,
  defaultZoomPercent: number,
  parameters: string,
  path: string,
  sqlReportingServerUrl: string,
  sqlReportingViewerUrl: string,
  isEnabled: boolean
) => {
  return async (dispatch: Redux.Dispatch) => {
    const report = await saveReport(
      accessToken,
      name,
      displayName,
      defaultZoomMode,
      defaultZoomPercent,
      parameters,
      path,
      sqlReportingServerUrl,
      sqlReportingViewerUrl,
      isEnabled
    );
    if (report !== null && report !== undefined) {
      dispatch(addReport(report));
    }
  };
};

export const handleUpdateReport = (accessToken: string, report: ISqlReport) => {
  return async (dispatch: Redux.Dispatch) => {
    const dsh = await saveUpdatedReport(accessToken, report);
    if (dsh !== null && dsh !== undefined) {
      dispatch(updateReport(report));
    }
  };
};

export const handleDeleteReport = (accessToken: string, id: number) => {
  return async (dispatch: Redux.Dispatch) => {
    const result = await deleteReport(accessToken, id);
    if (result === 200) {
      dispatch(removeReport(id));
    }
  };
};

export const handleLoadReports = (accessToken: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const reports = await getReports(accessToken);
    if (reports !== undefined) {
      if (reports.length > 0) {
        reports.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
        dispatch(loadReports(reports));
      }
      dispatch(setReportsLoaded(true));
    }
  };
};
