import IPredicate from "../models/IPredicate";
import { fetchSecureData } from "../utils";

export const saveUpdatedPredicate = async (
  accessToken: string,
  predicate: IPredicate
) => {
  const payload = {
    id: predicate.id,
    displayName: predicate.displayName,
    name: predicate.name,
    // tslint:disable-next-line:object-literal-sort-keys
    isKpiGrouping: predicate.isKpiGrouping,
    isEnabled: predicate.isEnabled
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/Predicate/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as IPredicate;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/Predicate/Edit`, err);
  }
  return {} as IPredicate;
};

export default saveUpdatedPredicate;
