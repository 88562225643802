import { Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { paths } from "../../constants";
import { IStore } from "../../models/IStore";

export interface ISecureAdminRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isAdministrator: boolean;
  component: React.ComponentType<RouteProps> | any;
}

class SecureAdminRoute extends React.Component<ISecureAdminRouteProps> {
  public constructor(props: ISecureAdminRouteProps) {
    super(props);
  }
  public render() {
    const Component = this.props.component;
    const { isAuthenticated, isAdministrator } = this.props;
    return (
      <Route
        {...this.props}
        render={
          // tslint:disable-next-line:jsx-no-lambda
          props =>
            isAuthenticated && isAdministrator ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: paths.root,
                  state: { from: props.location as Location }
                }}
              />
            )
        }
      />
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    isAdministrator: state.auth0.isAuthenticated(),
    isAuthenticated: state.auth0.isAuthenticated()
  };
};

export default connect(mapStateToProps)(SecureAdminRoute);
