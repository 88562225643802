import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  Table
} from "reactstrap";
import { handleLoadGroups, handleSaveGroup } from "../../../actions/groups";
import AddTextField from "../../../components/molecules/AddTextField";
import { paths, settings } from "../../../constants";
import IGroup from "../../../models/IGroup";
import { IStore } from "../../../models/IStore";
import { validateGroupName } from "../../../utils";
export interface IAddGroupPageProps extends RouteComponentProps {
  accessToken: string;
  existingGroups: IGroup[];
  onHandleLoadGroups: (accessToken: string) => void;
  onHandleSaveGroup: (
    accessToken: string,
    name: string,
    isEnabled: boolean
  ) => void;
}
export interface IAddGroupPageState {
  name: string;
  isEnabled: boolean;
  isReadyToSave: boolean;
}

export class AddGroupPage extends React.Component<
  IAddGroupPageProps,
  IAddGroupPageState
> {
  public isLoaded = false;
  public state = {
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    isEnabled: true,
    isReadyToSave: false
  };

  constructor(props: IAddGroupPageProps) {
    super(props);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public readyToSave = () => {
    const { name } = this.state;

    if (name === undefined || name === "" || !validateGroupName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }

    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public save = (addMore: boolean = false) => {
    const { accessToken, onHandleSaveGroup, history } = this.props;
    const { name, isEnabled } = this.state;
    if (this.readyToSave()) {
      onHandleSaveGroup(accessToken, name, isEnabled);
      if (!addMore) {
        history.push(paths.home);
      } else {
        this.setState(() => ({
          name: "",
          // tslint:disable-next-line:object-literal-sort-keys
          isEnabled: true
        }));
      }
    }
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };

  public async componentDidMount() {
    const { accessToken, onHandleLoadGroups, existingGroups } = this.props;
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingGroups.length === 0) {
        onHandleLoadGroups(accessToken);
      }
    }
  }
  public render() {
    const { existingGroups } = this.props;
    const { name, isEnabled } = this.state;
    const { allowDisableGroups } = settings;
    return (
      <Form>
        <Card>
          <CardHeader>Add New Group</CardHeader>
          <CardBody>
            <React.Fragment>
              <AddTextField
                noDuplicates={true}
                isValid={validateGroupName}
                title="Name"
                value={name}
                existingValues={existingGroups.map(grp => grp.name)}
                // tslint:disable-next-line:jsx-no-lambda
                callback={async (n: string) => {
                  await this.setState(() => ({ name: n }));
                  this.readyToSave();
                }}
              />
              {allowDisableGroups && (
                <Table>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <Input
                        type="checkbox"
                        defaultChecked={isEnabled}
                        onChange={this.isEnabledChange}
                      />
                      {isEnabled}
                    </td>
                  </tr>
                </Table>
              )}
            </React.Fragment>
          </CardBody>
          <CardFooter>
            <React.Fragment>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save()}
                style={{ margin: 4, padding: 4 }}
              >
                Save Changes
              </Button>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.save(true)}
                style={{ margin: 4, padding: 4 }}
              >
                Save And Add Another
              </Button>
              <Button onClick={this.cancel} style={{ margin: 4, padding: 4 }}>
                Cancel
              </Button>
            </React.Fragment>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingGroups: state.groups
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadGroups: (accessToken: string) => {
    dispatch(handleLoadGroups(accessToken));
  },
  onHandleSaveGroup: (
    accessToken: string,
    name: string,
    isEnabled: boolean
  ) => {
    dispatch(handleSaveGroup(accessToken, name, isEnabled));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddGroupPage);
