import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Card, CardBody, CardFooter, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import { handleDeleteGroup, handleLoadGroups, handleUpdateGroup } from "../../../actions/groups";
import AddTextField from "../../../components/molecules/AddTextField";
import GroupDetailsTable from "../../../components/molecules/GroupDetailsTable";
import { adminGroupName, paths, settings } from "../../../constants";
import IGroup from "../../../models/IGroup";
import { IStore } from "../../../models/IStore";
import { validateGroupName } from "../../../utils";
export interface IEditGroupPageProps extends RouteComponentProps {
  accessToken: string;
  group: IGroup;
  existingGroups: IGroup[];
  onHandleLoadGroups: (accessToken: string) => void;
  onHandleUpdateGroup: (accessToken: string, group: IGroup) => void;
  onHandleDeleteGroup: (accessToken: string, id: number) => void;
}
export interface IEditGroupPageState {
  editModeOn: boolean;
  name: string;
  isEnabled: boolean;
  showDeleteModal: boolean;
  isReadyToSave: boolean;
}

class EditGroupPage extends React.Component<
  IEditGroupPageProps,
  IEditGroupPageState
> {
  public isLoaded = false;
  public state = {
    editModeOn: false,
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    isEnabled: true,
    showDeleteModal: false,
    isReadyToSave: false
  };
  constructor(props: IEditGroupPageProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.delete = this.delete.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }

  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };
  public save = (e: React.MouseEvent) => {
    e.preventDefault();
    const { accessToken, onHandleUpdateGroup, history, group } = this.props;
    const { name, isEnabled, isReadyToSave } = this.state;
    const updatedGroup = {
      id: group.id,
      name,
      // tslint:disable-next-line:object-literal-sort-keys
      isEnabled
    } as IGroup;
    if (this.readyToSave() && isReadyToSave) {
      onHandleUpdateGroup(accessToken, updatedGroup);
      history.push(paths.home);
    }
  };
  public delete = (e: React.MouseEvent) => {
    const { accessToken, group, history, onHandleDeleteGroup } = this.props;
    onHandleDeleteGroup(accessToken, group.id);
    history.push(paths.home);
  };
  public cancel = (e: React.MouseEvent) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(paths.home);
  };
  public toggleEdit() {
    this.setState({ editModeOn: !this.state.editModeOn });
  }
  public toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  public readyToSave = () => {
    const { name } = this.state;
    if (name === undefined || name === "" || !validateGroupName(name)) {
      this.setState(() => ({ isReadyToSave: false }));
      return false;
    }
    this.setState(() => ({ isReadyToSave: true }));
    return true;
  };
  public async componentDidMount() {
    const {
      accessToken,
      onHandleLoadGroups,
      existingGroups,
      group
    } = this.props;
    const { name, isEnabled } = group;
    this.setState(() => ({ name, isEnabled }));
    this.isLoaded = true;
    if (this.isLoaded) {
      if (existingGroups.length === 0) {
        onHandleLoadGroups(accessToken);
      }
    }
  }
  public render() {
    const { group, existingGroups } = this.props;
    const { editModeOn, name, isEnabled, isReadyToSave } = this.state;
    const { allowDisableGroups } = settings;
    return (
      <Form>
        <Modal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>
            Delete Group {name}
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete Group: {name}
            {"?"}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Confirm Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Card>
          <CardHeader>Group Details for {name}</CardHeader>
          <CardBody>
            {!editModeOn && <GroupDetailsTable group={group} />}
            {editModeOn && (
              <React.Fragment>
                <AddTextField
                  noDuplicates={true}
                  isValid={validateGroupName}
                  title="Name"
                  value={name}
                  existingValues={existingGroups.map(grp => grp.name)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={async (n: string) => {
                    await this.setState(() => ({ name: n }));
                    this.readyToSave();
                  }}
                />

                {allowDisableGroups && (
                  <Table>
                    <tr>
                      <th scope="row">Enabled</th>
                      <td>
                        <Input
                          type="checkbox"
                          defaultChecked={isEnabled}
                          onChange={this.isEnabledChange}
                        />
                        {isEnabled}
                      </td>
                    </tr>
                  </Table>
                )}
              </React.Fragment>
            )}
          </CardBody>
          <CardFooter>
            {!editModeOn && name !== adminGroupName && (
              <React.Fragment>
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  onClick={this.toggleDeleteModal}
                  style={{
                    margin: 4,
                    padding: 4
                  }}
                >
                  <strong>Delete Group</strong>
                </Button>
              </React.Fragment>
            )}
            {editModeOn && name !== adminGroupName && (
              <React.Fragment>
                {isReadyToSave && (
                  <Button onClick={this.save} style={{ margin: 4, padding: 4 }}>
                    Save Changes
                  </Button>
                )}
                <Button
                  onClick={this.toggleEdit}
                  style={{ margin: 4, padding: 4 }}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
  const { match } = ownProps;
  let group = {} as IGroup;
  const existingGroups = state.groups.filter(
    grp => grp.id !== parseInt((match.params as any).id as string, 10)
  );
  if (match !== null) {
    group = state.groups.find(grp => {
      return grp.id === parseInt((match.params as any).id as string, 10);
    }) as IGroup;
    // if (group === undefined) {
    //   // tslint:disable-next-line:no-console
    //   console.log("didn't find the group");
    //   return { group: {} as IGroup };
    // }
  }
  return {
    accessToken: state.auth0.accessToken,
    existingGroups,
    group
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadGroups: (accessToken: string) => {
    dispatch(handleLoadGroups(accessToken));
  },
  onHandleUpdateGroup: (accessToken: string, group: IGroup) => {
    dispatch(handleUpdateGroup(accessToken, group));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleDeleteGroup: (accessToken: string, id: number) => {
    dispatch(handleDeleteGroup(accessToken, id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGroupPage);
