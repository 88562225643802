import { AnyAction } from "redux";
import { SET_USER_ROLES_LOADED } from "../actions/loadingUserRoles";

export const loadingUserRoles = (state = false, action: AnyAction) => {
  switch (action.type) {
    case SET_USER_ROLES_LOADED:
      return action.userRolesLoaded;
    default:
      return state;
  }
};

export default loadingUserRoles;
