import * as React from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import {
  clearActiveKpiPredicateId,
  setActiveKpiPredicateId
} from "../../actions/activeKpiPredicate";
import {
  handleClearActiveRoleKpiGroupingPredicates,
  handleLoadActiveRoleKpiGroupingPredicates
} from "../../actions/activeRoleKpiGroupingPredicates";
import IPredicate from "../../models/IPredicate";
import { IStore } from "../../models/IStore";
import DataLoading from "../organisms/DataLoading";

export interface IKpiGroupingSelectorProps {
  accessToken: string;
  predicates: IPredicate[];
  predicatesLoaded: boolean;
  roleId: number;
  onHandleLoadPredicates: (accessToken: string, roleId: number) => void;
  onHandleSetActiveKpiPredicateId: (selectedPredicateId: number) => void;
  onHandleClearActiveKpiPredicateId: () => void;
  onHandleClearLoadedPredicates: () => void;
}

export interface IKpiGroupingSelectorState {
  selectedPredicateId: number;
}

class KpiGroupingSelector extends React.Component<
  IKpiGroupingSelectorProps,
  IKpiGroupingSelectorState
> {
  public state = {
    selectedPredicateId: 0
  };
  constructor(props: IKpiGroupingSelectorProps) {
    super(props);
    this.selectedPredicateChange = this.selectedPredicateChange.bind(this);
  }
  public selectedPredicateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onHandleSetActiveKpiPredicateId } = this.props;
    e.preventDefault();
    const selectedPredicateId =
      e.target.value === undefined ? 0 : Number(e.target.value as string);
    onHandleSetActiveKpiPredicateId(selectedPredicateId);
    this.setState(() => ({ selectedPredicateId }));
  };
  public componentDidMount() {
    const {
      accessToken,
      predicatesLoaded,
      roleId,
      onHandleLoadPredicates
    } = this.props;
    if (!predicatesLoaded) {
      onHandleLoadPredicates(accessToken, roleId);
    }
  }
  public componentDidUpdate(prevProps: IKpiGroupingSelectorProps) {
    const {
      accessToken,
      roleId,
      predicatesLoaded,
      onHandleClearActiveKpiPredicateId,
      onHandleClearLoadedPredicates,
      onHandleLoadPredicates
    } = this.props;
    if (prevProps.roleId !== roleId) {
      onHandleClearLoadedPredicates();
      onHandleClearActiveKpiPredicateId();
      this.setState(() => ({ selectedPredicateId: 0 }));
    }
    if (!predicatesLoaded) {
      onHandleLoadPredicates(accessToken, roleId);
    }
  }
  public componentWillUnmount() {
    const {
      onHandleClearActiveKpiPredicateId,
      onHandleClearLoadedPredicates
    } = this.props;
    onHandleClearLoadedPredicates();
    onHandleClearActiveKpiPredicateId();
  }
  public render() {
    const { predicates, predicatesLoaded } = this.props;
    const { selectedPredicateId } = this.state;
    return (
      <FormGroup>
        {!predicatesLoaded && <DataLoading dataTitle="KPI Groupings" />}
        {predicatesLoaded && (
          <React.Fragment>
            <Label for="kpiGrouping">KPI Grouping</Label>
            <Input
              id="kpiGrouping"
              type="select"
              placeholder="KPI Grouping"
              value={selectedPredicateId}
              onChange={this.selectedPredicateChange}
            >
              <option value="0" disabled={true}>
                Select KPI Grouping
              </option>
              {predicates.map(predicate => (
                <option key={predicate.id} value={predicate.id}>
                  {predicate.displayName}
                </option>
              ))}
            </Input>
          </React.Fragment>
        )}
      </FormGroup>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    predicates: state.activeRoleKpiGroupingPredicates,
    predicatesLoaded: state.loadingRoleKpiGroupingPredicates,
    roleId: state.activeRoleId
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadPredicates: (accessToken: string, roleId: number) => {
    dispatch(handleLoadActiveRoleKpiGroupingPredicates(accessToken, roleId));
  },
  onHandleSetActiveKpiPredicateId: (selectedPredicateId: number) => {
    dispatch(setActiveKpiPredicateId(selectedPredicateId));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleClearActiveKpiPredicateId: () => {
    dispatch(clearActiveKpiPredicateId());
  },
  onHandleClearLoadedPredicates: () => {
    dispatch(handleClearActiveRoleKpiGroupingPredicates());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KpiGroupingSelector);
