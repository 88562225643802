import IClient from "models/IClient";

export const SET_ACTIVE_DASHBOARD_DEFAULT_CLIENT = "SET_ACTIVE_DASHBOARD_DEFAULT_CLIENT";
export const CLEAR_ACTIVE_DASHBOARD_DEFAULT_CLIENT = "CLEAR_ACTIVE_DASHBOARD_DEFAULT_CLIENT";

export const setActiveDashboardDefaultClient = (client: IClient) => {
  return {
    client,
    type: SET_ACTIVE_DASHBOARD_DEFAULT_CLIENT
  };
};

export const clearActiveDashboardDefaultClient = () => {
  return {
    type: CLEAR_ACTIVE_DASHBOARD_DEFAULT_CLIENT
  };
};
