import { AnyAction } from "redux";
import {
  ADD_PREDICATE,
  LOAD_PREDICATES,
  REMOVE_PREDICATE,
  UPDATE_PREDICATE
} from "../actions/predicates";
import IPredicate from "../models/IPredicate";

export const predicates = (state = [] as IPredicate[], action: AnyAction) => {
  switch (action.type) {
    case ADD_PREDICATE:
      const newState: IPredicate[] = state.slice();
      newState.push(action.predicate as IPredicate);
      return newState;
    case REMOVE_PREDICATE:
      return Object.assign(
        [],
        state.filter((rp: IPredicate) => rp.id !== (action.id as number))
      ) as IPredicate[];
    case UPDATE_PREDICATE:
      return Object.assign(
        [],
        state.map((rp: IPredicate) => {
          if (rp.id === (action.predicate as IPredicate).id) {
            return action.predicate as IPredicate;
          }
          return rp;
        })
      ) as IPredicate[];
    case LOAD_PREDICATES:
      return action.predicates as IPredicate[];
    default:
      return state;
  }
};

export default predicates;
