import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import {
  handleDeleteUserProfile as handleRemoveUserProfile,
  handleUpdateUserProfile
} from "../../../actions/profiles";
import AddTextField from "../../../components/molecules/AddTextField";
import UserDetailsTable from "../../../components/organisms/UserDetailsTable";
import paths, { settings } from "../../../constants";
import { IStore } from "../../../models/IStore";
import IUserProfile from "../../../models/IUserProfile";
import { validateEmail } from "../../../utils";

export interface IEditUserDetailsProps extends RouteComponentProps {
  accessToken: string;
  user: IUserProfile;
  existingUsers: IUserProfile[];
  onHandleUpdateUser: (accessToken: string, user: IUserProfile) => void;
  onHandleDeleteUser: (accessToken: string, id: number) => void;
}

export interface IEditUserDetailsState {
  editModeOn: boolean;
  emailAddress: string;
  isEnabled: boolean;
  showDeleteModal: boolean;
}

class EditUserDetails extends React.Component<
  IEditUserDetailsProps,
  IEditUserDetailsState
> {
  public state = {
    editModeOn: false,
    // tslint:disable-next-line:object-literal-sort-keys
    emailAddress: this.props.user.emailAddress,
    isEnabled: true,
    showDeleteModal: false
  };
  constructor(props: IEditUserDetailsProps) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.delete = this.delete.bind(this);
    this.readyToSave = this.readyToSave.bind(this);
    this.save = this.save.bind(this);
    this.isEnabledChange = this.isEnabledChange.bind(this);
  }
  public toggleEdit() {
    this.setState({ editModeOn: !this.state.editModeOn });
  }
  public toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  public delete = (e: React.MouseEvent) => {
    const { history, accessToken, user, onHandleDeleteUser } = this.props;
    // do delete
    onHandleDeleteUser(accessToken, user.id);
    history.push(paths.home);
  };
  public readyToSave = () => {
    const { emailAddress } = this.state;
    if (
      emailAddress === undefined ||
      emailAddress === "" ||
      !validateEmail(emailAddress)
    ) {
      return false;
    }

    return true;
  };
  public save = (e: React.MouseEvent<HTMLElement>) => {
    const { accessToken, user, onHandleUpdateUser } = this.props;
    const { emailAddress, isEnabled } = this.state;
    if (this.readyToSave()) {
      // do save
      onHandleUpdateUser(accessToken, {
        id: user.id,
        // tslint:disable-next-line:object-literal-sort-keys
        emailAddress,
        isEnabled
      } as IUserProfile);
    }
    this.toggleEdit();
  };
  public isEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    this.setState(() => ({ isEnabled }));
  };

  public render() {
    const { allowDisableUsers } = settings;
    const { user, existingUsers } = this.props;
    const { editModeOn, emailAddress, isEnabled } = this.state;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
        >
          <ModalHeader toggle={this.toggleDeleteModal}>
            Delete User: {emailAddress}
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete User: {emailAddress}{" "}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Confirm Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Card>
          <CardBody>
            {!editModeOn && <UserDetailsTable user={user} />}
            {editModeOn && (
              <React.Fragment>
                <AddTextField
                  noDuplicates={true}
                  isValid={validateEmail}
                  title="Email Address"
                  value={emailAddress}
                  existingValues={existingUsers.map(usr => usr.emailAddress)}
                  // tslint:disable-next-line:jsx-no-lambda
                  callback={(email: string) =>
                    this.setState(() => ({ emailAddress: email }))
                  }
                />

                {allowDisableUsers && (
                  <Table>
                    <tr>
                      <th scope="row">Enabled</th>
                      <td>
                        <Input
                          type="checkbox"
                          defaultChecked={isEnabled}
                          onChange={this.isEnabledChange}
                        />
                        {isEnabled}
                      </td>
                    </tr>
                  </Table>
                )}
              </React.Fragment>
            )}
            <CardFooter>
              {!editModeOn && (
                <React.Fragment>
                  <Button
                    onClick={this.toggleEdit}
                    style={{ margin: 4, padding: 4 }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    onClick={this.toggleDeleteModal}
                    style={{
                      margin: 4,
                      padding: 4
                    }}
                  >
                    <strong>Delete User</strong>
                  </Button>
                </React.Fragment>
              )}
              {editModeOn && (
                <React.Fragment>
                  {this.readyToSave() && (
                    <Button
                      onClick={this.save}
                      style={{ margin: 4, padding: 4 }}
                    >
                      Save Changes
                    </Button>
                  )}
                  <Button
                    onClick={this.toggleEdit}
                    style={{ margin: 4, padding: 4 }}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              )}
            </CardFooter>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    existingUsers: state.profiles.filter(
      user => user.id !== state.editUserDetails.id
    ),
    user: state.editUserDetails
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleUpdateUser: (accessToken: string, user: IUserProfile) => {
    dispatch(handleUpdateUserProfile(accessToken, user));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onHandleDeleteUser: (accessToken: string, id: number) => {
    dispatch(handleRemoveUserProfile(accessToken, id));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditUserDetails);
