import * as React from "react";
import { Input, Table } from "reactstrap";
import { settings } from "../../constants";
import IUserGroup from "../../models/IGroup";

export interface IGroupDetailsTableProps {
  group: IUserGroup;
}

const GroupDetailsTable: React.SFC<IGroupDetailsTableProps> = (
  props: IGroupDetailsTableProps
) => {
  const { group } = props;
  const { name, isEnabled } = group;
  const { allowDisableGroups } = settings;
  return (
    <Table striped={true}>
      {/* <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead> */}
      <tbody>
        <tr>
          <th scope="row">Name</th>
          <td> {name}</td>
        </tr>
        {allowDisableGroups && (
          <tr>
            <th scope="row">Enabled</th>
            <td>
              <Input type="checkbox" checked={isEnabled} readOnly={true} />
              {isEnabled}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default GroupDetailsTable;
