import IUserProfile from "../models/IUserProfile";
import { fetchSecureData } from "../utils";

export const saveUpdatedUserProfile = async (
  accessToken: string,
  userProfile: IUserProfile
) => {
  const payload = {
    id: userProfile.id,
    // tslint:disable-next-line:object-literal-sort-keys
    emailAddress: userProfile.emailAddress,
    isEnabled: userProfile.isEnabled
  };
  try {
    const data = await fetchSecureData(
      "PUT",
      `/api/UserProfile/Edit`,
      accessToken,
      payload
    );
    const result = ((await data.json()) as any).value as IUserProfile;
    // tslint:disable-next-line:no-console
    return result;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(`Fetch error for /api/UserProfile/Edit`, err);
  }
  return {} as IUserProfile;
};

export default saveUpdatedUserProfile;
