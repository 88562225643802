import * as Redux from "redux";
import getUserDashboards from "../api/getUserDashboards";
import IPbiDashboard from "../models/IPbiDashboard";
import { setActiveDashboard } from "./activeDashboard";
import { setUserDashboardsLoaded } from "./loadingUserDashboards";

export const ADD_USER_DASHBOARD = "ADD_USER_DASHBOARD";
export const REMOVE_USER_DASHBOARD = "REMOVE_USER_DASHBOARD";
export const LOAD_USER_DASHBOARDS = "LOAD_USER_DASHBOARDS";

export const addUserDashboard = (userDashboard: IPbiDashboard) => {
  return {
    userDashboard,
    // tslint:disable-next-line:object-literal-sort-keys
    type: ADD_USER_DASHBOARD
  };
};

export const removeUserDashboard = (id: number) => {
  return {
    id,
    type: REMOVE_USER_DASHBOARD
  };
};

export const loadUserDashboards = (userDashboards: IPbiDashboard[]) => {
  return {
    userDashboards,
    // tslint:disable-next-line:object-literal-sort-keys
    type: LOAD_USER_DASHBOARDS
  };
};

export const handleLoadUserDashboards = (
  accessToken: string,
  userId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    const dashboards = await getUserDashboards(accessToken, userId);
    if (dashboards !== undefined) {
      if (dashboards.length > 0) {
        dashboards.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
        dispatch(loadUserDashboards(dashboards));
        dispatch(setActiveDashboard(dashboards[0].id));
      }
      dispatch(setUserDashboardsLoaded(true));
    }
  };
};
